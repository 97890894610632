import http from "../http/http";
import Config from "../config/config.json"

import {getUserList} from "../store/slices/usersSlice";


const list = ()=>async (dispatch)=>{
    http.get({
        url: Config.USERS.LIST
    }).then(response=>{
        dispatch(getUserList(response.status?response.data:[]));
    })
}


const getDetails=({id,loader})=>{
    return http.get({url:Config.USERS.DETAILS.replace("{id}",id),loader:loader})
}

const getTransactions=({id,loader})=>{
    return http.get({url:Config.USERS.TRANSACTIONS.replace("{id}",id),loader:loader})
}
const getReservations=({id,loader})=>{
    return http.get({url:Config.USERS.RESERVATIONS.replace("{id}",id),loader:loader})
}
const setStatus=({id,status,loader})=>{
    return http.get({url:Config.USERS.SET_STATUS.replace("{id}",id).replace("{status}",status),loader:loader})
}

const addUser=({data,loader})=>{
    return http.post({url:Config.USERS.ADD_NEW,data:data,loader:loader})
}
const setPersonalInfo=({data,loader})=>{
    return http.post({url:Config.USERS.UPDATE,data:data,loader:loader})
}

const setPersonalPercent = (data) =>{
    return http.post({url:Config.USERS.SET_PERSONAL_PERCENT,data:data})

}

const charge = ({mode,params,loader}) =>{
    return http.post({
        url:Config.USERS.CHARGE.replace("{mode}",mode),
        data:{...params},
        loader:loader
    })
}

const accept_transaction = ({pay_id,cashback,loader}) => {
    return http.get({
        url:Config.USERS.ACCEPT_TRANSACTION.replace("{payId}",pay_id).replace("{cashback}",cashback),
        loader:loader
    })
}
const deleteTransactionByPayId = ({pay_id,loader}) => {
    return http.get({
        url:Config.USERS.DELETE_TRANSACTION_BY_PAY_ID.replace("{payId}",pay_id),
        loader:loader
    })
}
export  {
    list,
    getDetails,
    getTransactions,
    getReservations,
    setStatus,
    addUser,
    setPersonalPercent,
    setPersonalInfo,
    charge,
    deleteTransactionByPayId,
    accept_transaction
}
