import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import _ from "lodash";

export const Tabs=({onChangeTab,config=[]})=>{
    const [selectedIndex, setSelectedIndex] = useState(0);
    useEffect(() => {
        onChangeTab(selectedIndex);
    }, [selectedIndex]);


    return (<div role="tablist"
         className="dx-tabs dx-widget dx-visibility-change-handler dx-collection dx-tabs-expanded dx-state-focused"
         tabIndex="0" aria-activedescendant="dx-49d79513-ee9e-8e1a-a8db-871af674ee2f">
        <div className="dx-tabs-wrapper">
            {
                _.map(config,({key,icon,iconColor,title})=>(
                    <div key={key} className={`dx-item dx-tab  ${selectedIndex===key?'dx-tab-selected dx-state-focused':''}`} role="tab" aria-selected="true" onClick={()=>setSelectedIndex(key)}>
                        <div className="dx-item-content dx-tab-content"><span className="dx-tab-text">
                   {icon &&  <FontAwesomeIcon icon={icon} color={iconColor}/>}{" "}
                            {title}</span></div>
                    </div>
                ))
            }

        </div>
    </div>)
}
