import http from "../http/http";
import Config from "../config/config.json"
import {query_string} from "../helpers/query_params";
const Ping  = () =>{
     return http.get({url: Config.USER.PING})
}

const SignIn = ({username,password})=>{
   return http.post({
        url: Config.USER.SIGN_IN, data: query_string({
            "username": username,
            "password": password
        })
    })
}

export  {
    Ping,
    SignIn
}
