import {Button, FileUploader, Popup, SelectBox, TextBox} from "devextreme-react";
import React, {useEffect, useRef, useState} from "react";

import {Modal, Form, Col,Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {Conf} from "../../../core/config/Conf";

import {i18n} from "../../../core";

export default function SlidesAppModal(props){
	const [selected,setSelected]=useState([])
	const [data,setData]=useState(null)
	const fileUploaderRef = useRef();
	useEffect(()=>{
		if(props.data) {
			setSelected([])
			setData({...props.data, icon: props?.data?.image})
		}else{
			setData(null)
		}
	},[props.data])



	const onHandleImage=(e)=> {

		if(e && e.length>0){
			const reader = new FileReader();
			reader.onload = function(){
				setData({...data,icon:reader.result,fileChange:true});
			};
			reader.readAsDataURL(e[0]);
		}

	}

	return  data !==null && <Modal show={true} onHide={()=>props.onClose()}>
		<Modal.Header closeButton>
			<Modal.Title>{i18n.t('სლაიდის დამატება')}</Modal.Title>
		</Modal.Header>
		<Modal.Body>
			<Form>
				<Row>
					<Col md={6}>
						<Form.Group className="mb-3" controlId="form2">
							<Form.Label>{i18n.t("დასახელება")}</Form.Label>
							<Form.Control  type={"text"} value={data.title} onChange={e=>setData({...data,title:e.target.value})} />
						</Form.Group>
					</Col>
					<Col md={6}>
						<Form.Group className="mb-3" controlId="form2">
							<Form.Label>{i18n.t("დასახელება2")}</Form.Label>
							<Form.Control  type={"text"} value={data.title2} onChange={e=>setData({...data,title2:e.target.value})} />
						</Form.Group>
					</Col>
				</Row>
				<Row>
					<Col md={6}>
						<Form.Group className="mb-3" controlId="form2">
							<Form.Label>{i18n.t("დასახელება3")}</Form.Label>
							<Form.Control  type={"text"} value={data.title3} onChange={e=>setData({...data,title3:e.target.value})} />
						</Form.Group>
					</Col>
					<Col md={6}>
						<Form.Group className="mb-3" controlId="form2">
							<Form.Label>{i18n.t("დამატებითი ტექსტი")}</Form.Label>
							<Form.Control  type={"text"} value={data.content} onChange={e=>setData({...data,content:e.target.value})} />
						</Form.Group>
					</Col>
				</Row>



				<Form.Group className="mb-3">
					<Form.Label htmlFor="disabledSelect">{i18n.t("სტატუსი")}</Form.Label>
					<Form.Select id="disabledSelect" value={data.status} onChange={e=>setData({...data,status:e.target.value})}>
						<option value="">{i18n.t("აირჩიეთ სტატუსი")}</option>
						<option value="1">{i18n.t("აქტიური")}</option>
						<option value="-1">{i18n.t("პასიური")}</option>
					</Form.Select>
				</Form.Group>
				<Form.Group className="mb-3" controlId="form2">
					<Form.Label>{i18n.t("რიგითი ნომერი")}</Form.Label>
					<Form.Control  type={"number"} value={data.sort} onChange={e=>setData({...data,sort:e.target.value})} />
				</Form.Group>
				<Form.Group className="mb-3">
					<Form.Label htmlFor="disabledSelect">{i18n.t("ენა")}</Form.Label>
					<Form.Select id="disabledSelect" value={data.lang_key} onChange={e=>setData({...data,lang_key:e.target.value})}>
						<option value="">{i18n.t("აირჩიეთ ენა")}</option>
						<option value="ka">{i18n.t("ქართული")}</option>
						<option value="ru">{i18n.t("რუსული")}</option>
						<option value="en">{i18n.t("ინგლისური")}</option>
					</Form.Select>
				</Form.Group>
				<Form.Group controlId="formFile" className="mb-3">
					<Form.Label>{i18n.t("აირჩიეთ ფოტო")}</Form.Label>
					<Form.Control type="file" ref={fileUploaderRef} onChange={e=>onHandleImage(e.target.files)}/>
				</Form.Group>
				<Form.Group controlId="formFile" className="mb-3">
					{
						data.icon !==null && data.icon !=="" && data.icon  !==undefined  && <div style={{position:'relative',width:"150px"}}>
							<div style={{
								position:"absolute",
								right:"10px",
								top:"10px",
								cursor:'pointer'
							}}
								 onClick={()=>{
									 fileUploaderRef.current.value=""
									 setData({...data,icon:"",image:'',fileChange:true});
								 }}
							>
								<FontAwesomeIcon icon={faTimes} color={"red"} size={"2x"}  />
							</div>
							<img src={data.icon && data?.fileChange ?  data.icon:Conf.fileUri+data.icon} width={"150px"} height={"auto"}  alt={"image"}/>
						</div>
					}
				</Form.Group>
			</Form>
		</Modal.Body>
		<Modal.Footer>
			<Button variant="secondary" onClick={()=>props.onClose()}>
				{i18n.t("დახურვა")}
			</Button>
			<Button variant="primary" onClick={()=>props.onSave(data)}>
				{i18n.t("შენახვა")}
			</Button>
		</Modal.Footer>
	</Modal>


}


