import {combineReducers, configureStore} from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import {logger} from "redux-logger/src";
import userReducer from "./slices/userSlice";
import staffReducer from "./slices/staffSlice";
import roleReducer from "./slices/roleSlice";
import usersReducer from "./slices/usersSlice";
import sectionsSlice from "./slices/sectionSlice";
import handBookSlice from "./slices/handBookSlice";
import menuSlice from "./slices/menuSlice";

const store = configureStore({
    reducer: combineReducers({
        User:userReducer,
        Staff:staffReducer,
        Roles:roleReducer,
        Users:usersReducer,
        Sections:sectionsSlice,
        Groups:menuSlice,
        HandBook:handBookSlice,
    }),
    middleware: [thunk, logger],
})
export default store;
