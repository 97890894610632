import { withNavigationWatcher } from './contexts/navigation';
import {
  Agreement,
  Cashback,
  HomePage,
  Languages,
  MenusPage, Products,
  ProfilePage,
  RolesPage,
  SectionsPage,
  StaffPage,
  UsersPage
} from './pages';
import Transactions from "./pages/transactions/transactions";
import Reservations from "./pages/reservations/reservations";
import Notifications from "./pages/notifications/notifications";
import Spaces from "./pages/website/spaces/spaces";
import Slides from "./pages/website/slides/slides";
import AppSlides from "./pages/website/app-slides/slides"
import OrderNotificationNumbers from "./pages/website/orderNotificationNumbers/orderNotificationNumbers"
import UsersWithoutPercent from "./pages/users/usersWithoutPercent"
import PushNotifications from "./pages/pushNotifications/pushNotifications"

const routes = [

  {
    path: '/staff',
    component: StaffPage
  },
  {
    path: '/users',
    component: UsersPage
  },{
    path: '/manager-users',
    component: UsersWithoutPercent
  },
  {
    path: '/transactions',
    component: Transactions
  },
  {
    path: '/notifications',
    component: Notifications
  },
  {
    path: '/push-notifications',
    component: PushNotifications
  },
  {
    path: '/reservations',
    component: Reservations
  },
  {
    path: '/profile',
    component: ProfilePage
  },
  {
    path: '/home',
    component: HomePage
  },
  {
    path:'/roles',
    component:RolesPage
  },
  {
    path:'/handbook/sections',
    component:SectionsPage
  },
  {
    path:'/handbook/menus',
    component:MenusPage
  },
  {
    path:'/handbook/cashback',
    component:Cashback
  },
  {
    path:'/handbook/products',
    component:Products
  },
  {
    path:'/website/agreement',
    component:Agreement
  },
  {
    path:'/website/languages',
    component:Languages
  },
  {
    path:'/website/spaces',
    component:Spaces
  },{
    path:'/website/slides',
    component:Slides
  },{
    path:'/website/appSlides',
    component:AppSlides
  },{
    path:'/website/orderNotificationNumbers',
    component:OrderNotificationNumbers
  },

];

export default routes.map(route => {
  return {
    ...route,
    component: withNavigationWatcher(route.component)
  };
});
