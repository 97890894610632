import {Typeahead} from "react-bootstrap-typeahead";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import {Modal,Form} from "react-bootstrap";
const AutoComplete = ({label,labelKey,data,onChange,placeholder,selected}) =>{

	return  (
		<Form.Group>
			<Form.Label>{label}</Form.Label>
			<Typeahead
				id="basic-typeahead-single"
				labelKey={labelKey}
				onChange={onChange}
				options={data}
				placeholder={placeholder}
				selected={selected}
			/>
		</Form.Group>
	)
}

export default AutoComplete;