import { createSlice} from '@reduxjs/toolkit'
const initialState = []

const staffSlice = createSlice({
    name: 'Staff',
    initialState,
    reducers: {
        getStaffList:(state,action)=>{
           return action.payload;
        }
    }

})
export const { getStaffList } = staffSlice.actions

export default staffSlice.reducer
