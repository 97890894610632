import React, {useCallback, useEffect, useState} from 'react';
import 'devextreme/data/odata/store';
import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
} from 'devextreme-react/data-grid';
import {Api, i18n} from "../../../core";
import {useDispatch, useSelector} from "react-redux";
import {Box, Button} from "devextreme-react";
import {Edit, Loader} from "../../../assets/icons/assets";
import {Item} from "devextreme-react/box";
import CashbackModal from "../../../components/handbook/cashbackModal/cashbackModal";

export default function Cashback() {
  const dispatch = useDispatch();
  const cashBackList = useSelector(state=>state.HandBook.cardCashBackList)
  const [cashback,setCashback]= useState(null)
  const [btnLoader,setBtnLoader]=useState(-1)
  const [selectedGroupId,setSelectedGroupId]=useState(null)
  useEffect(()=>{
    dispatch(Api.HandBook.cardCashbackList())
      i18n.on("languageChanged",e=> {
          Api.Settings.ChangeLanguage(e).then(e=>{
              dispatch(Api.HandBook.cardCashbackList())
          })

      })
      return () =>{
          i18n.off("languageChanged",e=>{

          })
      }
      },[])


  return (
    <React.Fragment>
        <CashbackModal   data={cashback} setSelected={setCashback} onSave={e=>Api.HandBook.updateCashbackStatus(e).then(response=>{
            if(response.status){
                dispatch(Api.HandBook.cardCashbackList())
                setCashback(null)
            }
        })}/>
      <div style={{"padding":"20px 20px 20px 20px"}}>
            <Box
                direction="row"
                width="100%"
                height={"100%"}>
                <Item
                    ratio={1}
                   >
                    <div className={'dx-card '}>
                    <div style={{"padding":"10px 10px"}}>
                        <strong>{i18n.t("ბარათის ქეშბექი")}</strong>
                    </div>
                    <DataGrid
                        loadPanel={
                            {
                                enabled:true
                            }
                        }
                        selection={{
                            mode:'single'
                        }}
                        onSelectionChanged={({selectedRowsData})=> setSelectedGroupId(selectedRowsData[0]["group_id"])}
                        className={'dx-card wide-card'}
                        hoverStateEnabled={true}
                        dataSource={cashBackList}
                        showBorders={false}
                        focusedRowEnabled={true}
                        //defaultFocusedRowIndex={0}
                        columnAutoWidth={true}
                        columnHidingEnabled={false}
                        keyExpr="id"
                        allowColumnReordering={true}
                        allowColumnResizing={false}

                    >
                        <Paging defaultPageSize={30} />

                        <FilterRow visible={true} />
                       <Pager showPageSizeSelector={true} showInfo={true} />


                        <Column dataField={'id'} caption={"#"} alignment={"left"} width={90} allowFiltering={false} />
                        <Column
                            width={200}
                            dataField={'title'}
                            alignment={"center"}
                            caption={i18n.t("დასახელება")}
                        />

                        <Column
                            width={200}
                            alignment={"center"}
                            dataField={'min'}
                            caption={i18n.t("მინ. თანხა")}

                        />
                        <Column
                            width={200}
                            alignment={"center"}
                            dataField={'max'}
                            caption={i18n.t("მაქს. თანხა")}
                        />
                        <Column
                            width={250}
                            alignment={"center"}
                            dataField={'percent'}
                            caption={i18n.t("პროცენტი")}
                            cellRender={e=>{
                                return e.data.percent + "%"
                            }}
                        />

                        <Column dataField=""
                                width={"100%"}
                                alignment={"right"}
                                cssClass={"custom-button-icon"}
                                allowSorting={false}
                                cellRender={(e)=>{
                                    return  <div style={{display:'flex',justifyContent:'flex-end',paddingRight:"10px"}}>
                                        <Button
                                            disabled={btnLoader === e.key}
                                            icon={btnLoader === e.key? Loader:Edit}
                                            type="normal"
                                            style={{margin: 0, padding: 0,width:"35px",height:"35px"}}
                                            stylingMode="contained"
                                            onClick={() => setCashback({...e.data})}
                                        />
                                    </div>
                                } }
                        />
                    </DataGrid>
                    </div>
                </Item>
            </Box>
      </div>
    </React.Fragment>
)}
