import { createSlice} from '@reduxjs/toolkit'
const initialState = {
    cardCashBackList:[],
    sectionsList:[],
    websiteMenuList:[]
}

const sectionsSlice = createSlice({
    name: 'Sections',
    initialState,
    reducers: {
        getSectionsList:(state,action)=>{
           state.sectionsList = action.payload;
        },
        getCardCashbackList(state,action){
            state.cardCashBackList = action.payload;
        },
        getWebsiteMenuList:(state,action)=>{
            state.websiteMenuList = action.payload;
        },

    }

})
export const { getSectionsList,getCardCashbackList ,getWebsiteMenuList} = sectionsSlice.actions

export default sectionsSlice.reducer
