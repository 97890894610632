import http from "../http/http";
import Config from "../config/config.json"



const list = ()=>{
    return http.get({
        url: Config.NOTIFICATION.LIST
    })
}

const pushNotificationlist = ()=>{
    return http.get({
        url: Config.NOTIFICATION.PUSH_NOTIFICATION_LIST
    })
}
const sendPushNotification = ({title,subtitle})=>{
    return http.post({
        url: Config.NOTIFICATION.PUSH_NOTIFICATION_SEND,
        data:{title,subtitle}
    })
}

const sendGroupPushNotification = ({title,msg,users})=>{
    return http.post({
        url: Config.NOTIFICATION.USER_GROUP_PUSH_NOTIFICATION_SEND,
        data:{title,msg,users}
    })
}
const sendUserPushNotification = (data)=>{
    return http.post({
        url: Config.NOTIFICATION.USER_PUSH_NOTIFICATION_SEND,
        data:data
    })
}
const cancel=({id,loader})=>{
    return http.post({
        url: Config.NOTIFICATION.CANCEL.replace("{id}",id),
        loader:loader
    })
}

export  {
    list,
    pushNotificationlist,
    sendPushNotification,
    sendUserPushNotification,
    sendGroupPushNotification,
    cancel
}
