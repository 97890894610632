import React, {useEffect, useState} from 'react';
import 'devextreme/data/odata/store';
import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  Lookup, Editing
} from 'devextreme-react/data-grid';
import {Api, i18n} from "../../core";
import {useDispatch, useSelector} from "react-redux";
import {Button} from "devextreme-react";
import {CLoseIcon, Edit, Loader} from "../../assets/icons/assets";
import {UserDetails} from "../../components";
import notify from "devextreme/ui/notify";
import {UseConfirmation} from "../../core/hooks/useConfirmation";

export default function Notifications() {
  const dispatch = useDispatch();
  const confirmation = UseConfirmation();

  const users = useSelector(state=>state.Users)
  const [btnLoader,setBtnLoader]=useState(-1)
  const [selected,setSelected]=useState(null)
  const [data,setData]=useState([])
  useEffect(()=>{
    getTransactions()
    i18n.on("languageChanged",e=> {
      Api.Settings.ChangeLanguage(e).then(e=> {
        getTransactions()
      })
    })
    return () =>{
      i18n.off("languageChanged",e=>{

      })
    }
    },[])

  const getTransactions = ()=>{
    Api.Notification.list().then(response=>{
      if(response?.status){
        setData(response.data)
      }
    })
  }


  return (
    <React.Fragment>
      <div style={{"padding":"20px"}}>
         <strong>{i18n.t("შეტყობინებები")}</strong>
      </div>
      <div style={{"padding":"0 20px 20px 20px"}}>
        <div className={'dx-card '}>
          <DataGrid
              height={`${window.innerHeight - 150}px`}
              scrolling={{
                mode:'standard'
              }}

              className={'dx-card wide-card'}
              dataSource={data}
              showBorders={false}
              focusedRowEnabled={true}
              defaultFocusedRowIndex={0}
              columnAutoWidth={true}
              columnHidingEnabled={true}
              keyExpr="id"
              allowColumnReordering={true}
              allowColumnResizing={true}

          >
            <Paging defaultPageSize={20} />
            <Pager showPageSizeSelector={true} showInfo={true} />
            <FilterRow visible={true} />

            <Column dataField={'id'}  alignment={"center"} width={90} hidingPriority={6} allowFiltering={false} />
            <Column
                alignment={"center"}
                dataField={'title'}
                caption={i18n.t("სათაური")}
                hidingPriority={7}
            />
            <Column
                alignment={"center"}
                dataField={'description'}
                caption={i18n.t("ტექსტი")}
                hidingPriority={7}
            />


            <Column
                alignment={"center"}
                dataField={'timestamp'}
                caption={i18n.t("გაგზავნის დრო")}
                hidingPriority={3}
            />
            <Column
                alignment={"center"}
                dataField={'fullName'}
                caption={i18n.t("მომხმარებელი")}
                hidingPriority={3}
            />
            <Column
                alignment={"center"}
                dataField={"phone"}
                caption={i18n.t("მობილური")}
                hidingPriority={3}
            />

          </DataGrid>
        </div>
      </div>
    </React.Fragment>
)}
