import React, {useCallback, useEffect, useMemo, useState} from 'react';
import 'devextreme/data/odata/store';
import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
} from 'devextreme-react/data-grid';
import {Api, i18n} from "../../../core";
import {Box, Button} from "devextreme-react";
import {CLoseIcon, Edit, Loader, PlusIcon} from "../../../assets/icons/assets";
import {Item} from "devextreme-react/box";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import notify from "devextreme/ui/notify";
import SlidesAppModal from "../../../components/handbook/slidesAppModal/slidesAppModal"
import {Form} from "react-bootstrap"

export default function AppSlides() {
  const [slides,setSlides]=useState([])
  const [selected,setSelected]= useState(null)
  const [btnLoader,setBtnLoader]=useState(-1)
  const [lang,setLang]=useState("")

  useEffect(()=>{
      getSlides()
  },[])


  const listSlides= useMemo(() => {
      if(lang){
          return slides.filter(s=>s.lang_key===lang)
      }

      return slides
  },[slides,lang])

    const getSlides = () => {
       Api.HandBook.getAppSlides().then(response=>{
		   setSlides(response.status?response.data:[])
       })
   }


  return (
    <React.Fragment>
        <SlidesAppModal  data={selected}  onClose={e=>setSelected(null)} onSave={slide=>{
            Api.HandBook.saveOrUpdateAppSlide(slide).then(response=>{
                notify(response.data,response.status?'success':'error')
                if(response.status){
					getSlides()
                   setSelected(null)
                }
            })

        }}/>
      <div style={{"padding":"20px 20px 20px 20px"}}>
            <Box
                direction="row"
                width="100%"
                height={"100%"}>
                <Item
                    ratio={1}
                   >
                    <div className={'dx-card '}>
                        <div style={{"padding":"10px 10px",display:'flex',justifyContent:'space-between' }}>
                            <strong>{i18n.t("სლაიდები")}</strong>
                            <Form.Group className="mb-3">
                                <Form.Select id="disabledSelect" value={lang} onChange={e=>setLang(e.target.value)}>
                                    <option value="">{i18n.t("აირჩიეთ ენა")}</option>
                                    <option value="ka">{i18n.t("ქართული")}</option>
                                    <option value="ru">{i18n.t("რუსული")}</option>
                                    <option value="en">{i18n.t("ინგლისური")}</option>
                                    <option value="">{i18n.t("ყველა")}</option>
                                </Form.Select>
                            </Form.Group>
                            {
                                <Button
                                    icon={PlusIcon}
                                    type="normal"
                                    style={{margin: 0, padding: 0,width:"35px",height:"35px"}}
                                    stylingMode="contained"
                                    onClick={() => {
                                        setSelected({
                                            title:'',
                                            status:1,
                                            image:"",
											url:"",
											sort:0
                                        })
                                    }}
                                />
                            }

                        </div>
                    <DataGrid
                        height={`${window.innerHeight - 150}px`}
                        scrolling={{
                            mode:'standard'
                        }}
                        loadPanel={
                            {
                                enabled:true
                            }
                        }
                        selection={{
                            mode:'single'
                        }}
                        className={'dx-card wide-card'}
                        hoverStateEnabled={true}
                        dataSource={listSlides}
                        showBorders={false}
                        focusedRowEnabled={true}
                        //defaultFocusedRowIndex={0}
                        columnAutoWidth={true}
                        columnHidingEnabled={true}
                        keyExpr="id"
                        allowColumnReordering={true}
                        allowColumnResizing={true}

                    >
                        <Paging defaultPageSize={30} />

                        <FilterRow visible={true} />
                        <Pager showPageSizeSelector={true} showInfo={true} />

                        <Column dataField={'id'} caption={"#"} alignment={"left"} width={90} hidingPriority={2} allowFiltering={false} />

                        <Column
                            dataField={'title'}
                            caption={i18n.t("დასახელება")}
                            hidingPriority={6}
                        />

						<Column
							dataField={'url'}
							caption={i18n.t("ბმული")}
							hidingPriority={6}
						/>

						<Column
							dataField={'image'}
							caption={i18n.t("სურათი")}
							hidingPriority={6}
						/>
						<Column
							dataField={'lang_key'}
							caption={i18n.t("ენა")}
							hidingPriority={6}
						/>
                        <Column
							dataField={'sort'}
							caption={i18n.t("რიგითი ნომერი")}
							hidingPriority={6}
						/>

                        <Column
                            width="100"
                            dataField={'status'}
                            caption={i18n.t("სტატუსი")}
                            hidingPriority={6}
                            cellRender={(e)=>{

                                return  <Button onClick={()=>{
                                    Api.HandBook.updateAppSlideStatus(e.data.id,e.data.status===1?-1:1).then(response=>{
										getSlides()
                                    })
                                }}>
                                    <FontAwesomeIcon icon={e.data.status===1? faCheck: faTimes} color={e.data.status===1?"green":"red"} size={"1x"} />
                                </Button>

                            }}
                        />
                        <Column dataField=""
                                cssClass={"custom-button-icon"}
                                width={50}
                                allowSorting={false}
                                cellRender={(e)=>{
                                    return  <Button
                                        disabled={btnLoader === e.key}
                                        icon={btnLoader === e.key? Loader:Edit}
                                        type="normal"
                                        style={{margin: 0, padding: 0,width:"35px",height:"35px"}}
                                        stylingMode="contained"
                                        onClick={() => {
                                            setSelected(e.data)
                                        }}
                                    />
                                } }
                        />
                       <Column dataField=""
										cssClass={"custom-button-icon"}
										width={50}
										allowSorting={false}
										cellRender={(e)=>{
											return e.data.id && <Button
												disabled={btnLoader === e.key}
												icon={btnLoader === e.key? Loader:CLoseIcon}
												type="normal"
												style={{margin: 0, padding: 0,width:"35px",height:"35px"}}
												stylingMode="contained"
												onClick={() => {

													if(window.confirm("დარწმუნებული ხართ, რომ გსურთ წაშლა")){
														Api.HandBook.deleteAppSlide(e.key).then(response=>{
															if(response.status){
																getSlides()
															}
														})
													}
												}}
											/>
										} }
								/>
                    </DataGrid>
                    </div>
                </Item>
            </Box>
      </div>
    </React.Fragment>
)}
