import i18n from "i18next";
import ChainedBackend from "i18next-chained-backend";
import HttpBackend from "i18next-http-backend";
import resourcesToBackend from "i18next-resources-to-backend";
import {useTranslation,withTranslation} from "react-i18next";

const bundledResources = {
	en: {
		"main":"test"
	}
};

i18n
	.use(ChainedBackend)
	.init({
		whitelist:["ka","ru","en"],
		lng: localStorage.getItem("lang")||"ka",
//  language to use if translations in user language are not available
		fallbackLng: "ka",
		debug: true,
//  string or array of namespaces to load
		ns: "main",
//  default namespace used if not passed to 'translation function'
		defaultNS: "main",
		saveMissing: false, // send not translated keys to endpoint
		nonExplicitWhitelist: true,

		interpolation: {
			escapeValue: false,
		},
		react: {
			bindI18n: 'languageChanged',
			bindI18nStore: '',
			transEmptyNodeValue: '',
			transSupportBasicHtmlNodes: true,
			transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', '!', "'", "."],
			useSuspense: true,
		},
		backend: {
			backends: [
				HttpBackend,
			],
			backendOptions: [{
				loadPath: '/api/{{lng}}/locale/data?lang={{lng}}&ns={{ns}}',
				//addPath: '/api/locale/setKey?lang={{lng}}',

			}]
		}

	})
export {
	i18n,
	useTranslation,
	withTranslation,
}

