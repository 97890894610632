import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';
import React, {useEffect, Suspense, useState} from 'react';
import { HashRouter as Router } from 'react-router-dom';
import './dx-styles.scss';
import LoadPanel from 'devextreme-react/load-panel';
import { NavigationProvider } from './contexts/navigation';
import { AuthProvider, useAuth } from './contexts/auth';
import { useScreenSizeClass } from './utils/media-query';
import Content from './Content';
import UnauthenticatedContent from './UnauthenticatedContent';
import {useUser} from "./core/hooks/useUser";
import {ConfirmationPopup} from "./components/confirm/confirm";
import {i18n} from "./core";

function App() {
  const [loaded,setLoaded]=useState(false)
  const { user, loading } = useAuth();
  const {User,CheckSession} = useUser()
  useEffect(() => {
    CheckSession().then(response=>{
      i18n.changeLanguage(localStorage.getItem("lang") || 'ka', ).then (r =>setLoaded(true))
    })


  }, []);
  if (loading || !loaded) {
    return <LoadPanel visible={true} />;
  }

  if (User.isLogged) {
    return <Content />;
  }

  return <UnauthenticatedContent />;
}

export default function Root() {
  const screenSizeClass = useScreenSizeClass();
  return (
    <Router>
      <AuthProvider>
        <NavigationProvider>
          <div className={`app ${screenSizeClass}`}>
            <Suspense fallback={"loading"}>
              <App />
            </Suspense>
            <ConfirmationPopup/>
          </div>
        </NavigationProvider>
      </AuthProvider>
    </Router>
  );
}


