import { createSlice} from '@reduxjs/toolkit'
const initialState = []

const menuSlice = createSlice({
    name: 'Menus',
    initialState,
    reducers: {
        getGroups:(state,action)=>{
           return action.payload;
        }
    }

})
export const { getGroups } = menuSlice.actions

export default menuSlice.reducer
