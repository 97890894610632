import React, {useEffect, useRef, useCallback, useMemo, useState} from 'react';
import TreeView from 'devextreme-react/tree-view';
import { useNavigation } from '../../contexts/navigation';
import { useScreenSize } from '../../utils/media-query';
import './side-navigation-menu.scss';

import * as events from 'devextreme/events';
import {useSelector} from "react-redux";
import {Api, i18n} from "../../core";
import _ from "lodash";
import {nonPersistentNotification, persistentNotification, requestPermission} from "../../core/notification"


export default function SideNavigationMenu(props) {
  const [ordersCount,setOrdersCount]=useState(0)
  const navigation = useSelector(state => state?.User?.data?.views)
  const {
    children,
    selectedItemChanged,
    openMenu,
    compactMode,
    onMenuReady,
    UpdateButton
  } = props;

  const { isLarge } = useScreenSize();
  function normalizePath () {
    return _.map(navigation,(item) => {
      if(item.path && !(/^\//.test(item.path))){
        item.path = `/${item.path}`;
      }
      return {...item, expanded: isLarge,text:i18n.t(item.text)};
    });
  }

  const items = useMemo(
    normalizePath,
    [i18n.language]
  );

  const { navigationData: { currentPath } } = useNavigation();

  const treeViewRef = useRef();
  const wrapperRef = useRef();
  const getWrapperRef = useCallback((element) => {
    const prevElement = wrapperRef.current;
    if (prevElement) {
      events.off(prevElement, 'dxclick');
    }

    wrapperRef.current = element;
    events.on(element, 'dxclick', e => {
      openMenu(e);
    });
  }, [openMenu]);

  useEffect(() => {
    const treeView = treeViewRef.current && treeViewRef.current.instance;
    if (!treeView) {
      return;
    }

    if (currentPath !== undefined) {
      treeView.selectItem(currentPath);
      treeView.expandItem(currentPath);
    }

    if (compactMode) {
      treeView.collapseAll();
    }
  }, [currentPath, compactMode]);

  useEffect(()=>{
    const interval = setInterval(()=>{
      getOrders();
    },5000)
    return  ()=>clearInterval(interval)
  },[])
  /*useEffect(() => {
    requestPermission((status)=>{
    nonPersistentNotification({
        data: "ახალი ჯავშანი!",
        body:"შემოვიდა ახალი ჯავშანი !",icon:"/icon.png",
        silent:false,
        requireInteraction:false,

          actions:[{action:"open",title:"გახსნა"}]

      })
    })
  },[])*/
  const getOrders  = () =>{
    Api.Reservation.Count().then(response=>{
      if(response?.status){
         setOrdersCount((response?.data))
        /*if(localStorage.getItem("lastOrder") &&  parseInt(localStorage.getItem("lastOrder")) !==parseInt(response?.data?.id)){
          nonPersistentNotification({
            data: "ახალი ჯავშანი!",
            body:"შემოვიდა ახალი ჯავშანი !",icon:"/icon.png",
            silent:false,
            requireInteraction:false,
            badge:"test",


                        actions:[{action:"open",title:"გახსნა"}]

          })
        }

        localStorage.setItem("lastOrder",response?.data?.id)*/
      }
    })
  }

  return (
    <div
      className={'dx-swatch-additional side-navigation-menu'}
      ref={getWrapperRef}
    >
      {children}
      <div className={'menu-container'}>
        <TreeView
          ref={treeViewRef}
          items={items}
          keyExpr={'path'}

          itemRender={e=>{
            if(e?.path ==="/updateProducts"){
              return  (
                  <div style={{padding:"2px 8px",display:'flex',alignItems:'center',height:"100%"}} >
                    <UpdateButton/>
                  </div>
              )
            }

            return (
                <div style={{padding:"2px 8px",display:'flex',alignItems:'center'}}>
                  <i className={`dx-icon-${e.icon}`} style={{fontSize:"1.4rem",marginLeft:"10px"}}/>
                  <span className={e?.path==="/reservations" && ordersCount?.count>0?"blink_me":''} style={{fontSize:"0.8rem",marginLeft:`${e?.expanded !==undefined?"20px":"-30px"  }`,display:'flex', justifyContent:'space-between' , color:e?.path==="/reservations" && ordersCount.count>0?"red":''}}>{e.text} &nbsp;&nbsp; {e?.path==="/reservations" && <span >( {ordersCount.count} )</span> }</span>
                </div>
            )
          }}
          expandedExpr="isExpanded"
          selectionMode={'single'}
          focusStateEnabled={true}
          expandEvent={'click'}
          onItemClick={(e)=>{
            selectedItemChanged(e)
          }}
          onContentReady={onMenuReady}
          width={'100%'}

        />
      </div>
    </div>
  );
}
