import React, {useEffect, useState} from 'react';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import Button from 'devextreme-react/button';
import UserPanel from '../user-panel/user-panel';
import './header.scss';
import { Template } from 'devextreme-react/core/template';
import {DropDownButton} from "devextreme-react";
import {Api, i18n, useTranslation} from "../../core";
import {useUser} from "../../core/hooks/useUser";
import {Col, OverlayTrigger, Popover, Row} from "react-bootstrap";

export default function Header({ menuToggleEnabled, title, toggleMenu }) {
  const {CheckSession} = useUser();
  const [serverStatus,setServerStatus]=useState(null)
  const {t} = useTranslation()
  const [updateData,setUpdateData]=useState(null)
  useEffect(()=>{
     if(!window.updateData){
       window.updateData = function (){
         Api.HandBook.LastUpdate().then(response=>{
           if(response.status){
             setUpdateData(response.data)
           }
         })
       }
       window.updateData();
     }
    Api.HandBook.getGeoResServerStatus().then(response=>{
      setServerStatus(response)
    })

  },[])


  return (
    <header className={'header-component'}>
      <Toolbar className={'header-toolbar'}>
        <Item
          visible={menuToggleEnabled}
          location={'before'}
          widget={'dxButton'}
          cssClass={'menu-button'}
        >
          <Button icon="menu" stylingMode="text" onClick={toggleMenu} />
        </Item>
        <Item
          location={'before'}
          cssClass={'header-title'}
          text={title}
          visible={!!title}
        />

        <Item>
          { updateData?.timestamp? <OverlayTrigger
              placement="bottom"
              trigger="click"

              overlay={
                <Popover body >
                  <Row>
                    <Col>{t("დაემატა")}</Col>
                    <Col>{updateData?.newCount}</Col>
                  </Row>
                  <Row>
                    <Col>{t("წაიშალა")}</Col>
                    <Col>{updateData?.deletedCount}</Col>
                  </Row>
                  <Row>
                    <Col>{t("განახლა")}</Col>
                    <Col>{updateData?.updatedCount}</Col>
                  </Row>

                </Popover>
              }
          >
            <Button className={"lastUpdateButton"}>ბოლო განახლება: {updateData?.timestamp}</Button>
          </OverlayTrigger>:null}

        </Item>
        {
          serverStatus !==null &&  <Item>
              <Button className={"lastUpdateButton"} type={serverStatus?.status? 'success':'danger'} > GEORESS-სთან {serverStatus?.status? 'კავშირი არის':'კავშირი არ არის'}</Button>
            </Item>
        }

        <Item
          location={'after'}
          locateInMenu={'auto'}
          menuItemTemplate={'userPanelTemplate'}
        >
          <Button
            className={'user-button authorization'}
            width={210}
            height={'100%'}
            stylingMode={'text'}
          >
            <UserPanel menuMode={'context'} />

          </Button>
          <DropDownButton
              text={i18n.language}
              icon="language"
              dropDownOptions={{width:"100px"}}
              items={['ka', 'ru','en']}
              onItemClick={e=>{
                i18n.changeLanguage(e.itemData.toLowerCase()).then((response)=>{
                  localStorage.setItem("lang",e.itemData.toLowerCase())
                  CheckSession()
                })
              }}
          />
        </Item>
        <Template name={'userPanelTemplate'}>
          <UserPanel menuMode={'list'} />
        </Template>
      </Toolbar>
    </header>
)}
