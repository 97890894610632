import React, {useCallback, useEffect, useMemo, useState} from 'react';
import 'devextreme/data/odata/store';
import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
} from 'devextreme-react/data-grid';
import {Api, i18n} from "../../../core";
import {Box, Button} from "devextreme-react";
import {CLoseIcon, Edit, Loader, PlusIcon} from "../../../assets/icons/assets";
import {Item} from "devextreme-react/box";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import notify from "devextreme/ui/notify";
import SlidesAppModal from "../../../components/handbook/slidesAppModal/slidesAppModal"
import {Form} from "react-bootstrap"
import {deleteOrderNotificationNumber} from "../../../core/api/handbook"

export default function OrderNotificationNumbers() {
  const [slides,setSlides]=useState([])
  const [selected,setSelected]= useState(null)
  const [btnLoader,setBtnLoader]=useState(-1)
  const [lang,setLang]=useState("")
const [number,setNumber]=useState("")
  useEffect(()=>{
      getSlides()
  },[])


  const listSlides= useMemo(() => {
      if(lang){
          return slides.filter(s=>s.lang_key===lang)
      }

      return slides
  },[slides,lang])

    const getSlides = () => {
       Api.HandBook.getOrderNotificationNumbers().then(response=>{
		   setSlides(response.status?response.data:[])
       })
   }


  return (
    <React.Fragment>

      <div style={{"padding":"20px 20px 20px 20px"}}>
            <Box
                direction="row"
                width="100%"
                height={"100%"}>
                <Item
                    ratio={1}
                   >
                    <div className={'dx-card '}>
                        <div style={{"padding":"10px 10px",display:'flex',justifyContent:'space-between' }}>
                            <strong>{i18n.t("ნომრები")}</strong>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                            }}>
                                <Form.Group className="mb-3">
                                    <Form.Control type="text" placeholder={i18n.t("ახალი ნომერი")} value={number} onChange={e=>setNumber(e.target.value)} />
                                </Form.Group>
                                 &nbsp;&nbsp;
                                {
                                    <Button
                                        icon={PlusIcon}
                                        type="normal"
                                        style={{margin: 0, padding: 0,width:"35px",height:"35px"}}
                                        stylingMode="contained"
                                        onClick={() => {
                                            Api.HandBook.addOrderNotificationNumber(number).then(response=>{
                                                if(response.status){
                                                    notify(response.message,"success",3000)
                                                    getSlides()
                                                    setNumber("")
                                                }else{
                                                    notify(response.message,"error",3000)
                                                }
                                            })
                                        }}
                                    />
                                }

                            </div>

                        </div>
                    <DataGrid
                        height={`${window.innerHeight - 150}px`}
                        scrolling={{
                            mode:'standard'
                        }}
                        loadPanel={
                            {
                                enabled:true
                            }
                        }
                        selection={{
                            mode:'single'
                        }}
                        className={'dx-card wide-card'}
                        hoverStateEnabled={true}
                        dataSource={listSlides}
                        showBorders={false}
                        focusedRowEnabled={true}
                        //defaultFocusedRowIndex={0}
                        columnAutoWidth={true}
                        columnHidingEnabled={true}
                        keyExpr="id"
                        allowColumnReordering={true}
                        allowColumnResizing={true}

                    >
                        <Paging defaultPageSize={30} />

                        <FilterRow visible={true} />
                        <Pager showPageSizeSelector={true} showInfo={true} />

                        <Column dataField={'id'} caption={"#"} alignment={"left"} width={90} hidingPriority={2} allowFiltering={false} />

                        <Column
                            dataField={'number'}
                            caption={i18n.t("ნომერი")}
                            hidingPriority={6}
                        />

                       <Column dataField=""
										cssClass={"custom-button-icon"}
										width={50}
										allowSorting={false}
										cellRender={(e)=>{
											return e.data.id && <Button
												disabled={btnLoader === e.key}
												icon={btnLoader === e.key? Loader:CLoseIcon}
												type="normal"
												style={{margin: 0, padding: 0,width:"35px",height:"35px"}}
												onClick={() => {

													if(window.confirm("დარწმუნებული ხართ, რომ გსურთ წაშლა")){
														Api.HandBook.deleteOrderNotificationNumber(e.key).then(response=>{
															if(response.status){
																getSlides()
															}
														})
													}
												}}
											/>
										} }
								/>
                    </DataGrid>
                    </div>
                </Item>
            </Box>
      </div>
    </React.Fragment>
)}
