import React, {useEffect, useState} from "react";
import {Box, Button, FileUploader,  Popup} from "devextreme-react";
import {GroupItem, SimpleItem} from "devextreme-react/form";
import {Api, i18n} from "../../core";
import {Tabs} from "../tabs/tabs";
import DataGrid, {Column} from "devextreme-react/data-grid";

import {UseConfirmation} from "../../core/hooks/useConfirmation";
import {Col, Form, Modal, Row} from "react-bootstrap";
import notify from "devextreme/ui/notify";
import {Conf} from "../../core/config/Conf";
import OrderModal from "../users/orderModal"
import _ from "lodash"
import utils from "../../core/utils/Utils"
import moment from "moment"

export default function UserPayment(props){

	const [data,setData]=useState(null);

	useEffect(()=>{
		console.log(props)
		getPercent();
		if(props.data){
			setData({...props.data, percentType:props.data?.card_cashback_id===-1?'personal':'standart'})
		}else{
			setData(null)
		}

	},[props.data])

	const [loader,setLoader]=useState(false)
	const [paid,setPaid]=useState( false)
	const [mode,setMode]=useState( "create")
	const [payTypeModalVisible,setPayTypeModalVisible]=useState(false)
	const [check,setCheck]=useState(false)
	const [selectedPayType,setSelectedPayType]=useState( {"id": 4, "value": "ლოიალურით და ბანკის ბარათით"})

	const [baseAmount, setBaseAmount] = useState(0);
	const [payId,setPayId]=useState(props?.route?.params?.mode === "update"?data["transId"]:'')
	const [payError,setPayError]=useState("")

	const [payAmount,setPayAmount]=useState(0)
	const [payAmountError,setPayAmountError]=useState("")

	const [cashBack,setCashBackAmount]=useState(0)
	const [cashBackError,setCashBackError]=useState("")

	const [additionalAmount,setAdditionalAmount]=useState(0)
	const [additionalAmountError,setAdditionalAmmountError]=useState("")

	const [percent,setPercent]=useState(null)
	const [percentData,setPercentData]=useState([])
	const [status,setStatus]=useState(null)

	const isEnabledSignIn=()=>{
		if(selectedPayType){
			switch (selectedPayType?.id){
				case 1:
				case 2:
				case 5: return   payId !== "" && payAmount !=="" && payAmountError ==="" && payError===""
				case 3:
				case 4: return    (payId !== "" && payAmount !=="" && payAmountError ==="" && payError==="" &&  additionalAmount !=="" && additionalAmountError==="");
			}
		}
		return false
	}
	const getPercent = ()=>{
		Api.Order.getPercent().then(response=>{
			if(response?.status){
				const find = _.find(response?.data,(item)=>item["SCDefault"]==="True" )
				if(find){
					setPercent(find["SCValue"])
				}else{
					setPercent(0)
				}
				setPercentData(response?.data)
			}else{
				setPercent(0)
				setPercentData([])
			}
		})
	}
	const getOrderDetails = (payId,mode)=>{
		Api.Order.getOrderDetails({orderNumber:payId,mode:mode}).then(response=>{
			if(response?.status){
				setStatus(response?.data?.CurrentStatus);
				let amount =(response?.data?.CurrentStatus==="Closed")?parseFloat(response?.data?.TotalSale): parseFloat(response?.data?.sumPrice * (1+(percent/100)))||0
				setBaseAmount(response?.data?.sumPrice);
				setAdditionalAmount( (amount).toFixed(2).replace(',',"."))
				setPayAmount((amount - response?.data?.CardPayment).toFixed(2))
				setCashBackAmount(response?.data?.CardPayment?parseFloat(response?.data?.CardPayment)?.toFixed(2)||0:0)
				setCheck(true)

			}else{

				notify(response.data, response.status?"success":'error', 2000);

				setAdditionalAmount(0)
			}
		})

	}
	function getParams() {
		if(selectedPayType){
			switch (selectedPayType?.id){
				case 1:

				case 2: return   {
					user_id:data.id,
					card_id:data?.card,
					pay_type:selectedPayType.id,
					payId:payId,
					amount:payAmount,
					cashback:0,
					sumAmount:additionalAmount

				}
				case 3:
				case 4:
					return   {
						user_id:data.id,
						card_id:data?.card,
						pay_type:selectedPayType.id,
						payId:payId,
						amount:payAmount,
						cashback:cashBack,
						sumAmount:additionalAmount

					}
				case 5:
					return   {
						user_id:data.id,
						card_id:data?.card,
						pay_type:selectedPayType.id,
						payId:payId,
						amount:0,
						cashback:cashBack,
						sumAmount:additionalAmount

					}
			}

		}
	}
	return <Popup
		maxWidth={"500px"}
		height={'800px'}
		showTitle={true}
		title={ "test" }
		dragEnabled={true}
		closeOnOutsideClick={true}
		visible={data !==null}
		onHiding={e=>props.setSelected(null)}
		contentRender={()=>(
			<div style={{display:'flex',flexDirection:'column',height:'100%'}}>
				{
					paid &&  (!data?.trans_status || data.trans_status===3) && (
						<Button  type="normal" onClick={()=>{
							setMode("update")
							setPaid(false)
						}
						}>
							ჩასწორება
						</Button>
					)
				}
				<div style={{marginTop:"20px",flex:1,height: 'calc(100% - 100px)'}}>
					{
						paid? (
								<div>
									<div style={{border:"1px solid gray",padding:10}}>
										<div style={{display:'flex',justifyContent:"space-between"}}>
											<div><h5>{i18n.t("სახელი გვარი")}</h5></div>
											<div><h5>{data.firstName.concat(" ",data.lastName)}</h5></div>
										</div>
										<div style={{display:'flex',justifyContent:"space-between"}}>
											<div><h5>{i18n.t("მობილური")}</h5></div>
											<div><h5>{data?.phone}</h5></div>
										</div>
										<div style={{display:'flex',justifyContent:"space-between"}}>
											<div><h5>{i18n.t("ბარათის ნომერი")}</h5></div>
											<div><h5>{data?.card}</h5></div>
										</div>
										<div style={{display:'flex',justifyContent:"space-between"}}>
											<div><h5>{i18n.t("დაგროვებული ქეშბექი")}</h5></div>
											<div><h5>{data?.cashback}</h5></div>
										</div>
									</div>
									<div>
										<div style={{display:'flex',justifyContent:"space-between"}}>
											<div><h5>{i18n.t("ჩეკის ნომერი")}</h5></div>
											<div><h5>{payId}</h5></div>
										</div>
										<div style={{display:'flex',justifyContent:"space-between"}}>
											<div><h5>{i18n.t("სრული თანხა")}</h5></div>
											<div><h5>{additionalAmount}</h5></div>
										</div>

										<div style={{display:'flex',justifyContent:"space-between"}}>
											<div><h5>{i18n.t("ქეშბექიდან გამოყენებული თანხა.")}</h5></div>
											<div><h5>{cashBack} ლ</h5></div>
										</div>
										<div style={{display:'flex',justifyContent:"space-between"}}>
											<div><h5>{i18n.t("მომსახურების საკომისიო.")}</h5></div>
											<div><h5>{percent}%</h5></div>
										</div>
										<div style={{display:'flex',justifyContent:"space-between"}}>
											<div><h5>{i18n.t("გადახდილი თანხა.")}</h5></div>
											<div><h5>{payAmount}ლ</h5></div>
										</div>
										<div style={{display:'flex',justifyContent:"space-between"}}>
											<div><h5>{i18n.t("გატარების დრო.")}</h5></div>
											<div><h5>{data?.trans_date}</h5></div>
										</div>
									</div>

									<div >
										{

											<Button style={{marginTop:50}}  type="danger" onClick={()=>{

												Api.Users.deleteTransactionByPayId({
													pay_id:payId,
													loader:setLoader
												}).then(response=>{

													notify(response.data, response.status?"success":'error', 2000);

													if(response.status){
														props.setSelected(null)
													}
												})
											}
											}>
												ტრანზაქციის გაუქმება

											</Button>

										}
									</div>
								</div>
							):
							(
								<div>
									<div style={{display:'flex',justifyContent:"space-between"}}>
										<div><h4>{i18n.t("ბარათის ნომერი")}</h4></div>
										<div><h4>{data?.card}</h4></div>
									</div>
									<div style={{display:'flex',justifyContent:"space-between"}}>
										<div><h4>{i18n.t("ქეშბექი")}</h4></div>
										<div><h4>{data?.cashback} ლარი</h4></div>
									</div>
									<hr/>
									<div style={{display:'flex',justifyContent:"space-between",alignItems:'flex-end'}}>
										<div style={{flex:1}}><Form.Label htmlFor="inputPassword5">ჩეკის ნომერი</Form.Label>
											<Form.Control
												value={payId}
												type="text"
												id="inputPassword5"
												aria-describedby="passwordHelpBlock"
												onChange={(value)=>{
													setPayId(value.target.value)
												}}
											/></div>
										<div>
											<Button variant="secondary"  style={{padding:'1.25rem'}} onClick={()=>getOrderDetails(payId)}>
												შემოწმება
											</Button>
										</div>
									</div>
									<div style={{display:'flex',justifyContent:"space-between",alignItems:'flex-end',marginTop:10}}>
										<div style={{flex:1}}><Form.Label htmlFor="input2">სულ გადასახდელი თანხა</Form.Label>
											<Form.Control
												disabled={true}
												type="number"
												id="input2"
												value={additionalAmount}
												placeholder={"0.00"}
												onChange={(e)=>{
													let value = e.target.value.replace(',',".")
													let amount = parseFloat(value)||0
													setAdditionalAmount(amount)
													setPayAmount((amount - cashBack).toFixed(2))
												}}
												aria-describedby="helpInput2"

											/>
											<Form.Text id="helpInput2" muted>
												<span  style={{color:'red'}}>{additionalAmountError}</span>
											</Form.Text>
										</div>
									</div>
									<div style={{display:'flex',justifyContent:"space-between",alignItems:'flex-end',marginTop:10}}>
										<div style={{flex:1}}><Form.Label htmlFor="input3">გამოყენებული თანხა ქეშბექიდან</Form.Label>
											<Form.Control
												type="number"
												id="input3"
												disabled={true}
												value={cashBack}
												onChange={(event)=>{
													let value = event.target.value.replace(',',".")
													//utils.validateEmpty(value,setCashBackError)
													let amount = parseFloat(value)||0;
													setCashBackAmount(value)
													setPayAmount((additionalAmount-amount).toFixed(2))
												}}
												aria-describedby="helpInput3"

											/>
											<Form.Text id="helpInput3" muted>
												<span  style={{color:'red'}}>{cashBackError}</span>
											</Form.Text>
										</div>
									</div>
									<div style={{display:'flex',justifyContent:"space-between",alignItems:'flex-end',marginTop:10}}>
										<div style={{flex:1}}><Form.Label htmlFor="input3">პროცენტი</Form.Label>
											<div>
												{
													_.map(percentData,value => {
														console.log(value["SCValue"]===percent)
														return (
															<Button type={value["SCValue"]===percent?"success":"primary"}  width={50} height={50} style={{margin:10}} onClick={()=>{
																let amount = parseFloat(baseAmount * (1+(value["SCValue"]/100)))||0;
																setAdditionalAmount(amount.toFixed(2))
																setPayAmount((amount - cashBack).toFixed(2))
																setPercent(value["SCValue"])
															}}>
																{value["SCValue"]}
															</Button>
														)
													})
												}
											</div>
										</div>
									</div>
									{
										selectedPayType && [1,2,3,4].indexOf(selectedPayType.id)>-1 && (
											<div style={{display:'flex',justifyContent:"space-between",alignItems:'flex-end',marginTop:10}}>
												<div style={{flex:1}}><Form.Label htmlFor="input4">გადასახდელი თანხა</Form.Label>
													<Form.Control
														type="number"
														id="input4"
														value={payAmount}
														onChange={(event)=>{
															let value = event.target.value.replace(',',".")
															utils.validateEmpty(value,setPayAmountError)
															setPayAmount(parseFloat(value))
														}}
														aria-describedby="helpInput4"
													/>
													<Form.Text id="helpInput4" muted>
														<span  style={{color:'red'}}>{payAmountError}</span>
													</Form.Text>
												</div>
											</div>
										)
									}
								</div>
							)
					}

				</div>
				<Modal.Footer>
					{
						paid? <div style={{flex:1,flexDirection:'row'}}>
							<Button  type="success" onClick={()=>{
								Api.Users.accept_transaction({
									pay_id:payId,
									cashback:cashBack,
									loader:setLoader
								}).then(response=>{
									notify(response.data, response.status?"success":'error', 2000);
									if(response.status){
										props?.setSelected(null)
									}
								})
							}}>
								დადასტურება
							</Button>
						</div>:(
							<div style={{flex:1,flexDirection:'row'}}>
								<Button disabled={!isEnabledSignIn() || loader} type="normal"
										onClick={()=>{
											if(isEnabledSignIn() && check || !loader){
												let params = getParams()

												if(parseFloat(params?.amount)+parseFloat(params?.cashback) !==parseFloat(params?.sumAmount)){
													notify("შეამოწმეთ თანხა", 'error', 2000);
													return;
												}


												Api.Users.charge({
													params:params,
													loader:setLoader,
													mode:mode,
												}).then(response=>{

													notify(response.data, response.status?"success":'error', 2000);

													if(response.status){
														setData({...data,"trans_date":moment().format("YYYY-MM-DD HH:mm:ss")})
														setMode("update")
														setPaid(true)
														//props.navigation.navigate("HomeScreen")
													}
												})

											}
										}}
								>
									{loader?"მუშავდება...":"გატარება"}
								</Button>
							</div>
						)
					}


					<Button variant="secondary" onClick={()=>props?.setSelected(null)}>
						დახურვა
					</Button>
				</Modal.Footer>
			</div>
		)}
		showCloseButton={true}
	/>
}


