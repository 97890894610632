import React, {useEffect, useState} from "react";
import {Box, Button, FileUploader,  Popup} from "devextreme-react";
import {GroupItem, SimpleItem} from "devextreme-react/form";
import {Api, i18n} from "../../core";
import {Tabs} from "../tabs/tabs";
import DataGrid, {Column} from "devextreme-react/data-grid";

import {UseConfirmation} from "../../core/hooks/useConfirmation";
import {Col, Form, Modal, Row} from "react-bootstrap";
import notify from "devextreme/ui/notify";
import {Conf} from "../../core/config/Conf";
import OrderModal from "../users/orderModal"
import UserPayment from "./userPayment"

export default function UserDetails(props){

	const [data,setData]=useState(null);
	const [tab,setTab]=useState(0);
	const [transactions,setTransactions]=useState([])
	const [reservations,setReservations]=useState([])
	const confirmation = UseConfirmation();
	const [order,setOrder]=useState(null)
	const [payment,setPayment]=useState(null)
	useEffect(()=>{
		if(props.data){
			setData({...props.data, percentType:props.data?.card_cashback_id===-1?'personal':'standart'})
			getTransactions(props.data)
		}else{
			setData(null)
		}

	},[props.data])

	const getTransactions = ({id})=>{
		if(id){
			Api.Users.getTransactions({id:id})
				.then(response=>{
					 setTransactions(response.status? response.data:[])
				})
			Api.Users.getReservations({id:id})
				.then(response=>{
					 setReservations(response.status? response.data:[])
				})
		}

	}
	function avatarRender() {
		return (
			<>
				<div className="form-avatar" style={{backgroundImage:`url(${data?.picture? Conf.fileUri+data.picture:''})`,aspectRatio:1, width:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}> {data?.picture?"":i18n.t("სურათის გარეშე")}</div>
			</>

		);
	}

	if(order){
		 return <OrderModal details={order} setDetails={setOrder} />
	}

	if(payment){
		return  <UserPayment data={payment}  setSelected={setPayment}/>

	}

	return <>


		<Popup
		maxWidth={"1000px"}
		height={'800px'}
		showTitle={true}
		title={ data?.firstName+" "+data?.lastName }
		dragEnabled={true}
		closeOnOutsideClick={true}
		visible={data !==null}
		onHiding={e=>props.setSelected(null)}
		contentRender={()=>(
			<div style={{display:'flex',flexDirection:'column',height:'100%'}}>

				<div >
					<Tabs onChangeTab={e=>setTab(e)} config={[
						{key:0,title:i18n.t("პერსონალური ინფორმაცია")},
						{key:1,title:i18n.t("ტრანზაქციები")},
						{key:2,title:i18n.t("ჯავშნები")},
					]}/>
				</div>

				<div style={{marginTop:"20px",flex:1,height: 'calc(100% - 100px)'}}>
					{
						tab===0 && (
							<div style={{paddingRight:"10px"}}>
								<Form>
									<Row>
										<Col><h4 style={{fontWeight:'bold'}}>{i18n.t("დახარჯული თანხა")} {data?.paid_amount} {i18n.t("ლარი")}</h4></Col>
										<Col><h4  style={{fontWeight:'bold'}}>{i18n.t("დარიცხული ქეშბექი")} {data?.cashback} {i18n.t("ლარი")}</h4></Col>
										<Col><h4  style={{fontWeight:'bold'}}>{i18n.t("ქეშბექ სტატუსი")} {data?.cashback_percent}%</h4></Col>
									</Row>
									<hr/>
									<Row className="mb-3">
										<Col style={{maxWidth:"200px"}} >
											<Form.Group as={Col} controlId="formGridEmail">
												{avatarRender()}
											</Form.Group>

										</Col>
										<Col>
											<Row>
												<Form.Group as={Col} controlId="formGridEmail">
													<Form.Label>{i18n.t("სახელი")}</Form.Label>
													<Form.Control type="text" placeholder="" value={data?.firstName} onChange={e=>setData({...data,firstName:e.target.value})} />
												</Form.Group>

												<Form.Group as={Col} controlId="formGridPassword">
													<Form.Label>{i18n.t("გვარი")}</Form.Label>
													<Form.Control type="text" placeholder="" value={data?.lastName}   onChange={e=>setData({...data,lastName:e.target.value})}/>
												</Form.Group>
											</Row>

											<Row className="mb-3">
												<Form.Group as={Col} controlId="formGridEmail">
													<Form.Label>{i18n.t('პირადი ნომერი')}</Form.Label>
													<Form.Control type="text" placeholder="" value={data?.idNumber} onChange={e=>setData({...data,idNumber:e.target.value})} />
												</Form.Group>

												<Form.Group as={Col} controlId="formGridPassword">
													<Form.Label>{i18n.t("მობილური")}</Form.Label>
													<Form.Control type="text" placeholder="" value={data?.phone} onChange={e=>setData({...data,phone:e.target.value})} />
												</Form.Group>
											</Row>
										</Col>
									</Row>
									<Row>
										<Col style={{
											display:'flex',
											justifyContent:'flex-end'
										}}>
											<Button variant="secondary" onClick={()=>{
												Api.Users.setPersonalInfo({data,loader:(e)=>console.log(e)})
													.then(response=>{
														if(response.status){
															notify(response.data,response?.status?'success':'error')
														}
													})
											}}>
												შენახვა
											</Button>

										</Col>
									</Row>
									<hr/>
									<Row className="mb-3">
										<Form.Group as={Col} controlId="formGridState">
											<Form.Label>{i18n.t("სტატუსი")}</Form.Label>
											<Form.Select value={data?.status} onChange={e=>{

												Api.Users.setStatus({id:data?.id,status:e.target.value})
													.then(response=>{
														props.onSelectionChanged({data:props.data})
														notify(response.data,response.status?'success':'error')

													})
											}}>
												<option value={"active"}  style={{color:'green'}}>{i18n.t("აქტიური")}</option>
												<option value={"inactive"} style={{color:"red"}}>{i18n.t("არააქტიური")}</option>
												<option value={"blocked"} style={{color:"red"}}>{i18n.t("დაბლოკილი")}</option>
											</Form.Select>
										</Form.Group>
									</Row>


									<hr/>


									{
										props?.from !=="manager" && (
											<Row className="mb-3">

												<Form.Group as={Col} controlId="formGridState">
													<Form.Label>{i18n.t("პროცენტის ტიპი")}</Form.Label>
													<Form.Select value={data?.percentType}  onChange={e=>{
														setData({...data,percentType:e.target.value})
													}}>
														<option value={"standart"}  style={{color:'green'}}>{i18n.t("სტანდარტული")}</option>
														<option value={"personal"} style={{color:"red"}}>{i18n.t("პერსონალური")}</option>
													</Form.Select>
												</Form.Group>
												<Form.Group as={Col}  controlId="formGridPassword">
													<Form.Label>{i18n.t("პროცენტი")}</Form.Label>
													<Form.Control type="number" placeholder="" value={data?.cashback_percent} onChange={e=>{
														setData({...data,cashback_percent:e.target.value})
													}}/>
												</Form.Group>
												<Form.Group as={Col} style={{display:'flex',alignItems:'center',marginTop:20}}>
													<Button variant="secondary" onClick={()=>{
														Api.Users.setPersonalPercent(data)
															.then(response=>{
																if(response.status){
																	notify(response.data,response?.status?'success':'error')
																}
															})
													}}>
														შენახვა
													</Button>
												</Form.Group>
											</Row>
										)
									}


								</Form>
							</div>
						)
					}
					{
						tab ===1 && (
							<div style={{paddingLeft:"10px"}}>
								<DataGrid
									dataSource={transactions}
									keyExpr="id"
									showBorders={true}
									scrolling={{mode:"standard"}}
									style={{
										height: '620px',
									}}
								>
									<Column dataField="id" caption={"#"} width={"60"}/>
									<Column dataField="type" caption={i18n.t("ტიპი")} width={"280"} />
									<Column dataField="amount" caption={i18n.t("თანხა")}  width={"100"}/>
									<Column dataField="cashback" caption={i18n.t("ქეშბექი")} width={"100"} cellRender={e=>{
										return <span style={{color:`${e.data?.transaction_type===2? 'red':'green'}`}}>{e.data.cashback}</span>
									}} />
									<Column dataField="timestamp" caption={i18n.t("დრო")} />
									<Column dataField="cashback_percent" caption={i18n.t("ქეშბ პროც")} />

								</DataGrid>
							</div>
						)
					}
					{/*{
						tab ===1 && (
							<div style={{paddingLeft:"10px"}}>
								<DataGrid
									dataSource={data?.roles}
									keyExpr="id"
									showBorders={true}
								>
									<Column  dataField="title" caption={i18n.t("როლი")} cellRender={e=>{
										return i18n.t(e.value)
									}}/>
									<Column width={"100px"} alignment={"center"} dataField="checked" caption={i18n.t("უფლებები")} cellRender={e=>{
										return  <FontAwesomeIcon icon={e.data.checked===1?faCheck:faSquare} size={"xl"} style={{cursor:"pointer"}} onClick={()=>{
											confirmation.open({
												title:i18n.t(`როლის ${e.data.checked===1?'გაუქმება':'ჩართვა'}`),
												type:'danger',
												subtitle:i18n.t(`დარწმუნებული ხართ, რომ გსურთ როლის ${e.data.checked===1?'გაუქმება':'ჩართვა'}?`),
												notes:false,
												icon:"times-circle",
												//iconColor:'red',
												//subtitleColor:'red',
												onYes:(note)=>{
													Api.Staff.setRole({
														type:e.data.checked===1?'delete':'set',
														data:{
															"admin":data.id,
															"role":e.data.id
														},loader:(e)=>{
															console.log(e)
														}
													})
														.then((response)=>{

															if(response.status){
																props.onSelectionChanged({data:props.data})
																confirmation.close()
															}
														})

												},
												onNo:()=>{
													confirmation.close()
												}
											})

										}}/>
									}}/>

								</DataGrid>
							</div>
						)
					}*/}
					{
						tab ===2 && (
							<div style={{paddingLeft:"10px"}}>
								<DataGrid
									dataSource={reservations}
									keyExpr="id"
									showBorders={true}
									scrolling={{mode:"standard"}}
									style={{
										height: '620px'
									}}

								>
									<Column dataField="id" caption={"#"} width={"50"} alignment={"center"} />
									<Column dataField="place" caption={i18n.t("ადგილი")} alignment={"center"} />
									<Column dataField="guestQty" caption={i18n.t("სტ რაოდ")}  alignment={"center"}/>
									<Column dataField="timestamp" caption={i18n.t("დრო")}  alignment={"center"} cellRender={e=>{
										return <span>{e.data.order_date} {e.data.order_time}</span>
									}}/>
									<Column dataField="status" caption={i18n.t("სტატუსი")} cellRender={e=>{
										switch (e.data.status){
											case -1: return <span >{i18n.t("დასადასტურებელი")}</span>
											case 1: return <span style={{color:'green'}}>{i18n.t("დადასტურებული")}</span>
											case 2: return <span style={{color:'red'}}>{i18n.t("გაუქ კლიენტის მიერ")}</span>
											case 3: return <span style={{color:'red'}}>{i18n.t("გაუქ მენეჯერის მიერ")}</span>
											default: return <span >{i18n.t("არ არის განსაზღვრული")}</span>
										}
									}}/>

								</DataGrid>
							</div>
						)
					}



				</div>

				<Modal.Footer>
					<div style={{flex:1,flexDirection:'row'}}>
						<Button type="success" onClick={()=>confirmation.open({
							title:"ქეშბექის დარიცხვა",
							type:"success",
							notes:true,
							noteLabel:"თანხა",
							editorType:"dxTextBox",
							show:true,
							detailsTitle:"კომენტარი",
							detailsValue:"",
							details:true,
							onNo:()=>confirmation.close(),
							onYes:(amount,details)=>{


								if(parseFloat(amount)>0 ){

									Api.Transaction.cashback({data:{
											user_id:props.data.id,
											transaction_type:1,
											cashback:amount,
											comment:details?.detailsValue
										}}).then((response)=>{
										if(response.status){
											confirmation.close()
										}
										props.onSelectionChanged({data:props.data})
										notify(response.data,response.status?'success':'error')
									})
								}else{
									notify("თანხა არასწორია","error",2000)

								}

							}
						})}

						>
							ქეშბექის დარიცხვა
						</Button>
						&nbsp;&nbsp;
						<Button type="danger" onClick={()=>confirmation.open({
							title:"ქეშბექის ჩამოჭრა",
							type:"danger",
							notes:true,
							noteLabel:"თანხა",
							editorType:"dxTextBox",
							show:true,
							detailsTitle:"კომენტარი",
							detailsValue:"",
							details:true,
							onNo:()=>confirmation.close(),
							onYes:(amount,details)=>{

								if(parseFloat(amount)>0 &&  parseFloat(amount)<parseFloat(props?.data?.cashback || 0)){
									Api.Transaction.cashback({data:{
											user_id:props.data.id,
											transaction_type:2,
											cashback:amount,
											comment:details?.detailsValue
										}}).then((response)=>{
										if(response.status){
											confirmation.close()
										}
										props.onSelectionChanged({data:props.data})
										notify(response.data,response.status?'success':'error')
									})
								}else{
									notify("თანხა არასწორია","error",2000)

								}

							}
						})}

						>
							ქეშბექის ჩამოჭრა
						</Button>
						&nbsp;&nbsp;
						<Button type="normal"
							onClick={()=>{
								setOrder({
									"type": "edit",
									"details": [],
									"order": {
										"id": "",
										"comment": "",
										"order_date": "",
										"order_time": "",
										"guestQty": 1,
										"user_id": props.data.id,
										"space_id": "",
										"order_timestamp": new Date()
									}
								})
							}}
						>
							მაგიდის დაჯავშნა
						</Button>
						&nbsp;&nbsp;
						&nbsp;&nbsp;
						&nbsp;&nbsp;

						<Button type="normal"
								onClick={()=>{
									confirmation.open({
										type:"success",
										title:"ნოტიფიკაცია",
										subtitle:true,
										notes:true,
										noteTitle:true,
										noteTitleLabel:"სათაური",
										onYes:(note,details,title)=>{
											Api.Notification.sendUserPushNotification({title:title,msg:note,user_id:props.data.id}).then(response=>{
												notify(response.data,response.status?'success':'error')
												if(response.status){
													confirmation.close()
												}

											})
										},
										onNo:()=>confirmation.close()
									})
								}}
						>
							ნოტიფიკაციის გაგზავნა
						</Button>
						&nbsp;&nbsp;
						&nbsp;&nbsp;
						&nbsp;&nbsp;

						<Button type="normal"
								onClick={()=>{
									console.log("ჩეკის გატარება")
									console.log(data)
									setPayment(data)
								}}
						>
							ჩეკის გატარება
						</Button>
					</div>

					<Button variant="secondary" onClick={()=>props.setSelected(null)}>
						დახურვა
					</Button>
				</Modal.Footer>
			</div>
		)}
		showCloseButton={true}
	/>
		</>
}


