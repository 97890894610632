import React, {useState,Fragment} from "react";
import HtmlEditor, { Toolbar, Item } from 'devextreme-react/html-editor';
import {Box} from "devextreme-react";
import {Tabs} from "../../../components";
import {i18n} from "../../../core";

const headerValues = [false, 1, 2, 3, 4, 5];


export default function Agreement(){
	const [value,setValue]=useState();
	const toolbarButtonOptions = {
		text: 'Show markup',
		stylingMode: 'text',
		onClick: (e)=>console.log(e),
	};
	return (
		<React.Fragment>
			<div className={'content-block'}>

				<div className={'dx-card'} style={{height:'calc(100vh - 70px)',padding:"20px"}}>
					<h5 className={'content-block'}>{i18n.t("წესები და პირობები")}</h5>

					<Tabs config={[
							{key:0,title:"ქართული"},
							{key:1,title:"რუსული"},
							{key:2,title:"ინგლისური"},
						]} onChangeTab={e=>console.log(e)} />
						<hr/>
							<HtmlEditor
								style={{height:"calc(100% - 100px)"}}
								value={value}
								onValueChanged={e=>console.log(e)}
							>
								<Toolbar>
									<Item name="undo" />
									<Item name="redo" />
									<Item name="separator" />
									<Item
										name="header"
										acceptedValues={headerValues}
									/>
									<Item name="separator" />
									<Item name="bold" />
									<Item name="italic" />
									<Item name="strike" />
									<Item name="underline" />
									<Item name="separator" />
									<Item name="alignLeft" />
									<Item name="alignCenter" />
									<Item name="alignRight" />
									<Item name="alignJustify" />
									<Item name="separator" />
									<Item
										widget="dxButton"
										options={toolbarButtonOptions}
									/>
								</Toolbar>
							</HtmlEditor>


			</div>
			</div>
		</React.Fragment>
	)
}