import React, {useCallback, useEffect, useState} from 'react';
import 'devextreme/data/odata/store';
import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
} from 'devextreme-react/data-grid';
import {Api, i18n} from "../../../core";
import {useDispatch, useSelector} from "react-redux";
import {Box, Button} from "devextreme-react";
import {CLoseIcon, Edit, Loader, PlusIcon} from "../../../assets/icons/assets";
import {Item} from "devextreme-react/box";
import TranslationModal from "../../../components/handbook/translationModal/translationModal";
import {Translator} from "../../../components";

export default function Languages() {
    const dispatch = useDispatch();
  const [loader,setLoader]=useState(false)
  const [languages,setLanguages]=useState([])
  const [selectedLanguage,setSelectedLanguage]=useState({id:1})
  const [data,setData]=useState([])
  const [selected,setSelected]= useState(null)
  const [btnLoader,setBtnLoader]=useState(-1)


  useEffect(()=>{
    Api.HandBook.languages().then(response=>{
        setLanguages(response.status?response.data:[])
    })
  },[])


  const getDataByLang=(language)=>{
      setSelectedLanguage(language)
      console.log(language)
        Api.HandBook.getDataByLanguage({id:language?.id,loader:setLoader})
            .then(response=>{
                setData(response.status?response.data:[])
            })
  }

  return (
    <React.Fragment>
        <TranslationModal data={selected} onClose={()=>setSelected(null)} onSave={(e)=>{
            Api.HandBook.setLanguage({data:e,loader:setLoader})
                .then(response=>{
                    if(response?.status){

                        i18n.reloadResources(['ka','ru','en']).then(()=>{
                            setSelected(null);
                            i18n.emit("languageChanged")
                            getDataByLang(selectedLanguage)
                        })

                    }
                })
        }}  />
      <div style={{"padding":"20px 20px 20px 20px"}}>
            <Box
                direction="row"
                width="100%"
                height={"100%"}>
                <Item
                    ratio={0}
                    baseSize={"450px"}
                   >
                    <div className={'dx-card '}>
                    <div style={{"padding":"10px 10px",display:'flex',justifyContent:'space-between',alignItems:'center' }}>
                        <strong>{i18n.t("ენები")}</strong>
                       {/* <Button
                            icon={PlusIcon}
                            type="normal"
                            style={{margin: 0, padding: 0,width:"35px",height:"35px"}}
                            stylingMode="contained"
                            onClick={() => {

                            }}
                        />*/}
                    </div>
                    <DataGrid
                        height={`${window.innerHeight - 150}px`}
                        scrolling={{
                            mode:'standard'
                        }}
                        loadPanel={
                            {
                                enabled:true
                            }
                        }
                        selection={{
                            mode:'single'
                        }}
                        onSelectionChanged={({selectedRowsData})=>getDataByLang(selectedRowsData[0])}
                        className={'dx-card wide-card'}
                        hoverStateEnabled={true}
                        dataSource={languages}
                        showBorders={false}
                        focusedRowEnabled={true}
                        //defaultFocusedRowIndex={0}
                        columnAutoWidth={true}
                        columnHidingEnabled={true}
                        keyExpr="id"
                        allowColumnReordering={true}
                        allowColumnResizing={true}

                    >
                        <Paging defaultPageSize={30} />

                        <FilterRow visible={true} />
                        <Pager showPageSizeSelector={true} showInfo={true} />



                        <Column dataField={'id'} caption={"#"} alignment={"left"} width={90} hidingPriority={2} allowFiltering={false} />
                        <Column
                            dataField={'key'}
                            caption={i18n.t("კოდი")}
                            hidingPriority={6}
                        />
                        <Column
                            dataField={'title'}
                            caption={i18n.t("დასახელება")}
                            hidingPriority={6}
                        />

                        <Column
                            dataField={'status'}
                            caption={i18n.t("სტატუსი")}
                            hidingPriority={6}
                        />

                        <Column dataField=""
                                cssClass={"custom-button-icon"}
                                width={50}
                                allowSorting={false}
                                cellRender={(e)=>{
                                    return  <Button
                                        disabled={btnLoader === e.key}
                                        icon={btnLoader === e.key? Loader:Edit}
                                        type="normal"
                                        style={{margin: 0, padding: 0,width:"35px",height:"35px"}}
                                        stylingMode="contained"
                                       /* onClick={() => setSelectedSection(e)}*/
                                    />
                                } }
                        />
                    </DataGrid>
                    </div>
                </Item>
                <Item
                    ratio={1}
                    >
                    <div className={'dx-card '}>
                        <div style={{"padding":"10px 10px",display:'flex',justifyContent:'space-between',alignItems:'center' }}>
                            <strong>{i18n.t("თარგმანები")}</strong>
                            {selectedLanguage && <Button
                                icon={PlusIcon}
                                type="normal"
                                style={{margin: 0, padding: 0,width:"35px",height:"35px"}}
                                stylingMode="contained"
                                onClick={() => setSelected({
                                    id:-1,
                                    lang_id:selectedLanguage.id,
                                    code:selectedLanguage.key,
                                    key:"",
                                    value:""
                                })}
                            />}
                        </div>
                        <DataGrid
                            height={`${window.innerHeight - 150}px`}
                            scrolling={{
                                mode:'standard'
                            }}
                            loadPanel={
                                {
                                    enabled:true
                                }
                            }
                            filterPanel={true}

                            className={'dx-card wide-card'}
                            dataSource={data}
                            showBorders={false}
                            focusedRowEnabled={true}
                            //defaultFocusedRowIndex={0}
                            columnAutoWidth={true}
                            columnHidingEnabled={true}
                            keyExpr="key"
                            allowColumnReordering={true}
                            allowColumnResizing={true}

                        >
                            <Paging defaultPageSize={30} />

                            <FilterRow visible={true} />
                            <Pager showPageSizeSelector={true} showInfo={true} />

                         {/*   <Column
                                dataField={'title'}
                                caption={i18n.t("სათარგმნი სიტყვა")}
                                hidingPriority={6}
                                cellRender={(e)=> {
                                    return <Translator
                                        id={e.rowIndex}
                                        originalName={e.data.key}
                                        translated={e.data.translated}
                                        translatedName={e.data.translated_name}
                                        onSuccess={()=>dispatch(Api.HandBook.websiteMenuList())} />
                                }} />}
                            />*/}

                            <Column
                                dataField={'key'}
                                caption={i18n.t("სათარგმნი სიტყვა")}
                                hidingPriority={6}
                                cellRender={(e)=> {
                                    return <Translator
                                        id={e.rowIndex}
                                        originalName={e.data.key}
                                        translated={e.data.key}
                                        translatedName={e.data.key}
                                        onSuccess={()=> getDataByLang(selectedLanguage)} />
                                }} />}
                            />
                            <Column
                                dataField={'value'}
                                caption={i18n.t("თარგმანი")}
                                hidingPriority={6}
                            />


                           {/* <Column dataField=""
                                    cssClass={"custom-button-icon"}
                                    width={50}
                                    allowSorting={false}
                                    cellRender={(e)=>{
                                        console.log(e)
                                        return  <Button
                                            disabled={btnLoader === e.key}
                                            icon={btnLoader === e.key? Loader:Edit}
                                            type="normal"
                                            style={{margin: 0, padding: 0,width:"35px",height:"35px"}}
                                            stylingMode="contained"
                                            onClick={() => setSelected({
                                                code:e.data.key,
                                                key:e.data.key,
                                                type:e.data.type
                                            })}
                                        />
                                    } }
                            />*/}

                            <Column dataField=""
                                    cssClass={"custom-button-icon"}
                                    width={50}
                                    allowSorting={false}
                                    cellRender={(e)=>{
                                        return  <Button
                                            disabled={btnLoader === e.key}
                                            icon={btnLoader === e.key? Loader:CLoseIcon}
                                            type="normal"
                                            style={{margin: 0, padding: 0,width:"35px",height:"35px"}}
                                            stylingMode="contained"
                                            onClick={() => {

                                               if(window.confirm("დარწმუნებული, ხართ რომ გსურთ წაშლა?")){
                                                    Api.HandBook.deleteTranslation({data:e.data,loader:setLoader})
                                                        .then(response=>{
                                                            if(response.status){
                                                                getDataByLang(selectedLanguage)
                                                            }
                                                        })

                                               }
                                            }}
                                        />
                                    } }
                            />
                        </DataGrid>
                    </div>
                </Item>


            </Box>
      </div>
    </React.Fragment>
)}
