import Config from "../config/config.json";
import http from "../http/http";

const GetSpaces  = ({loader}) =>async (dispatch)=>{
	http.get({url: Config.ORDER.SPACES,loader:loader})
		 .then(response=>{

 		 })
}
const GetOrders  = ({loader}) =>async (dispatch)=>{
	http.get({url: Config.USER.ORDERS,loader:loader})
		.then(response=>{

		})
}
const CreateOrder = (data,loader) =>{
	return  http.post({url: Config.USER.CREATE_ORDER,loader:loader,data:data})
}
const DeleteOrder = (id)=>{
	return http.get({url:Config.USER.DELETE_ORDER.replace("{id}",id)})
}

const getPercent = (loader) =>{
	return http.get({url:Config.ORDER.CHECK_PERCENT,loader:loader})
}
const getOrderDetails = ({loader,orderNumber,mode}) =>{
	return http.get({url:Config.ORDER.CHECK_ORDER.replace("{orderNumber}",orderNumber).replace("{mode}",mode),loader:loader})
}

export  {
	getPercent,
	getOrderDetails,
	GetSpaces,
	CreateOrder,
	GetOrders,
	DeleteOrder
}
