import React, {useEffect, useState} from 'react';
import 'devextreme/data/odata/store';
import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  Lookup, Editing
} from 'devextreme-react/data-grid';
import {Api, i18n} from "../../core";
import {useDispatch, useSelector} from "react-redux";
import {Button} from "devextreme-react";
import {Edit, Loader, PlusIcon} from "../../assets/icons/assets";
import {UserDetails} from "../../components";
import NewUserModal from "../../components/users/newUserModal";
import _ from "lodash";
import notify from "devextreme/ui/notify";

export default function UsersWithoutPercent() {
  const dispatch = useDispatch();
  const users = useSelector(state=>state.Users)
  const [btnLoader,setBtnLoader]=useState(-1)
  const [selected,setSelected]=useState(null)
  const [newUser, setNewUser]=useState(null)
  useEffect(()=>{
    dispatch(Api.Users.list())
      i18n.on("languageChanged",e=> {
          Api.Settings.ChangeLanguage(e).then(e=> {
              dispatch(Api.Users.list())
          })
      })
      return () =>{
          i18n.off("languageChanged",e=>{

          })
      }
  },[])

  const onSelectionChanged=(e)=>{

    let data =e.data;
    Api.Users.getDetails({id:data.id,loader:(e)=>{
        setBtnLoader(e?e.key:null)
    }}).then(response=>{
        setSelected(response.status?response.data:null)
    })

  }

  return (
    <React.Fragment>
      <NewUserModal data={newUser} from={"manager"} onClose={()=>setNewUser(null)} onSave={(user)=>{
          let error = [];
          ["firstName",'lastName','idNumber','phone'].forEach(v=>{
              if(!user[v] || user[v].length<2){
                error.push(v)
              }
          })
          if(_.size(error)>0){

              notify("შეამოწმეთ ყველა ველი","error",2000)
          }else{
              Api.Users.addUser({data:user})
                  .then(response=>{
                      notify(response.data,response.status?'success':'error',2000)
                      if(response.status) setNewUser(null)
                      dispatch(Api.Users.list())

                  })
          }

      }}/>
      <UserDetails data={selected} from={"manager"} onSelectionChanged={onSelectionChanged} setSelected={setSelected}/>
      <div style={{"padding":"20px"}}>
         <strong>{i18n.t("მომხმარებლები")} ({_.size(users)}) </strong>
      </div>
      <div style={{"padding":"0 20px 20px 20px"}}>
        <div className={'dx-card '}>
          <DataGrid
              height={`${window.innerHeight - 150}px`}
              scrolling={{
                mode:'standard'
              }}
              className={'dx-card wide-card'}
              dataSource={users}
              showBorders={false}
              focusedRowEnabled={true}
              defaultFocusedRowIndex={0}
              columnAutoWidth={true}
              columnHidingEnabled={true}
              keyExpr="id"
              allowColumnReordering={true}
              allowColumnResizing={true}

          >
            <Paging defaultPageSize={20} />
            <Pager showPageSizeSelector={true} showInfo={true} />
            <FilterRow visible={true} />

            <Column dataField={'id'}  alignment={"center"} width={90} hidingPriority={2} allowFiltering={false} />
            <Column
                alignment={"center"}
                dataField={'firstName'}
                caption={i18n.t("სახელი")}
                hidingPriority={6}
            />
            <Column
                alignment={"center"}
                dataField={'lastName'}
                caption={i18n.t("გვარი")}
                hidingPriority={5}
            />
            <Column
                alignment={"center"}
                dataField={'username'}
                caption={i18n.t("მომხმარებელი")}
                allowSorting={false}
                hidingPriority={7}
            />
              <Column
                  alignment={"center"}
                  dataField={'idNumber'}
                  caption={i18n.t("პირადი ნომერი")}
                  hidingPriority={3}
              />
              <Column
                  alignment={"center"}
                  dataField={'phone'}
                  caption={i18n.t("ტელეფონი")}
                  hidingPriority={3}
              />
            <Column
                alignment={"center"}
                dataField={'verified'}
                caption={i18n.t("ვერიფიკაცია")}
                hidingPriority={3}
            />
            <Column
                alignment={"center"}
                dataField={'status'}
                caption={i18n.t("სტატუსი")}
                hidingPriority={3}
            />
            <Column
                alignment={"center"}
                dataField={'registration_date'}
                caption={i18n.t("რეგისტრაციის თარითი")}
                dataType={'date'}
                hidingPriority={3}
            />

            <Column dataField=""
                    headerCellRender={()=>{


                        return (
                            <Button
                                icon={PlusIcon}
                                type="normal"
                                style={{margin: 0, padding: 0,width:"35px",height:"35px"}}
                                stylingMode="contained"
                                onClick={() => {
                                        setNewUser({
                                            firstName:'',
                                            lastName:'',
                                            idNumber:'',
                                            phone:"",
                                            icon:"",
                                            image:"",
                                            sendPassword:true
                                        })
                                }}
                            />

                        )

                    }}
                    cssClass={"custom-button-icon"}
                    width={50}
                    allowSorting={false}
                    cellRender={(e)=>{
                      return  <Button
                                disabled={btnLoader === e.key}
                                icon={btnLoader === e.key? Loader:Edit}
                                type="normal"
                                style={{margin: 0, padding: 0,width:"35px",height:"35px"}}
                                stylingMode="contained"
                                onClick={() => onSelectionChanged(e)}
                            />
                    } }
            />
          </DataGrid>
        </div>
      </div>
    </React.Fragment>
)}
