import defaultUser from '../utils/default-user';
import Http from "../core/http/http";
import Config from "../core/config/config.json"
import {query_string} from "../core/helpers/query_params";
import Token from "../core/models/Access";
export async function signIn(email, password) {
  try {
    // Send request
    console.log(email, password);
    const response = await Http.post({
      url: Config.User.SIGN_IN, data: query_string({
        "username": email,
        "password": password
      })
    })
    if(response.status){
      Token.setData(response.data)
      return {
        isOk: response.status,
        data: defaultUser
      };
    }
    return {
      isOk: false,
      message: "Authentication failed"
    };
  }
  catch {
    return {
      isOk: false,
      message: "Authentication failed"
    };
  }
}

export async function getUser() {
  try {
    // Send request

    return {
      isOk: true,
      data: defaultUser
    };
  }
  catch {
    return {
      isOk: false
    };
  }
}

export async function createAccount(email, password) {
  try {
    // Send request
    console.log(email, password);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to create account"
    };
  }
}

export async function changePassword(email, recoveryCode) {
  try {
    // Send request
    console.log(email, recoveryCode);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to change password"
    }
  }
}

export async function resetPassword(email) {
  try {
    // Send request
    console.log(email);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to reset password"
    };
  }
}
