import { createSlice} from '@reduxjs/toolkit'
const initialState = {
    isLogged:false,
    data:null
}

const userSlice = createSlice({
    name: 'User',
    initialState,
    reducers: {
        Ping:(state,action)=>{
            state.isLogged=action.payload.isLogged;
           // state.isLogged=action;
            state.data=action.payload.data;
        }
    }

})
export const { Ping } = userSlice.actions

export default userSlice.reducer
