import {UseEvent} from "./useEvent";
import {i18n} from "../i18n/i18n";

export const UseConfirmation = () => {
    const ev = UseEvent()
    {/*==="accept"?'check-circle':'times-circle'*/}
    const open=({title,type="danger",subtitle,onYes,onNo,notes=false,note="",show=true,subtitleColor="black",icon="",iconColor="black",height=null,checkbox=null,checkboxText=null,checkboxValue=false,noteLabel="note",editorType="dxTextArea",noteTitle=null,noteTitleLabel="",details=false,detailsTitle="",detailsValue="",detailsEditorType="dxTextArea"})=>{
        ev.emit("confirmation",{title,type,subtitle,onYes,onNo,notes,note,show,subtitleColor,icon,iconColor,height,noText:i18n.t("არა"),yesText:i18n.t("დიახ"),checkbox:checkbox,checkboxText:checkboxText,checkboxValue:checkboxValue,noteLabel,editorType,noteTitle,noteTitleLabel,details:details,detailsTitle:detailsTitle,detailsValue:detailsValue,detailsEditorType:detailsEditorType})
    }
    const close=()=>{
        ev.emit("confirmation",{show:false})
    }

    return {open,close}
}
