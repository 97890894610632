import http from "../http/http";
import Config from "../config/config.json"
import {getGroups} from "../store/slices/menuSlice";



const list = ()=>async (dispatch)=>{
    http.get({
        url: Config.MENU.GROUPS
    }).then(response=>{
        dispatch(getGroups(response.status?response.data:[]));
    })
}
const productsByGroup = ({group_id})=>{
    return http.get({
        url: Config.MENU.PRODUCTS_BY_GROUP.replace("{group_id}",group_id)
    })
}
const getAllProducts = ()=>{
    return http.get({
        url: Config.MENU.GET_ALL_PRODUCTS
    })
}

export  {
    list,
    productsByGroup,
    getAllProducts
}
