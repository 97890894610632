import { createSlice} from '@reduxjs/toolkit'
const initialState = []

const roleSlice = createSlice({
    name: 'Roles',
    initialState,
    reducers: {
        getRoles:(state,action)=>{
           return action.payload;
        }
    }

})
export const { getRoles } = roleSlice.actions

export default roleSlice.reducer
