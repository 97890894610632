class Token {
    access=null;
    refresh=null;
    constructor() {

        this.access=localStorage.getItem("AGRD_token")||null
        this.refresh=localStorage.getItem("RGRD_token")||null
    }
    setData(data){
        const {access_token,refresh_token} = data;
       localStorage.setItem("AGRD_token",access_token);
       localStorage.setItem("RGRD_token",refresh_token);
    }
    clear(){
        localStorage.removeItem("AGRD_token");
        localStorage.removeItem("RGRD_token");
    }

}
export default Token;
