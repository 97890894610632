import {Button, Popup, SelectBox, TextBox} from "devextreme-react";
import {useEffect, useState} from "react";
import Form, {GroupItem, Item, SimpleItem} from 'devextreme-react/form';
import {i18n} from "../../../core";
import {ToolbarItem} from "devextreme-react/popup";
import dxNumberBox from "devextreme/ui/number_box";


export default function CashbackModal(props){
	const [data,setData]=useState(null)
	useEffect(()=>{
		if(props.data){
			setData({...props.data})
		}

	},[props.data])


	return <Popup
		maxWidth={"800px"}
		showTitle={true}
		title={data?.title || data?.name}
		dragEnabled={true}
		closeOnOutsideClick={true}
		visible={props.data !==null}
		onHiding={e=>props.setSelected(null)}
		contentRender={()=>(
			<div>
				<Form formData={data}>
						<GroupItem >
							<SimpleItem dataField="title" label={{text:i18n.t("დასახელება")}} />
							<SimpleItem dataField="min" editorType={dxNumberBox} label={{text:i18n.t("მინ.თანხა")}} />
							<SimpleItem dataField="max" editorType={dxNumberBox} label={{text:i18n.t("მაქს.თანხა")}} />
							<SimpleItem dataField="percent" editorType={dxNumberBox} label={{text:i18n.t("პროცენტი")}} />
						</GroupItem>

				</Form>
			</div>

		)}
		showCloseButton={true}
	>

		<ToolbarItem
			widget="dxButton"
			toolbar="bottom"
			location="after"
			options={{
				text: i18n.t("დახურვა"),
				onClick: ()=>props.setSelected(null),
			}}
		/>
		<ToolbarItem
			widget="dxButton"
			toolbar="bottom"
			location="after"
			component={ ()=><Button
				width={120}
				text={i18n.t("შენახვა")}
				type="success"
				onClick={_=>props.onSave(data)}
			/>}

		/>
	</Popup>


}


