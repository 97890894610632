import React from 'react';
import './home.scss';
import {i18n} from "../../core"
import {useUser} from "../../core/hooks/useUser";

const Home = ()=> {
  const {User} = useUser()
  return (
    <React.Fragment>

      <h2 className={'content-block'}>{i18n.t("მთავარი")}</h2>

      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>


          <p>{i18n.t("მოგესალმებით")} {User?.data.firstName},</p>
          <p>{i18n.t("სამართავი პანელიდან თქვენ შეგიძლიათ მართოთ ყველაფერი")}</p>
          <p>{i18n.t("პანელიდან შესაძლებელია")}:</p>

          <ul>
            <li>{i18n.t("შეცვალოთ კერძის დასახელება")}</li>
            <li>{i18n.t("შეცვალოთ კერძის ფასი")}</li>
            <li>{i18n.t("შეზღუდოთ საიტზე ჩვენება")}</li>
            <li>{i18n.t("დაამატოთ თარგმანები")}</li>
            <li>{i18n.t("დაადასტუროთ ან გააუქმოთ ჯავშანი")}</li>
            <li>{i18n.t("გააუქმოთ გატარება/ტრანზაქცია")}</li>
            <li>{i18n.t("ჩართოთ ან გამორთოთ ფუნქციონალი როლების მიხედვით")}</li>
            <li>{i18n.t("ნახოთ უამრავი რეპორტი")}</li>
            <li>{i18n.t("და კიდევ უამრავი ფუნქციონალი")} </li>
            <li>P.S {i18n.t("მეტის ჩამოწერა დამეზარა")}</li>
            <li>...</li>
          </ul>

        </div>
      </div>
    </React.Fragment>
)}

export default Home;