import {Button, FileUploader, Popup, SelectBox, TextBox} from "devextreme-react";
import React, {useEffect, useRef, useState} from "react";
import Form, {GroupItem, Item, SimpleItem} from 'devextreme-react/form';
import {i18n} from "../../../core";
import {ToolbarItem} from "devextreme-react/popup";
import dxNumberBox from "devextreme/ui/number_box";
import "./handbookmodal.scss"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {Conf} from "../../../core/config/Conf";

export default function HandbookModal(props){
	const [data,setData]=useState(null)
	const fileUploaderRef = useRef();
	useEffect(()=>{
		if(props.data){
			setData({...props.data,image:Conf.fileUri+props.data?.image})
		}

	},[props.data])


	const onHandleImage=(e)=> {

		if(e && e.length>0){
			const reader = new FileReader();
			reader.onload = function(){
				setData({...data,image:reader.result,fileChange:true});
			};
			reader.readAsDataURL(e[0]);
		}

	}

	return <Popup
		maxWidth={"800px"}
		maxHeight={"600px"}
		showTitle={true}
		title={data?.title || data?.name}
		dragEnabled={true}
		closeOnOutsideClick={true}
		visible={props.data !==null}
		onHiding={e=>props.setSelected(null)}
		contentRender={()=>(
			<div className={"handbookmodal"}>

				<Form formData={data}>
						<GroupItem >
							<SimpleItem dataField="title" label={{text:i18n.t("დასახელება")}} />

							<SimpleItem dataField="sort" editorType={dxNumberBox} label={{text:i18n.t("რიგითობა")}} />

						</GroupItem>

					<GroupItem>

						<SelectBox label={i18n.t("სტატუსი")} dataSource={[{id:1,value:i18n.t("აქტიური")},{id:-1,value:i18n.t("პასიური")}]}
								   defaultValue={data.status}
								   displayExpr='value'
								   showClearButton={false}
								   labelMode={'static'}
								   labelLocation={"top"}
								   valueExpr='id'
								   onSelectionChanged={e=>setData({...data,status:e.selectedItem.id})}
						/>
					</GroupItem>

					{
						props?.form ==="product" && <GroupItem>
							{
								data.image && <div style={{position:'relative',width:"150px"}}>
									<div style={{
										position:"absolute",
										right:"10px",
										top:"10px",
										cursor:'pointer'
									}}
										 onClick={()=>{
											 fileUploaderRef.current.instance.reset()

											 setData({...data,image:"",fileChange:true});
										 }}
									>
										<FontAwesomeIcon icon={faTimes} color={"red"} size={"2x"} />
									</div>
									<img src={data.image|| ''} width={"150px"} height={"auto"}  alt={"image"}/>
								</div>
							}
							<div className="fileuploader-container">
								<FileUploader  ref={fileUploaderRef} defaultValue={""} selectButtonText="Select photo" labelText=""  accept="image/*"  onValueChange={e=>onHandleImage(e)} uploadMode="useForm" />
							</div>
						</GroupItem>
					}


				</Form>
			</div>

		)}
		showCloseButton={true}
	>

		<ToolbarItem
			widget="dxButton"
			toolbar="bottom"
			location="after"
			options={{
				text: i18n.t("დახურვა"),
				onClick: ()=>props.setSelected(null),
			}}
		/>
		<ToolbarItem
			widget="dxButton"
			toolbar="bottom"
			location="after"
			component={ ()=><Button
				width={120}
				text={i18n.t("შენახვა")}
				type="success"
				onClick={_=>{
					props.onSave(data)
				}}
			/>}

		/>
	</Popup>


}


