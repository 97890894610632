import http from "../http/http";
import Config from "../config/config.json"

const Count=()=> {
    return http.get({
        url: Config.RESERVATION.COUNT
    })
}


const list = ()=>{
    return http.get({
        url: Config.RESERVATION.LIST
    })
}
const Details = (id)=>{
    return http.get({
        url: Config.RESERVATION.DETAILS.replace("{id}",id)
    })
}
const changeStatus=({data,loader})=>{
    return http.post({
        url:Config.RESERVATION.CHANGE_STATUS,
        data:data,
        loader:loader
    })
}

const updateReservation=({data,loader})=>{
    return http.post({
        url:Config.RESERVATION.UPDATE_RESERVATION,
        data:data,
        loader:loader
    })

}

export  {
    list,
    changeStatus,
    Count,
    Details,
    updateReservation
}
