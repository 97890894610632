import { createSlice} from '@reduxjs/toolkit'
const initialState = []

const sectionsSlice = createSlice({
    name: 'Sections',
    initialState,
    reducers: {
        getSections:(state,action)=>{
           return action.payload;
        }
    }

})
export const { getSections } = sectionsSlice.actions

export default sectionsSlice.reducer
