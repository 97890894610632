import {Button, FileUploader, Popup, SelectBox, TextBox} from "devextreme-react";
import React, {useEffect, useRef, useState} from "react";

import {Modal,Form} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {Conf} from "../../../core/config/Conf";
import _ from "lodash";

export default function HandbookWebsiteMenuModalV1(props){
	const [data,setData]=useState(null)
	const fileUploaderRef = useRef();
	useEffect(()=>{

		if(props.data) {
			setData({...props.data, icon: props?.data?.icon})
		}else{
			setData(null)
		}
	},[props.data])

	useEffect(()=>{
		console.log(props?.errors)
	},[props.errors])

	const onHandleImage=(e)=> {

		if(e && e.length>0){
			const reader = new FileReader();
			reader.onload = function(){
				setData({...data,icon:reader.result,fileChange:true});
			};
			reader.readAsDataURL(e[0]);
		}

	}

	return  data !==null && <Modal show={true} onHide={()=>props.onClose()}>
		<Modal.Header closeButton>
			<Modal.Title>მენიუს დამატება</Modal.Title>
		</Modal.Header>
		<Modal.Body>
			<Form>
				{
					_.map(data?.details,(v,k)=>{
						return (
							<Form.Group className="mb-3" controlId="form1" key={k}>
								<Form.Label>დასახელება {v.lang_key}</Form.Label>
								<Form.Control isInvalid={props?.errors?.key==="details"} value={v?.title} type="text" placeholder="დასახელება"  onChange={e=>{
									let details = data.details;
									details[k]["title"]=e.target.value;
									setData({...data,details:[...details]})
								}}/>
								<Form.Control.Feedback type="invalid">
								</Form.Control.Feedback>
							</Form.Group>
						)
					})
				}

				<Form.Group className="mb-3">
					<Form.Label htmlFor="disabledSelect">ტიპი</Form.Label>
					<Form.Select value={data.type} id="disabledSelect" onChange={e=>setData({...data,type:e.target.value})}>
						<option value="">აირჩიეთ ტიპი</option>
						<option value="1">ტიპი 1</option>
						<option value="2">ტიპი 2</option>
						<option value="3">ტიპი 3</option>
						<option value="4">ტიპი 4</option>
					</Form.Select>
				</Form.Group>
				<Form.Group className="mb-3" controlId="form2">
					<Form.Label>რიგითობა</Form.Label>
					<Form.Control type="number" placeholder="რიგითობა" value={data.sort} onChange={e=>setData({...data,sort:e.target.value})} />
				</Form.Group>
				<Form.Group className="mb-3">
					<Form.Label htmlFor="disabledSelect">სტატუსი</Form.Label>
					<Form.Select id="disabledSelect" value={data.status} onChange={e=>setData({...data,status:e.target.value})}>
						<option value="">აირჩიეთ სტატუსი</option>
						<option value="1">აქტიური</option>
						<option value="-1">პასიური</option>
					</Form.Select>
				</Form.Group>
				<Form.Group controlId="formFile" className="mb-3">
					<Form.Label>აირჩიეთ ფოტო</Form.Label>
					<Form.Control type="file" ref={fileUploaderRef} onChange={e=>onHandleImage(e.target.files)}/>
				</Form.Group>
				<Form.Group controlId="formFile" className="mb-3">
					{
						data.icon !==null && data.icon !=="" && data.icon  !==undefined  && <div style={{position:'relative',width:"150px"}}>
							<div style={{
								position:"absolute",
								right:"10px",
								top:"10px",
								cursor:'pointer'
							}}
								 onClick={()=>{
									 fileUploaderRef.current.value=""
									 setData({...data,icon:""});
								 }}
							>
								<FontAwesomeIcon icon={faTimes} color={"red"} size={"2x"} />
							</div>
							<img src={data.icon && data?.fileChange ?  data.icon:Conf.fileUri+data.icon} width={"150px"} height={"auto"}  alt={"image"}/>
						</div>
					}
				</Form.Group>
			</Form>
		</Modal.Body>
		<Modal.Footer>
			<Button variant="secondary" onClick={()=>props.onClose()}>
				დახურვა
			</Button>
			<Button variant="primary" onClick={()=>props.onSave(data)}>
				შენახვა
			</Button>
		</Modal.Footer>
	</Modal>


}


