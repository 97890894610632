import React, {useEffect, useState} from "react";
import {Box, Button, FileUploader,  Popup} from "devextreme-react";
import {GroupItem, SimpleItem} from "devextreme-react/form";
import {Api, i18n} from "../../core";
import {Tabs} from "../tabs/tabs";
import DataGrid, {Column} from "devextreme-react/data-grid";

import {UseConfirmation} from "../../core/hooks/useConfirmation";
import {Col, Form, Modal, Row} from "react-bootstrap";
import notify from "devextreme/ui/notify";
import {Conf} from "../../core/config/Conf";

export default function TransactionDetails(props){

	console.log(props)

	return <Popup
		maxWidth={"1000px"}
		height={'800px'}
		showTitle={true}
		title={ "დეტალები" }
		dragEnabled={true}
		closeOnOutsideClick={true}
		visible={props.transactions !==null}
		onHiding={_=>props.close(null)}
		contentRender={()=>(
			<div style={{display:'flex',flexDirection:'column',height:'100%'}}>


				<Modal.Body>
					<DataGrid
						dataSource={props.transactions}
						keyExpr="id"
						showBorders={true}
						scrolling={{mode:"standard"}}
						style={{
							height: '620px',
						}}
					>
						<Column dataField="id" caption={"#"} width={"60"}/>
						<Column dataField="type" caption={i18n.t("ტიპი")} width={"280"} />
						<Column dataField="amount" caption={i18n.t("თანხა")}  width={"100"}/>
						<Column dataField="cashback" caption={i18n.t("ქეშბექი")} width={"100"} cellRender={e=>{
							return <span style={{color:`${e.data?.transaction_type===2? 'red':'green'}`}}>{e.data.cashback}</span>
						}} />
						<Column dataField="timestamp" caption={i18n.t("დრო")} />
						<Column dataField="cashback_percent" caption={i18n.t("ქეშბ პროც")} />

					</DataGrid>

				</Modal.Body>

				<Modal.Footer>
					<Button variant="secondary" onClick={()=>props.close(null)}>
						დახურვა
					</Button>
				</Modal.Footer>
			</div>
		)}
		showCloseButton={true}
	>



	</Popup>
}


