import http from "../http/http";
import Config from "../config/config.json"

import {getCardCashbackList, getSectionsList, getWebsiteMenuList} from "../store/slices/handBookSlice";

const Update= (setLoader)=> {
	return http.get({
        url:Config.HANDBOOK.UPDATE ,
        loader:setLoader
    })
}

const LastUpdate = ()=>{
    return http.get({url:Config.HANDBOOK.LAST_UPDATE})
}

export function getLocaleDataByKey({key}) {
   return  http.get({
        url: Config.LOCALIZATION.GET_DATA_BY_KEY.replace("{key}",key)
    })
}

const list = ()=>async (dispatch)=>{
    http.get({
        url: Config.HANDBOOK.SECTION_LIST
    }).then(response=>{
        dispatch(getSectionsList(response.status?response.data:[]));
    })
}
const websiteMenuList = ()=>async (dispatch)=>{
    http.get({
        url: Config.HANDBOOK.WEBSITE_MENU_LIST
    }).then(response=>{
        dispatch(getWebsiteMenuList(response.status?response.data:[]));
    })
}
const cardCashbackList = ()=>async (dispatch)=>{
   return http.get({
        url: Config.HANDBOOK.CARD_CASHBACK_LIST
    }).then(response=>{
       dispatch(getCardCashbackList(response.status?response.data:[]));
   })
}
const tablesListById = ({section_id})=>{
    return http.get({
        url: Config.HANDBOOK.TABLES_BY_SECTION.replace("{section_id}",section_id)
    })
}
const websiteMenuProducts = ({menu_id})=>{
    return http.get({
        url: Config.HANDBOOK.WEBSITE_MENU_PRODUCTS.replace("{menu_id}",menu_id)
    })
}
const updateMenuGroup = (data) =>{
    return http.post({
        url:Config.HANDBOOK.UPDATE_MENU_GROUP,
        data:data
    })
}
const updateMenu = (data) =>{

    return http.post({
        url:Config.HANDBOOK.UPDATE_MENU,
        data:data
    })
}
const updateWebsiteMenu = (data) =>{

    return http.post({
        url:Config.HANDBOOK.UPDATE_WEBSITE_MENU,
        data:data
    })
}
const updateWebsiteProduct = (data) =>{

    return http.post({
        url:Config.HANDBOOK.UPDATE_WEBSITE_PRODUCT,
        data:data
    })
}
const updateSection = (data) =>{

    return http.post({
        url:Config.HANDBOOK.UPDATE_SECTION,
        data:data
    })
}
const updateTable = (data) =>{

    return http.post({
        url:Config.HANDBOOK.UPDATE_TABLE,
        data:data
    })
}
const updateCashbackStatus = (data) =>{

    return http.post({
        url:Config.HANDBOOK.UPDATE_CASHBACK,
        data:data
    })
}
const deleteWebsiteMenu = (id) =>{
    return http.get({
        url:Config.HANDBOOK.DELETE_WEBSITE_MENU.replace("{id}",id)
    })
}
const deleteWebsiteMenuProduct = (id) =>{
    return http.get({
        url:Config.HANDBOOK.DELETE_WEBSITE_MENU_PRODUCT.replace("{id}",id)
    })
}
//languages
const languages = ()=>{
    return http.get({
        url:Config.LOCALIZATION.LANGUAGES
    })
}
const getDataByLanguage = ({id,loader})=>{
    return http.get({
        url:Config.LOCALIZATION.DATA_BY_LANG.replace("{lang_id}",id),
        loader:loader
    })
}
const setLanguage=({data,loader})=>{

    return http.post({
        url:Config.LOCALIZATION.SET_LANGUAGE,
        loader:loader,
        data:data
    })
}
const deleteTranslation=({data,loader})=>{

    return http.get({
        url:Config.LOCALIZATION.DELETE_DATA.replace("{id}",data.id).replace("{code}",data.code).replace("{key}",data.key),
        loader:loader,
        data:data
    })
}
const addWebsiteProduct = ({data,loader}) =>{
    return http.post({
        url:Config.HANDBOOK.ADD_WEBSITE_PRODUCT,
        loader:loader,
        data:data
    })
}
const updateProductStatus = (id,status)=>{
    console.log(id,status)
    return http.get({
        url:Config.HANDBOOK.UPDATE_WEBSITE_PRODUCT_STATUS.replace("{id}",id).replace("{status}",status)
    })
}

const getSpaces = ()=>{
    return http.get({url:Config.WEBSITE.GET_SPACES.replace("{lang}",)})

}
const getSlides = ()=>{
    return http.get({url:Config.WEBSITE.GET_SLIDES.replace("{lang}")})

}
const getAppSlides = ()=>{
    return http.get({url:Config.APP.GET_SLIDES})
}
const getOrderNotificationNumbers = ()=>{
    return http.get({url:Config.WEBSITE.ORDER_NOTIFICATION_NUMBERS})
}
const addOrderNotificationNumber = (number)=>{
    return http.get({
        url:Config.WEBSITE.ADD_ORDER_NOTIFICATION_NUMBER.replace("{number}",number)
    })
}
const deleteOrderNotificationNumber = (id) =>{
    return http.get({
        url:Config.WEBSITE.DELETE_ORDER_NOTIFICATION_NUMBERS.replace("{id}",id)
    })
}
const updateSpacesStatus = (id,status)=>{
    return http.get({
        url:Config.WEBSITE.UPDATE_SPACE_STATUS.replace("{id}",id).replace("{status}",status)
    })
}
const updateSlideStatus = (id,status)=>{
    return http.get({
        url:Config.WEBSITE.UPDATE_SLIDE_STATUS.replace("{id}",id).replace("{status}",status)
    })
}
const saveOrUpdateSpace=(space)=>{
    return http.post({
        url:Config.WEBSITE.SAVE_OR_UPDATE_SPACE,
        data:space
    })
}
const saveOrUpdateSlide=(slide)=>{
    return http.post({
        url:Config.WEBSITE.SAVE_OR_UPDATE_SLIDE,
        data:slide
    })
}

const saveOrUpdateAppSlide=(slide)=>{
    return http.post({
        url:Config.APP.SAVE_OR_UPDATE_SLIDE,
        data:slide
    })
}
const deleteSpace = (id) =>{
    return http.get({
        url:Config.WEBSITE.DELETE_SPACE.replace("{id}",id)
    })
}
const deleteSlide = (id) =>{
    return http.get({
        url:Config.WEBSITE.DELETE_SLIDE.replace("{id}",id)
    })
}

const updateAppSlideStatus = (id,status)=>{
    return http.get({
        url:Config.APP.UPDATE_SLIDE_STATUS.replace("{id}",id).replace("{status}",status)
    })
}
const deleteAppSlide = (id) =>{
    return http.get({
        url:Config.APP.DELETE_SLIDE.replace("{id}",id)
    })
}

const getWebsiteMenuById = (id) =>{
    return http.get({
        url:Config.HANDBOOK.GET_WEBSITE_MENU_BY_ID.replace("{id}",id)
    })
}
const getWebsiteProductById = (id) =>{
    return http.get({
        url:Config.HANDBOOK.GET_WEBSITE_PRODUCT_BY_ID.replace("{id}",id)
    })
}

const getGeoResServerStatus=()=>{
    return http.get({url:Config.HANDBOOK.GET_GEORES_SERVER_STATUS})
}
export  {
    getGeoResServerStatus,
    list,
    Update,
    getSlides,
    tablesListById,
    cardCashbackList,
    updateMenuGroup,
    updateMenu,
    updateSection,
    updateTable,
    updateCashbackStatus,
    websiteMenuList,
    websiteMenuProducts,
    updateWebsiteMenu,
    updateWebsiteProduct,
    deleteWebsiteMenu,
    deleteWebsiteMenuProduct,
    languages,
    getDataByLanguage,
    setLanguage,
    deleteTranslation,
    addWebsiteProduct,
    LastUpdate,
    updateProductStatus,
    getSpaces,
    updateSpacesStatus,
    saveOrUpdateSpace,
    deleteSpace,
    getWebsiteMenuById,
    getWebsiteProductById,
    updateSlideStatus,
    saveOrUpdateSlide,
    deleteSlide,
    getAppSlides,
    saveOrUpdateAppSlide,
    deleteAppSlide,
    updateAppSlideStatus,
    deleteOrderNotificationNumber,
    getOrderNotificationNumbers,
    addOrderNotificationNumber
}
