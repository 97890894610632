import {Position, ToolbarItem} from "devextreme-react/popup"
import {Button, Popup} from "devextreme-react"
import {CLoseIcon, Edit, PlusIcon} from "../../assets/icons/assets"
import {Form, Table} from "react-bootstrap"
import _ from "lodash"
import DatePicker from "react-datepicker"
import moment from "moment/moment"
import DataGrid, {Column} from "devextreme-react/data-grid"
import {Api, i18n} from "../../core"
import React, {useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import notify from "devextreme/ui/notify"

const OrderModal=({details,setDetails})=>{

    const [spaces,setSpaces]=useState([])
    const [allProducts,setAllProducts] = useState([])


    const {t}= useTranslation()

    useEffect(() => {
        console.log(JSON.stringify(details))
    }, [details]);


    useEffect(()=> {
        console.log(details)
        getAllProducts();

        getSpaces();
    },[])
    const getAllProducts=()=>{
        Api.Menu.getAllProducts()
            .then((response)=>{
                if(response.status){
                    setAllProducts(response.data)
                }
            })
    }
    const getSpaces = () => {
        Api.HandBook.getSpaces().then(response=>{
            setSpaces(response.status?response.data:[])
        })
    }

    const updateReservation=()=>{
        Api.Reservation.updateReservation({data:{
                type:details.type,
                order_date:details.order.order_date,
                space_id:details.order.space_id,
                order_time:details.order.order_time,
                guestQty:details.order.guestQty,
                cart:details?.details||[],
                orderId:details.order.id,
                user_id:details.order.user_id,
                comment:details.order.comment
            }})
            .then((response)=>{
                if(response.status){
                    setDetails(null)
                }
                notify(response.data,response.status?'success':'error',3000);

            })
    }



    return  details && (
        <Popup
            visible={true}
            onHiding={()=>setDetails(null)}
            dragEnabled={false}
            hideOnOutsideClick={true}
            showCloseButton={false}
            showTitle={true}
            title={t("დეტალები")}
            container=".dx-viewport"
            width={800}
            height={800}

        >
            <Position
                at="center"
                my="center"
                of={"center"}
            />

            {
                details?.order?.canChange===1 && (
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="top"
                        location="after"
                        render={()=>(
                            <Button
                                icon={Edit}
                                type="normal"
                                style={{margin: 0, padding: 0,width:"35px",height:"35px"}}
                                stylingMode="contained"
                                onClick={() => {
                                    let data= details;
                                    data.type=(data.type==="edit"?"info":"edit");
                                    setDetails({...data})
                                }}
                            />
                        )}
                    />
                )

            }

            {
                 (


                        <ToolbarItem
                            widget="dxButton"
                            toolbar="bottom"
                            location="before"
                            render={ ()=><b><h3 style={{color:'green'}}>ჯამი: {_.sum(details?.details.map(v=>v.qty*v.price))} ლარი </h3></b>}

                        />


                )
            }
            {
                details?.type==='edit' && (!details?.order?.id || details?.order?.canChange===1) && (


                        <ToolbarItem
                            widget="dxButton"
                            toolbar="bottom"
                            location="after"

                            options={{
                                text: t('შენახვა'),
                                onClick: ()=>{
                                    updateReservation()
                                }
                            }}
                        />


                )
            }
            <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="after"
                options={{
                    text: t('დახურვა'),
                    onClick: ()=>setDetails(null),
                }}
            />

            {
                details.type==='edit'? (
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>{t("სივრცე")}</Form.Label>

                            <Form.Select aria-label="აირჩიეთ სივრცე" value={details?.order?.space_id}  onChange={e=>{
                                let data= details;
                                data.order["space_id"]=e.target.value;
                                setDetails({...data})

                            }}>
                                <option>{t("აირჩიეთ სივრცე")}</option>
                                {
                                    _.map(spaces,space=><option value={space.id} key={space.id}>{space.title}</option>)
                                }
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>{t("სტუმრების რაოდენობა")}</Form.Label>

                            <Form.Select aria-label="აირჩიეთ სტუმრების რაოდენობა" value={details?.order?.guestQty} onChange={e=>{
                                let data= details;
                                data.order["guestQty"]=e.target.value;
                                setDetails({...data})

                            }}>
                                <option>აირჩიეთ სტუმრების რაოდენობა</option>
                                {
                                    _.range(1,15).map(v=>{
                                        return <option key={v} >{v}</option>
                                    })
                                }

                                <option value={"15+"}>15+</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>{t("სტუმრების რაოდენობა")}</Form.Label>
                            <div>
                                <DatePicker
                                    selected={details?.order?.order_timestamp? moment(details?.order?.order_timestamp).toDate() : new Date()}
                                    onChange={(date) => {
                                        let data= details;
                                        data.order["order_date"]=moment(date).format("YYYY-MM-DD");
                                        data.order["order_time"]=moment(date).utcOffset("+0400").format("HH:mm");
                                        data.order["order_timestamp"]=date;
                                        setDetails({...data})

                                        /*  setDateTime({...dateTime,time:moment(date).utcOffset("+0400").format("HH:mm")})
                                          setDateTime({...dateTime,date:moment(new Date()).format("YYYY-MM-DD")})
                                          setDate(moment(new Date()).format("YYYY-MM-DD"))
                                          setStartDate(date)*/
                                    }}
                                    showTimeSelect
                                    minDate={new Date()}
                                    locale="ka"
                                    dateFormat="dd-MM-yyyy hh:mm"
                                    timeFormat="HH:mm"
                                />
                            </div>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>კომენტარი</Form.Label>
                            <Form.Control as="textarea" rows={3} value={details?.order?.comment} onChange={e=>{
                                let data= details;
                                data.order.comment=e.target.value;
                                setDetails({...data})

                            }} />
                        </Form.Group>

                        <div style={{width:"100%",height:330,border:'1px solid #ced4da', borderRadius:5}}>
                            <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between', alignItems:'center',paddingLeft:20,borderBottom:'1px solid black'}}>
                                <div>პროდუქტები</div>
                                <div>
                                    <Button
                                        icon={PlusIcon}
                                        type="normal"
                                        style={{margin: 0, padding: 0,width:"35px",height:"35px"}}
                                        stylingMode="contained"
                                        onClick={() => {
                                            let data= details;
                                            if(!data?.details){
                                                data["details"]=[];
                                            }
                                            data.details.push({id: "", order_id: data?.order?.id, product_id: "", price: '', qty: 1})
                                            setDetails({...data})

                                        }}
                                    />
                                </div>
                            </div>
                            <div style={{overflowY:'scroll',height:'100%',width:'100%'}}>
                                <Table striped bordered hover center>
                                    <tbody>
                                    {
                                        _.map(details?.details || [], (detail,index)=>{
                                            return (
                                                <tr key={detail.product_id}>

                                                    <td>
                                                        <Form.Select aria-label="აირჩიეთ კერძი" value={detail?.product_id} onChange={(e)=>{
                                                            if(e.target.value){
                                                                const find = allProducts.find(v=>v.product_id==e.target.value)
                                                                if(find){
                                                                    let data= details;
                                                                    data.details[index]["product_id"]=e.target.value;
                                                                    data.details[index]["price"]=find["product_price"];
                                                                    data.details[index]["product_price"]=find["product_price"];
                                                                    setDetails({...data})

                                                                }
                                                            }else{

                                                            }
                                                        }}>
                                                            <option>აირჩიეთ კერძი</option>
                                                            {
                                                                _.map(allProducts, product=>{
                                                                    return  <option key={product?.product_id} value={product?.product_id}>{product?.product_name}</option>
                                                                })
                                                            }
                                                        </Form.Select>
                                                    </td>
                                                    <td>
                                                        <Form.Control type="number" placeholder="ფასი" value={detail?.price} disabled />
                                                    </td>
                                                    <td>
                                                        <Form.Control type="number" placeholder="რაოდენობა" value={detail?.qty}  onChange={e=>{
                                                            let data= details;
                                                            data.details[index]["qty"]=e.target.value;
                                                            setDetails({...data})
                                                        }} />
                                                    </td>
                                                    <td width="50">
                                                        <Button
                                                            icon={CLoseIcon}
                                                            type="normal"
                                                            style={{margin: 0, padding: 0,width:"35px",height:"35px"}}
                                                            stylingMode="contained"
                                                            onClick={() => {
                                                                let data =details;
                                                                data.details.splice(index,1)
                                                                setDetails({...data})
                                                            }}
                                                        />

                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }

                                    </tbody>

                                </Table>
                            </div>
                        </div>

                    </Form>
                ):(
                    <div>

                        <div style={{
                            marginBottom:20,
                            padding:20,
                            flexWrap: "wrap",
                            overflowWrap: "break-word",
                            maxHeight: "136px"
                        }}>
                            {
                                details?.comment
                            }
                        </div>


                        <DataGrid
                            scrolling={{mode:'default'}}
                            height={"350px"}
                            dataSource={details?.details || []}
                            keyExpr="id"
                            showBorders={true}
                        >
                            <Column
                                width={'50px'}
                                dataField={'id'}
                                caption={"#"}
                                hidingPriority={6}
                            />
                            <Column
                                dataField={'product_name'}
                                caption={i18n.t("დასახელება")}
                                hidingPriority={6}
                            />
                            <Column
                                dataField={'price'}
                                caption={i18n.t("ფასი")}
                                hidingPriority={6}
                            />
                            <Column
                                dataField={'product_price'}
                                caption={i18n.t("ფასი პროგრამიდან")}
                                hidingPriority={6}
                            />
                            <Column
                                dataField={'qty'}
                                caption={i18n.t("რაოდენობა")}
                                hidingPriority={6}
                            />
                        </DataGrid>



                    </div>
                )

            }



        </Popup>
    )
}

export default OrderModal;
