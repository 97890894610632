import {Button, FileUploader, Popup, SelectBox, TextBox} from "devextreme-react";
import React, {useEffect, useRef, useState} from "react";

import {Modal, Form, Row, Col, Tab, Tabs} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {Conf} from "../../../core/config/Conf";
import AutoComplete from "../../autocomplete/AutoComplete";
import {i18n} from "../../../core";
import _ from "lodash";

export default function HandbookWebsiteMenuModalV2(props){
	const [selected,setSelected]=useState([])
	const [data,setData]=useState(null)
	const fileUploaderRef = useRef();
	useEffect(()=>{
		if(props.data) {
			setSelected([])
			setData({...props.data, icon: props?.data?.image})
		}else{
			setData(null)
		}
	},[props.data])


	const onHandleImage=(e)=> {
		if(e && e.length>0){
			const reader = new FileReader();
			reader.onload = function(){
				setData({...data,icon:reader.result,fileChange:true});
			};
			reader.readAsDataURL(e[0]);
		}
	}

	return  data !==null && <Modal show={true} onHide={()=>props.onClose()}>
		<Modal.Header closeButton>
			<Modal.Title>{data.original_name || i18n.t('კერძის დამატება')}</Modal.Title>
		</Modal.Header>
		<Modal.Body>
			<Form>
				{
					data?.new && (
						<AutoComplete
							label={i18n.t("აირჩიეთ კერძი")}
							onChange={e=>{
								setSelected(e)
								if(e && e[0] && e[0].product_id){
									setData({...data,product_id:e[0].product_id,icon:props.products[props.products.findIndex(v=>parseInt(v.product_id)===parseInt(e[0].product_id))]?.image})
								}
							}}
							placeholder={i18n.t("აირჩიეთ კერძი")}
							data={props.products}
							labelKey={"product_name"}
							selected={selected}
						/>
					)
				}
					<br/>
				<Tabs
					defaultActiveKey="ka"
					id="uncontrolled-tab-example"
					className="mb-3"
				>
					{
						_.map(data.details,(v,k)=>{
							 return (
								 <Tab eventKey={v.lang_key} title={v.lang_key} key={k}>
									 <Form.Group className="mb-3" controlId="form2">
										 <Form.Label>{i18n.t("დასახელება")}</Form.Label>
										 <Form.Control  type={"text"} value={v.title} onChange={e=>{
											 let  tmp = data.details;
											 tmp[k]['title']= e.target.value
											 setData({...data,details:[...tmp]})
										 }} />
									 </Form.Group>
									 <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
										 <Form.Label>{i18n.t("აღწერა")}</Form.Label>
										 <Form.Control as="textarea" rows={3} value={v.description}
													   onChange={e=>{
														   let  tmp = data.details;
														   tmp[k]['description']= e.target.value
														   setData({...data,details:[...tmp]})
													   }}/>
									 </Form.Group>
								 </Tab>
							 )
						})
					}



				</Tabs>


				<Row>
					<Col>
						<Form.Group className="mb-3" controlId="form2">
							<Form.Label>{i18n.t("რიგითობა")}</Form.Label>
							<Form.Control type="number" placeholder="რიგითობა" value={data.sort} onChange={e=>setData({...data,sort:e.target.value})} />
						</Form.Group>
					</Col>
					<Col>
						<Form.Group className="mb-3">
							<Form.Label htmlFor="disabledSelect">{i18n.t("სტატუსი")}</Form.Label>
							<Form.Select id="disabledSelect" value={data.status} onChange={e=>setData({...data,status:e.target.value})}>
								<option value="">{i18n.t("აირჩიეთ სტატუსი")}</option>
								<option value="1">{i18n.t("აქტიური")}</option>
								<option value="-1">{i18n.t("პასიური")}</option>
							</Form.Select>
						</Form.Group>
					</Col>
				</Row>
				<Form.Group controlId="formFile" className="mb-3">
					<Form.Label>აირჩიეთ ფოტო</Form.Label>
					<Form.Control type="file" ref={fileUploaderRef} onChange={e=>onHandleImage(e.target.files)}/>
				</Form.Group>
				<Form.Group controlId="formFile" className="mb-3">
					{
						data.icon !==null && data.icon !=="" && data.icon  !==undefined  && <div style={{position:'relative',width:"150px"}}>
							<div style={{
								position:"absolute",
								right:"10px",
								top:"10px",
								cursor:'pointer'
							}}
								 onClick={()=>{
									 fileUploaderRef.current.value=""
									 setData({...data,icon:"",image:'',fileChange:true});
								 }}
							>
								<FontAwesomeIcon icon={faTimes} color={"red"} size={"2x"}  />
							</div>
							<img src={data.icon && data?.fileChange ?  data.icon:Conf.fileUri+data.icon} width={"150px"} height={"auto"}  alt={"image"}/>
						</div>
					}
				</Form.Group>
			</Form>
		</Modal.Body>
		<Modal.Footer>
			<Button variant="secondary" onClick={()=>props.onClose()}>
				დახურვა
			</Button>
			<Button variant="primary" onClick={()=>props.onSave(data)}>
				შენახვა
			</Button>
		</Modal.Footer>
	</Modal>


}


