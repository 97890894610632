import {Button, FileUploader, Popup, SelectBox, TextBox} from "devextreme-react";
import React, {useEffect, useRef, useState} from "react";

import {Modal,Form} from "react-bootstrap";

import { i18n} from "../../../core";

export default function TranslationModal(props){
	const [data,setData]=useState(null)
	useEffect(()=>{
		if(props.data) {
			setData({...props.data})
		}else{
			setData(null)
		}
	},[props.data])




	return  data !==null && <Modal show={true} onHide={()=>props.onClose()}>
		<Modal.Header closeButton>
			<Modal.Title>{i18n.t("თარგმნა")}  {data?.language}</Modal.Title>
		</Modal.Header>
		<Modal.Body>
			<Form>

				<Form.Group className="mb-3" controlId="form2">
					<Form.Label>{i18n.t("სათარგმნი სიტყვა")}</Form.Label>
					<Form.Control type="text" placeholder="" value={data.key} onChange={e=>setData({...data,key:e.target.value})} />
				</Form.Group>
				<Form.Group className="mb-3" controlId="form3">
					<Form.Label>{i18n.t("თარგმანი ქართულად")} </Form.Label>
					<Form.Control type="text" placeholder="" value={data.value_ka} onChange={e=>setData({...data,value_ka:e.target.value})} />
				</Form.Group>
				<Form.Group className="mb-3" controlId="form4">
					<Form.Label>{i18n.t("თარგმანი რუსულად")} </Form.Label>
					<Form.Control type="text" placeholder="" value={data.value_ru} onChange={e=>setData({...data,value_ru:e.target.value})} />
				</Form.Group>
				<Form.Group className="mb-3" controlId="form5">
					<Form.Label>{i18n.t("თარგმანი ინგლისურად")} </Form.Label>
					<Form.Control type="text" placeholder="" value={data.value_en} onChange={e=>setData({...data,value_en:e.target.value})} />
				</Form.Group>
				<Form.Group className="mb-3">
					<Form.Label htmlFor="disabledSelect">თარგმნის ტიპი </Form.Label>
					<Form.Select id="disabledSelect" value={data.type} onChange={e=>setData({...data,type:e.target.value})}>
						<option value="">აირჩიეთ </option>
						<option value="1">კერძები</option>
						<option value="2">ვებგვერდი</option>
					</Form.Select>
				</Form.Group>
			</Form>
		</Modal.Body>
		<Modal.Footer>
			<Button variant="secondary" onClick={()=>props.onClose()}>
				{i18n.t('დახურვა')}
			</Button>
			<Button variant="primary" onClick={()=>props.onSave(data)}>
				{i18n.t('შენახვა')}
			</Button>
		</Modal.Footer>
	</Modal>


}


