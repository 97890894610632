import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import 'devextreme/data/odata/store';
import DataGrid, {
	Column,
	Pager,
	Paging,
	FilterRow,
} from 'devextreme-react/data-grid';
import {Api, i18n} from "../../../core";
import {useDispatch, useSelector} from "react-redux";
import {Box, Button} from "devextreme-react";
import {CLoseIcon, Edit, Loader, PlusIcon,UpdateIcon} from "../../../assets/icons/assets";
import HandbookWebsiteMenuModalV1 from "../../../components/handbook/handbookWebsiteModalsV1/handbookWebsiteMenuModalV1";
import HandbookWebsiteMenuModalV2
	from "../../../components/handbook/handbookWebsiteModalsV2/handbookWebsiteMenuModalV2";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import {faSquare,faCheckSquare} from "@fortawesome/free-regular-svg-icons";
import {Col, Form, Row} from "react-bootstrap";
import _ from "lodash";

export default function Products() {

	const dispatch = useDispatch();
	const productRef = useRef();
	const groups = useSelector(state=>state.Groups)

	const [addProducts,setAddProducts]=useState([])
	const [allProducts,setAllProducts] = useState([])
	const menuList = useSelector(state=>state.HandBook.websiteMenuList)
	const [products,setProducts]= useState([])
	const [btnLoader,setBtnLoader]=useState(-1)
	const [selectedMenuId,setSelectedMenuId]=useState(null)
	const [selectedGroup,setSelectedGroup]=useState(null)
	const [group,setGroup]=useState("")
	const [selectedProduct,setSelectedProduct]= useState(null)
	const [menuModal,setMenuModal] = useState(true)
	const [errors,setErrors]=useState(null)
	useEffect(()=>{
		dispatch(Api.Menu.list());
		dispatch(Api.HandBook.websiteMenuList())
		Api.Menu.getAllProducts()
			.then((response)=>{
				if(response.status){
					setAllProducts(response.data)
				}
			})
		i18n.on("languageChanged",e=> {
			Api.Settings.ChangeLanguage(e).then(e=> {
				dispatch(Api.HandBook.websiteMenuList())
				fetchProducts();
			})
		})
		return () =>{
			i18n.off("languageChanged",e=>{

			})
		}
		},[])
	useEffect(()=>{
		fetchProducts();
	},[selectedMenuId])
	const fetchProducts = () =>{
		setProducts([])
		if(selectedMenuId){
			Api.HandBook.websiteMenuProducts({menu_id:selectedMenuId})
				.then(response=>{
					setProducts(response.status?response.data:[])
				})
		}
	}

	const filtered = useMemo(()=>{

		if(group && group !==""){
			return products.filter(v => v.group_id.toString()===group.toString())
		}
		return products;
	},[products,group])
	return (
		<React.Fragment>

			<HandbookWebsiteMenuModalV1 errors ={errors} show={menuModal} onClose={()=>setSelectedGroup(null)}  data={selectedGroup} setSelected={setSelectedGroup} onSave={e=>{
				setErrors(null)

				Api.HandBook.updateWebsiteMenu(e).then(response=>{
					if(response.status){
						setSelectedGroup(null)
						dispatch(Api.HandBook.websiteMenuList())

					}
				})

			}}/>
			<HandbookWebsiteMenuModalV2  products={allProducts}  data={selectedProduct} onClose={()=>setSelectedProduct(null)} setSelected={setSelectedProduct} onSave={e=>Api.HandBook.updateWebsiteProduct({...e,menu_id:e?.new?selectedMenuId:e?.menu_id}).then(response=>{
				if(response.status){
					fetchProducts();
					setSelectedProduct(null)
				}
			})}/>
			<div style={{"padding":"20px 20px 20px 20px"}}>
				<Row>
					<Col
						sm="4"
					>
						<div className={'dx-card '}>
							<div style={{"padding":"10px 10px",display:'flex',justifyContent:'space-between',alignItems:'center' }}>
								<strong>{i18n.t("ვებსაიტის მენიუ")}</strong>
								<Button
									icon={PlusIcon}
									type="normal"
									style={{margin: 0, padding: 0,width:"35px",height:"35px"}}
									stylingMode="contained"
									onClick={() => {
										setSelectedGroup({
											status:-1,
											sort:1,
											product_id:-1,
											details:[
												{
													wm_id:'',
													lang_key:'ka',
													title:''
												},
												{
													wm_id:'',
													lang_key:'ru',
													title:''
												},
												{
													wm_id:'',
													lang_key:'en',
													title:''
												}
											]
										})
									}}
								/>
							</div>
							<DataGrid
								height={`${window.innerHeight - 150}px`}
								scrolling={{
									mode:'standard'
								}}
								loadPanel={
									{
										enabled:true
									}
								}
								selection={{
									mode:'single'
								}}
								onSelectionChanged={({selectedRowsData})=> setSelectedMenuId(selectedRowsData[0]["id"])}
								className={'dx-card wide-card'}
								hoverStateEnabled={true}
								dataSource={menuList}
								showBorders={false}
								focusedRowEnabled={true}
								//defaultFocusedRowIndex={0}
								columnAutoWidth={true}
								columnHidingEnabled={false}
								keyExpr="id"
								allowColumnReordering={true}
								allowColumnResizing={false}

							>
								<Paging defaultPageSize={300} />
								<Pager showPageSizeSelector={true} showInfo={true} />

								<FilterRow visible={true} />


								<Column dataField={'id'} caption={"#"} alignment={"left"} width={90} hidingPriority={6} allowFiltering={false} />
								<Column
									dataField={'title'}
									caption={i18n.t("დასახელება")}
									hidingPriority={6}

								/>
								<Column
									dataField={'type'}
									caption={i18n.t("ტიპი")}
									hidingPriority={6}
								/>
								<Column
									dataField={'status'}
									caption={i18n.t("სტატუსი")}
									hidingPriority={6}
									cellRender={(e)=>{
										return <FontAwesomeIcon icon={e.data.status===1? faCheck: faTimes} color={e.data.status===1?"green":"red"} size={"1x"} />

									}}
								/>
								<Column
									dataField={'sort'}
									caption={i18n.t("რიგითი ნომერი")}
									hidingPriority={6}
								/>

								{/*
								<Column
									dataField={'icon'}
									caption={i18n.t("ფოტო")}
									hidingPriority={6}
								/>*/}
								<Column dataField=""
										cssClass={"custom-button-icon"}
										width={50}
										allowSorting={false}
										cellRender={(e)=>{
											return  <Button
												disabled={btnLoader === e.key}
												icon={btnLoader === e.key? Loader:Edit}
												type="normal"
												style={{margin: 0, padding: 0,width:"35px",height:"35px"}}
												stylingMode="contained"
												onClick={() => {
													Api.HandBook.getWebsiteMenuById(e.data.id).then((response)=>{
														setSelectedGroup({...e.data,name:e.data.group_name,details:response.data})
													})
												}}
											/>
										} }
								/>
								<Column dataField=""
										cssClass={"custom-button-icon"}
										width={50}
										allowSorting={false}
										cellRender={(e)=>{
											return <Button
												disabled={btnLoader === e.key}
												icon={btnLoader === e.key? Loader:CLoseIcon}
												type="normal"
												style={{margin: 0, padding: 0,width:"35px",height:"35px"}}
												stylingMode="contained"
												onClick={() => {

													if(window.confirm("დარწმუნებული ხართ, რომ გსურთ წაშლა")){
														Api.HandBook.deleteWebsiteMenu(e.key).then(response=>{
															if(response.status){
																dispatch(Api.HandBook.websiteMenuList())
																setSelectedProduct(null);
																setSelectedMenuId(null)
															}
														})
													}
												}}
											/>
										} }
								/>
							</DataGrid>
						</div>
					</Col>
					<Col
						sm="8"
						ratio={2}
					>
						<div className={'dx-card '}>
							<div style={{"padding":"10px 10px",display:'flex',justifyContent:'space-between',alignItems:'center' }}>
								<div style={{flex:1,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
									<strong>{i18n.t("კერძები")}</strong>

									<Form.Select aria-label={i18n.t("კატეგორია")} style={{
										marginLeft:'20px',
										marginRight:'20px'
									}} value={group} onChange={e=>setGroup(e.target.value)}>
										<option value="">{i18n.t('კატეგორია')}</option>
										{
											_.map(groups,v=>{
												return <option key={v.group_id} value={v.group_id}>{v.group_name}</option>
											})
										}

									</Form.Select>
								</div>




								{
									selectedMenuId && addProducts.length>0 && <Button
										icon={PlusIcon}
										type="normal"
										style={{margin: 0, padding: 0,width:"35px",height:"35px"}}
										stylingMode="contained"
										onClick={() => {


											if(window.confirm(i18n.t("დარწმუნებული ხართ, რომ გსურთ დაამატება"))){
												Api.HandBook.addWebsiteProduct({
														data:{
															menu_id:selectedMenuId,
															product_id:addProducts,
														},
														loader:setBtnLoader
													}
												).then(response=>{
													if(response.status){
														setAddProducts([])
														fetchProducts()
													}
												})
											}

											/*setSelectedProduct({
												id:-1,
												image: "",
												product_description: "",
												product_price: "",
												sort: 1,
												status: 1,
												title: ""
											})*/
										}}
									/>
								}
								{
									selectedMenuId && addProducts.length===0 &&<Button
										icon={PlusIcon}
										type="normal"
										style={{margin: 0, padding: 0,width:"35px",height:"35px"}}
										stylingMode="contained"
										onClick={() => {

											setSelectedProduct({
												id:-1,
												image: "",
												product_description: "",
												product_price: "",
												sort: 1,
												status: 1,
												title: "",
												new:true,
												details:[
													{
														product_id: '',
														lang_key:'ka',
														title:'',
														description:''
													},
													{
														product_id: '',
														lang_key: 'ru',
														title: '',
														description: ''
													},
													{
														product_id: '',
														lang_key:'en',
														title:'',
														description:''
													}
												]
											})
										}}
									/>
								}
							</div>
							<DataGrid

								ref={productRef}
								height={`${window.innerHeight - 150}px`}
								scrolling={{
									mode:'standard'
								}}
								loadPanel={
									{
										enabled:true
									}
								}

								className={'dx-card wide-card'}
								dataSource={filtered}
								showBorders={false}
								focusedRowEnabled={true}
								//defaultFocusedRowIndex={0}
								columnAutoWidth={true}
								columnHidingEnabled={true}
								keyExpr="id"
								allowColumnReordering={true}
								allowColumnResizing={true}

							>
								<Paging defaultPageSize={30} />
								<Pager showPageSizeSelector={true} showInfo={true} />

								<FilterRow visible={true} />

								<Column dataField={'id'} caption={"#"} alignment={"left"} width={90} hidingPriority={6} allowFiltering={false} />
								<Column
									dataField={'original_name'}
									caption={i18n.t("GeoRess დასახელება")}
									hidingPriority={6}
								/>
								<Column
									dataField={'product_name'}
									caption={i18n.t("დასახელება")}
									hidingPriority={6}
								/>
								{/*<Column
									dataField={'product_description'}
									caption={i18n.t("დეტალები")}
									hidingPriority={6}
								/>*/}
								<Column
									width="100"
									dataField={'product_price'}
									caption={i18n.t("ფასი")}
									hidingPriority={6}
								/>
								<Column
									width="100"
									dataField={'geores_status'}
									caption={i18n.t("GeoresStatus")}
									hidingPriority={6}
									cellRender={e=>	<FontAwesomeIcon icon={e.data.geores_status===1? faCheck: faTimes} color={e.data.geores_status===1?"green":"red"} size={"1x"} />
									}
								/>
								<Column
									width="100"
									dataField={'status'}
									caption={i18n.t("სტატუსი")}
									hidingPriority={6}
									cellRender={(e)=>{


										return e.data.id && <Button onClick={()=>{
											let findIndex = products.findIndex(v=>v.id===e.data.id);
											let newProducts= products;

											Api.HandBook.updateProductStatus(e.data.id,e.data.status===1?-1:1).then(response=>{
												//fetchProducts()
												if(response.status){
													newProducts[findIndex]["status"]=e.data.status===1?-1:1;

													setProducts([...newProducts])
												}else{

												}
											})
										}
										}>
											<FontAwesomeIcon icon={e.data.status===1? faCheck: faTimes} color={e.data.status===1?"green":"red"} size={"1x"} />
										</Button>

									}}
								/>
								<Column
									width="100"
									dataField={'sort'}
									caption={i18n.t("რიგითი ნომერი")}
									hidingPriority={6}
								/>
								<Column dataField=""
										cssClass={"custom-button-icon"}
										width={50}
										allowSorting={false}
										cellRender={(e)=>{
											return   <Button
												disabled={btnLoader === e.key}
												type="normal"
												style={{margin: 0, padding: 0,width:"35px",height:"35px"}}
												stylingMode="contained"
												onClick={() => {
													if(e.data.id){

														if(window.confirm(i18n.t("დარწმუნებული ხართ, რომ გსურთ წაშლა"))){
															Api.HandBook.deleteWebsiteMenuProduct(e.key).then(response=>{
																if(response.status){
																	fetchProducts()
																}
															})
														}
													}else{

														if(addProducts.indexOf(e.data.product_id)>-1){
															setAddProducts([...addProducts.filter(v=>v !==e.data.product_id)])
														}else{
															setAddProducts([...addProducts,e.data.product_id])
														}


													}
												}}
											>
												<FontAwesomeIcon icon={e.data.id || addProducts.indexOf(e.data.product_id)>-1?faCheckSquare:faSquare} size={"2x"} />
											</Button>
										} }
								/>
								<Column dataField=""
										cssClass={"custom-button-icon"}
										width={50}
										allowSorting={false}
										cellRender={(e)=>{

											return  <Button
												disabled={btnLoader === e.key}
												icon={btnLoader === e.key? Loader:Edit}
												type="normal"
												style={{margin: 0, padding: 0,width:"35px",height:"35px"}}
												stylingMode="contained"
												onClick={() => {
													Api.HandBook.getWebsiteProductById(e.data.product_id).then((response)=>{
														setSelectedProduct({...e.data,edit:true,details:response.data,menu_id:selectedMenuId})

													})
												}}
											/>
										} }
								/>
								{/*<Column dataField=""
										cssClass={"custom-button-icon"}
										width={50}
										allowSorting={false}
										cellRender={(e)=>{
											return e.data.id && <Button
												disabled={btnLoader === e.key}
												icon={btnLoader === e.key? Loader:CLoseIcon}
												type="normal"
												style={{margin: 0, padding: 0,width:"35px",height:"35px"}}
												stylingMode="contained"
												onClick={() => {

													if(window.confirm("დარწმუნებული ხართ, რომ გსურთ წაშლა")){
														Api.HandBook.deleteWebsiteMenuProduct(e.key).then(response=>{
															if(response.status){
																fetchProducts()
															}
														})
													}
												}}
											/>
										} }
								/>*/}
							</DataGrid>
						</div>
					</Col>

				</Row>

			</div>
		</React.Fragment>
	)}
