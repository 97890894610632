import {ContextMenu} from "devextreme-react";
import {Api, i18n} from "../../core";
import TranslationModal from "../handbook/translationModal/translationModal";
import React, {useEffect, useState} from "react";

export default function Translator({id,originalName,translated,translatedName,onSuccess,maxWidth="200px"}){

	const [data,setData]=useState(null)
	const [loader,setLoader]=useState(false)
	const rand = Math.random().toString().substring(5);


	return <>
		<TranslationModal data={data} onClose={()=>setData(null)} onSave={(e)=>{
			Api.HandBook.setLanguage({data:e,loader:setLoader})
				.then(response=>{
					if(response?.status){
						setData(null);
						originalName=e.value
						i18n.reloadResources().then(r =>onSuccess() )

					}
				})
		}}  />
			<ContextMenu
				dataSource={[{text: i18n.t('თარგმნა')}]}
				width={200}
				target={`#translated-${id}-${rand}`}
				onItemClick={()=>{

					Api.HandBook.getLocaleDataByKey({
						key:originalName
					}).then(response=>{
						setData({
							id:-1,
							lang_id:1,
							code:'ka',
							key:originalName,
							value_ka:response.data.value_ka,
							value_ru:response.data.value_ru,
							value_en:response.data.value_en,
							type:response.data.type
						})
					})


				}}
			/>

		<div style={{width:"100%",height:"100%",maxWidth:`${maxWidth}` ,color:`${!translated?'red':''}`,whiteSpace: "nowrap",textOverflow:'ellipsis',overflow: "hidden"}} id={`translated-${id}-${rand}`} >{translatedName}</div>
		</>

}