import http from "../http/http";
import Config from "../config/config.json"



const ChangeLanguage = (lang)=>{
	return http.get({url:Config.SETTINGS.SET_LANGUAGE.replace("{lang}",lang)})

}



export {
	ChangeLanguage
}