import {Position, ToolbarItem} from "devextreme-react/popup"
import {Button, Popup} from "devextreme-react"
import {CLoseIcon, Edit, PlusIcon} from "../../assets/icons/assets"
import {Form, Table} from "react-bootstrap"
import _ from "lodash"
import {Api} from "../../core"
import React, {useEffect, useMemo, useState} from "react"
import {useTranslation} from "react-i18next"
import {useDispatch, useSelector} from "react-redux"
import notify from "devextreme/ui/notify"
import {Timeout} from "../../core/utils/Utils";

const UserNotificationModal=({notifications,setNotifications,onSuccess})=>{
    const dispatch = useDispatch();
    const [selected,setSelected]=useState([])
    const users = useSelector(state=>state.Users)
    const [searchText,setSearchText] = useState("")
    const {t}= useTranslation()

    useEffect(() => {
        dispatch(Api.Users.list())
    }, []);


   const filteredUsers= useMemo(() => {
       if(searchText.length<3){
           return selected.length>0?users.filter(item=>selected.indexOf(item.id)>-1):users.filter((item,key)=>key<40)
       }

       return users.filter(v=>v?.userId?.indexOf(searchText)>-1 || v?.firstName?.indexOf(searchText)>-1 || v?.idNumber?.indexOf(searchText)>-1 || v?.phone?.indexOf(searchText)>-1 )

    }, [users,searchText,selected]);


    return  notifications && (
        <Popup
            visible={notifications.modal}
            onHiding={()=>setNotifications({
                title:"",
                subTitle:"",
                users:[],
                modal:false
            })}
            dragEnabled={false}
            hideOnOutsideClick={true}
            showCloseButton={false}
            showTitle={true}
            title={t("შეტყობინებები")}
            container=".dx-viewport"
            width={800}
            height={800}
        >
            <Position
                at="center"
                my="center"
                of={"center"}
            />


                   {/* <ToolbarItem
                        widget="dxButton"
                        toolbar="top"
                        location="after"
                        render={()=>(
                            <Button
                                icon={Edit}
                                type="normal"
                                style={{margin: 0, padding: 0,width:"35px",height:"35px"}}
                                stylingMode="contained"
                                onClick={() => {
                                    let data= notifications;
                                    setNotifications({...data})
                                }}
                            />
                        )}
                    />*/}

                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"

                        options={{
                            text: t('შენახვა'),
                            onClick: ()=>{

                                if(notifications.title !=="" && notifications.subTitle !=="" && selected.length !==0){
                                    onSuccess({
                                        title:notifications.title,
                                        subTitle:notifications.subTitle,
                                        users:selected
                                    })
                                }else{
                                    notify("შეამოწმეთ ყველა ველი","error")
                                }
                            }
                        }}
                    />
            <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="after"
                options={{
                    text: t('დახურვა'),
                    onClick: ()=>setNotifications({
                        title:"",
                        subTitle:"",
                        users:[],
                        modal:false
                    }),
                }}
            />

            {
                <Form>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Label>სათაური</Form.Label>
                        <Form.Control type="text" rows={3} value={notifications?.title} onChange={e=>{
                            let data= notifications;
                            data.title=e.target.value;
                            setNotifications({...data})

                        }} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Label>ტექსტი</Form.Label>
                        <Form.Control as="textarea" rows={3} value={notifications?.subTitle} onChange={e=>{
                            let data= notifications;
                            data.subTitle=e.target.value;
                            setNotifications({...data})
                        }} />
                    </Form.Group>
                    <div style={{width:"100%",height:'500px',border:'1px solid #ced4da', borderRadius:5}}>
                        <div style={{height:'100%',width:'100%',padding:10}}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>მომხმარებლის ძიება  | მონიშნულია {selected.length} |  <span style={{color:'#ff5722'}}>*შენიშნვა. ძიებისთვის აკრიფეთ მინიმუმ 3 სიმბოლო</span></Form.Label>
                                <Form.Control type="text" placeholder="მომხმარებლის ძიება" value={searchText} onChange={e=>{
                                    setSearchText(e.target.value)


                                }} />
                            </Form.Group>
                            <div style={{height:"375px",overflowY:'scroll'}}>
                                <Table striped bordered hover center >
                                    <tbody>
                                    {
                                        _.map(filteredUsers, (user,index)=>{
                                            return (
                                                <tr key={user.userId}>

                                                    <td>
                                                        <Form.Check // prettier-ignore
                                                            type={"checkbox"}
                                                            id={`default-${user.id}`}
                                                            label={`${user.id}`}
                                                            checked={selected.indexOf(user.id)>-1}
                                                            value={user.id}
                                                            onChange={e=>{
                                                             if(e.target.checked){
                                                                 setSearchText("")
                                                                 setSelected(prevState => [...prevState,user.id])
                                                             }else{
                                                                 setSelected(prevState => _.filter(prevState,value => value !==user.id))
                                                             }
                                                            }}
                                                        />
                                                    </td>
                                                    <td>
                                                        <span>{user.firstName+" "+user.lastName}</span>
                                                    </td>
                                                    <td>
                                                        <span>{user.idNumber}</span>
                                                    </td>
                                                    <td>
                                                        <span>{user.phone}</span>
                                                    </td>
                                                    <td width="50">
                                                        <Button
                                                            icon={CLoseIcon}
                                                            type="normal"
                                                            style={{margin: 0, padding: 0,width:"35px",height:"35px"}}
                                                            stylingMode="contained"
                                                            onClick={() => {
                                                                setSelected(prevState => _.filter(prevState,value => value !==user.id))
                                                            }}
                                                        />
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    </tbody>
                                </Table>
                            </div>

                        </div>
                    </div>
                </Form>
            }
        </Popup>
    )
}

export default UserNotificationModal;
