import React, {useEffect, useState} from "react";
import {Box, Button, FileUploader, Form, Popup} from "devextreme-react";
import {GroupItem, SimpleItem} from "devextreme-react/form";
import {Api, i18n} from "../../core";
import {Tabs} from "../tabs/tabs";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DataGrid, {Column} from "devextreme-react/data-grid";

import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {faSquare} from "@fortawesome/free-regular-svg-icons";
import {UseConfirmation} from "../../core/hooks/useConfirmation";
import {Col, Row,Form as Form1} from "react-bootstrap";
import notify from "devextreme/ui/notify";

export default function StaffDetails(props){

	const [data,setData]=useState(null)
	const [tab,setTab]=useState(0)
	const confirmation = UseConfirmation();
	useEffect(()=>{

			setData(props.data)

	},[props])
	function avatarRender() {
		return (
			<>
				<div className="form-avatar"></div>
			</>

		);
	}
	return <Popup
		maxWidth={"800px"}
		showTitle={true}
		title={ data?.firstName.concat(" ",data?.lastName) }
		dragEnabled={true}
		closeOnOutsideClick={true}
		visible={data !==null}
		onHiding={e=>props.setSelected(null)}
		contentRender={()=>(
			<div>
				<div >
					<Tabs onChangeTab={e=>setTab(e)} config={[
						{key:0,title:i18n.t("პერსონალური ინფორმაცია")},
						{key:1,title:i18n.t("როლები")},
						{key:2,title:i18n.t("უფლებები")},
					]}/>
				</div>

				<div style={{marginTop:"20px"}}>
					{
						tab===0 && (
							<div style={{paddingRight:"10px"}}>
								<Form formData={data}>
									<GroupItem cssClass="first-group" colCount={4}>
										<SimpleItem render={avatarRender}/>
										<GroupItem colSpan={3}>
											<SimpleItem dataField="firstName"  label={{text:i18n.t("სახელი")}} />
											<SimpleItem dataField="lastName" label={{text:i18n.t("გვარი")}}  />
										</GroupItem>
									</GroupItem>
									<GroupItem cssClass="second-group" colCount={1}>
										<GroupItem>
											<SimpleItem dataField="username" label={{text: i18n.t("მომხმარებელი")}} />
										</GroupItem>
									</GroupItem>


								</Form>
								<Form1.Group as={Col} controlId="formGridState">
								<Form1.Label>{i18n.t("სტატუსი")}</Form1.Label>

									<Form1.Select value={data?.status} onChange={e=>{

										Api.Staff.setStatus({id:data?.id,status:e.target.value})
											.then(response=>{
												props.onSelectionChanged({data:props.data})
												notify(response.data,response.status?'success':'error')

											})
									}}>
										<option value={"active"}  style={{color:'green'}}>{i18n.t("აქტიური")}</option>
										<option value={"inactive"} style={{color:"red"}}>{i18n.t("არააქტიური")}</option>
										<option value={"blocked"} style={{color:"red"}}>{i18n.t("დაბლოკილი")}</option>
									</Form1.Select>
								</Form1.Group>
								<Button
									style={{position:'absolute',right:"1.8rem",bottom:'1rem'}}
									className="send"
									icon="check"
									text={i18n.t("განახლება")}
									onClick={()=>console.log("click")}
								/>
							</div>
						)
					}
					{
						tab ===1 && (
							<div style={{paddingLeft:"10px"}}>
								<DataGrid
									dataSource={data?.roles}
									keyExpr="id"
									showBorders={true}
								>
									<Column  dataField="title" caption={i18n.t("როლი")} cellRender={e=>{
										return i18n.t(e.value)
									}}/>
									<Column width={"100px"} alignment={"center"} dataField="checked" caption={i18n.t("უფლებები")} cellRender={e=>{
										return  <FontAwesomeIcon icon={e.data.checked===1?faCheck:faSquare} size={"xl"} style={{cursor:"pointer"}} onClick={()=>{
											confirmation.open({
												title:i18n.t(`როლის ${e.data.checked===1?'გაუქმება':'ჩართვა'}`),
												type:'danger',
												subtitle:i18n.t(`დარწმუნებული ხართ, რომ გსურთ როლის ${e.data.checked===1?'გაუქმება':'ჩართვა'}?`),
												notes:false,
												icon:"times-circle",
												//iconColor:'red',
												//subtitleColor:'red',
												onYes:(note)=>{
													Api.Staff.setRole({
														type:e.data.checked===1?'delete':'set',
														data:{
															"admin":data.id,
															"role":e.data.id
														},loader:(e)=>{
															console.log(e)
														}
													})
														.then((response)=>{

															if(response.status){
																props.onSelectionChanged({data:props.data})
																confirmation.close()
															}
														})

												},
												onNo:()=>{
													confirmation.close()
												}
											})

										}}/>
									}}/>

								</DataGrid>
							</div>
						)
					}
					{
						tab ===2 && (
							<div style={{paddingLeft:"10px"}}>
								<DataGrid
									dataSource={data?.actions}
									keyExpr="action_id"
									showBorders={true}
								>
									<Column dataField="role_title" caption={i18n.t("როლი")} cellRender={e=>{
										return i18n.t(e.value)
									}}/>
									<Column dataField="action_title" caption={i18n.t("უფლებები")} cellRender={e=>{
										return i18n.t(e.value)
									}}/>

								</DataGrid>
							</div>
						)
					}



				</div>


			</div>

		)}
		showCloseButton={true}

	>



	</Popup>
}


