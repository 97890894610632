import React, {useEffect, useState} from 'react';
import 'devextreme/data/odata/store';
import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  Lookup, Editing
} from 'devextreme-react/data-grid';
import {Api, i18n} from "../../core";
import {useDispatch, useSelector} from "react-redux";
import {Button} from "devextreme-react";
import {Edit, Loader, PlusIcon} from "../../assets/icons/assets";
import {StaffDetails, UserDetails} from "../../components";
import _ from "lodash";
import notify from "devextreme/ui/notify";
import NewUserModal from "../../components/users/newUserModal";
import NewStaffModal from "../../components/users/newStaffModal";

export default function Staff() {
  const dispatch = useDispatch();
  const staff = useSelector(state=>state.Staff)
  const [btnLoader,setBtnLoader]=useState(-1)
  const [selected,setSelected]=useState(null)
    const [newUser, setNewUser]=useState(null)

    useEffect(()=>{

      i18n.on("languageChanged",e=>{
          Api.Settings.ChangeLanguage(e).then(e=> {
              dispatch(Api.Staff.list())
          })
      })
      dispatch(Api.Staff.list())

  },[])
  const onSelectionChanged=(e)=>{
    let data =e.data;
    Api.Staff.getDetails({id:data.id,loader:(e)=>{
        setBtnLoader(e?e.key:null)
    }}).then(response=>{
        setSelected(response.status?response.data:null)
    })

  }
  return (
    <React.Fragment>
        <NewStaffModal data={newUser} onClose={()=>setNewUser(null)} onSave={(user)=>{
            let error = [];
            ["firstName",'lastName','username','password'].forEach(v=>{
                if(!user[v] || user[v].length<2){
                    error.push(v)
                }
            })
            if(_.size(error)>0){

                notify("შეამოწმეთ ყველა ველი","error",2000)
            }else{
                Api.Staff.addUser({data:user})
                    .then(response=>{
                        notify(response.data,response.status?'success':'error',2000)
                        if(response.status) setNewUser(null)
                        dispatch(Api.Staff.list())

                    })
            }

        }}/>
      <StaffDetails data={selected} onSelectionChanged={onSelectionChanged} setSelected={setSelected}/>
      <div style={{"padding":"20px"}}>
         <strong>{i18n.t("თანამშრომლები")}</strong>
      </div>
      <div style={{"padding":"0 20px 20px 20px"}}>
        <div className={'dx-card '}>
          <DataGrid
              height={`${window.innerHeight - 150}px`}
              scrolling={{
                  mode:'standard'
              }}
              className={'dx-card wide-card'}
              dataSource={staff}
              showBorders={false}
              focusedRowEnabled={true}
              defaultFocusedRowIndex={0}
              columnAutoWidth={true}
              columnHidingEnabled={true}
              keyExpr="id"
              allowColumnReordering={true}
              allowColumnResizing={true}

          >
            <Paging defaultPageSize={20} />
            <Pager showPageSizeSelector={true} showInfo={true} />
            <FilterRow visible={true} />

            <Column dataField={'id'} width={90} hidingPriority={2} allowFiltering={false} />
            <Column
                dataField={'firstName'}
                caption={i18n.t("სახელი")}
                hidingPriority={6}
            />
            <Column
                dataField={'lastName'}
                caption={i18n.t("გვარი")}
                hidingPriority={5}
            />
            <Column
                dataField={'username'}
                caption={i18n.t("მომხმარებელი")}
                allowSorting={false}
                hidingPriority={7}
            />
            <Column
                dataField={'status'}
                caption={i18n.t("სტატუსი")}
                hidingPriority={3}
            />
            <Column
                dataField={'timestamp'}
                caption={i18n.t("რეგისტრაციის თარითი")}
                dataType={'date'}
                hidingPriority={3}
            />

            <Column
                headerCellRender={()=>{


                    return (
                        <Button
                            icon={PlusIcon}
                            type="normal"
                            style={{margin: 0, padding: 0,width:"35px",height:"35px"}}
                            stylingMode="contained"
                            onClick={() => {
                                setNewUser({
                                    firstName:'',
                                    lastName:'',
                                    username:'',
                                    password:"",
                                    icon:"",
                                    image:"",
                                    sendPassword:true
                                })
                            }}
                        />

                    )

                }}
                dataField=""
                    cssClass={"custom-button-icon"}
                    width={50}
                    allowSorting={false}
                    cellRender={(e)=>{
                      return  <Button
                                disabled={btnLoader === e.key}
                                icon={btnLoader === e.key? Loader:Edit}
                                type="normal"
                                style={{margin: 0, padding: 0,width:"35px",height:"35px"}}
                                stylingMode="contained"
                                onClick={() => onSelectionChanged(e)}
                            />
                    } }
            />
          </DataGrid>
        </div>
      </div>
    </React.Fragment>
)}
