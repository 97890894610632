import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button, CheckBox, Form, Popup} from "devextreme-react";
import {Item} from "devextreme-react/box";

import React, {useEffect, useState} from "react";
import {UseEvent} from "../../core/hooks/useEvent";
import {Label} from "devextreme-react/form"
import dxTextBox from "devextreme/ui/text_box"

export const ConfirmationPopup=()=>{
    const ev = UseEvent()
    const [details,setDetails]=useState({})

    useEffect(()=>{
        const confirmation = ev.subscribe("confirmation",(data)=>{
            setDetails({...data})
        })
        return ()=>confirmation.unsubscribe(()=>setDetails({}))
    },[])

    return (
        <Popup
            width={details?.width? details.width:"400px"}
            height={details?.height? details.height:details.notes? details?.noteTitle?"300px":"270px":"200px"}
            showTitle={true}
            title={details?.title}
            dragEnabled={true}
            closeOnOutsideClick={false}
            visible={details?.show}
            onHiding={_=>setDetails({show:false})}
            contentRender={()=>(
                <div style={{display:'flex', flexDirection:'column', height:'100%'}}>
                    {
                        details?.subtitle? <div style={{flex:1, height:'100%'}}>
                            <div style={{display:'flex', alignItems:'center',justifyContent:'flex-start'}}>
                                {
                                    details?.icon?<FontAwesomeIcon    icon={['fas',details?.icon]} size={'2x'} color={details?.iconColor}/>:null
                                }
                                &nbsp; &nbsp;
                                <span style={{color:`${details?.subtitleColor}`}}>{details?.subtitle}</span>
                            </div>
                        </div>:null
                    }
                    {
                        details?.checkbox && <div style={{flex:1}}>

                            <div className="dx-field">
                                <div className="dx-field-value" style={{width:"30px"}}>
                                    <CheckBox value={details?.checkboxValue} onValueChanged={e=>{
                                        setDetails({...details,checkboxValue:e.value})
                                    }} />
                                </div>
                                <div className="dx-field-label" style={{width:"100%"}} >&nbsp;&nbsp;&nbsp; {details?.checkboxText}</div>

                            </div>
                        </div>
                    }
                    {
                        details?.noteTitle && <div style={{flex:1}}>
                            <Form labelLocation={"top"} formData={details}>
                                <Item dataField={"title"} editorType={dxTextBox}   >
                                </Item>
                            </Form>
                        </div>
                    }
                    {
                        details?.notes && <div style={{flex:1}}>
                            <Form labelLocation={"top"} formData={details}>
                                <Item dataField={"note"}  editorType={details?.editorType}  colSpan={4}  >
                                    <Label text={details?.noteLabel} />
                                </Item>
                            </Form>
                        </div>
                    }
                    {
                        details?.details && <div style={{flex:1}}>
                            <Form labelLocation={"top"} formData={details}>
                                <Item dataField={"detailsValue"}  editorType={details?.detailsEditorType}  colSpan={4}  >
                                    <Label text={details?.detailsTitle}/>
                                </Item>
                            </Form>
                        </div>
                    }

                    <div style={{flex:1, height:'40px', borderTop: "1px solid #ddd"}}>
                        <div >&nbsp;</div>
                        <div  style={{display:'flex', flexDirection:"row", justifyContent:"flex-end" ,alignItems:'flex-end'}}>
                            <Button
                                width={120}
                                text={details?.yesText}
                                type="normal"
                                stylingMode="contained"
                                onClick={()=>{
                                    details?.onYes(details?.note, details, details?.title )
                                }}
                            />
                            &nbsp;&nbsp;
                            <Button
                                width={120}
                                text={details?.noText}
                                type="normal"
                                stylingMode="contained"
                                onClick={details?.onNo}
                            />
                        </div>
                    </div>
                </div>
            )}
            showCloseButton={true}
        />
    )
}
