import http from "../http/http";
import Config from "../config/config.json"

import {getRoles} from "../store/slices/roleSlice";

const list = ()=>async (dispatch)=>{
	http.get({
		url: Config.ROLES.LIST
	}).then(response=>{
		dispatch(getRoles(response.status?response.data:[]));
	})
}

const getActionsByRole = ({role_id,loader})=> {

	return http.get({
		url: Config.ROLES.ACTIONS_LIST.replace("{role_id}",role_id),
		loader:loader
	})
}
const getPagesByRole = ({role_id,loader})=>{
	return http.get({
		url: Config.ROLES.PAGES_LIST.replace("{role_id}", role_id),
		loader: loader
	})
}

export  {
	list,
	getActionsByRole,
	getPagesByRole
}
