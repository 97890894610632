import http from "../http/http";
import Config from "../config/config.json"
import {getStaffList} from "../store/slices/staffSlice";
import {query_string} from "../helpers/query_params";

const list = ()=>async (dispatch)=>{
	http.get({
		url: Config.STAFF.LIST
	}).then(response=>{
		dispatch(getStaffList(response.status?response.data:[]));
	})
}

const addUser=({data,loader})=>{
	return http.post({url:Config.STAFF.ADD_NEW,data:data,loader:loader})
}

const getDetails=({id,loader})=>{
	return http.get({url:Config.STAFF.DETAILS.replace("{id}",id),loader:loader})
}

const setRole=({type,data,loader})=>{
	return http.post({url:type==="set"?Config.STAFF.SET_ROLE:Config.STAFF.DELETE_ROLE,data:query_string(data),loader:loader})
}

const setStatus=({id,status,loader})=>{
	return http.get({url:Config.STAFF.SET_STATUS.replace("{id}",id).replace("{status}",status),loader:loader})
}

export  {
	list,
	getDetails,
	setRole,
	addUser,
	setStatus
}
