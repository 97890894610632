import * as Api from "../api"
import {useDispatch, useSelector} from "react-redux";
import {Ping} from "../store/slices/userSlice";

export const useUser = ()=>{
    const dispatch = useDispatch()
    const User = useSelector(state=>state.User)
    const CheckSession = ()=>{

        return new Promise((resolve)=>{
            Api.User.Ping().then(response=>{
                dispatch(Ping({
                    isLogged:response.status,
                    data:response?.data
                }))
                resolve(response)
            })
        })


    }
    const logout = ()=>{
        dispatch(Ping({
            isLogged:false,
            data:null
        }))
    }
    const signIn = (username,password) => {
        return Api.User.SignIn({username,password});
    }
    return {User,CheckSession,logout,signIn}
}
