import React, {useCallback, useEffect, useState} from 'react';
import 'devextreme/data/odata/store';
import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
} from 'devextreme-react/data-grid';
import {Api, i18n, useTranslation} from "../../../core";
import {useDispatch, useSelector} from "react-redux";
import {Box, Button,ContextMenu} from "devextreme-react";
import {Edit, Loader,} from "../../../assets/icons/assets";
import {Item} from "devextreme-react/box";
import HandbookModal from "../../../components/handbook/handBookModal/handbookModal";
import {Translator} from "../../../components";

export default function Menus() {
    const {t} = useTranslation()
  const dispatch = useDispatch();
  const groups = useSelector(state=>state.Groups)
  const [menus,setMenus]= useState([])
  const [btnLoader,setBtnLoader]=useState(-1)
  const [selectedGroupId,setSelectedGroupId]=useState(null)
  const [selectedGroup,setSelectedGroup]=useState(null)
  const [selectedMenu,setSelectedMenu]=useState(null)

  useEffect(()=>{
    dispatch(Api.Menu.list());
      i18n.on("languageChanged",e=> {
          Api.Settings.ChangeLanguage(e).then(e=> {
              dispatch(Api.Menu.list());
          })
      })
      return () =>{
          i18n.off("languageChanged",e=>{

          })
      }
      },[])
  useEffect(()=>{
      fetchMenu();
    },[selectedGroupId])
  const fetchMenu = () =>{
      setMenus([])
      if(selectedGroupId){

          Api.Menu.productsByGroup({group_id:selectedGroupId})
              .then(response=>{
                  setMenus(response.status?response.data:[])
              })
      }
  }

  return (
    <React.Fragment>
        <ContextMenu
            dataSource={[{ text: 'Favorite' },]}
            width={200}
            target=".translated"
            onItemClick={e=>console.log(e)} />
      <HandbookModal  form={"menuGroup"}  data={selectedGroup} setSelected={setSelectedGroup} onSave={e=>Api.HandBook.updateMenuGroup(e).then(response=>{
          if(response.status){
              setSelectedGroup(null)
              dispatch(Api.Menu.list())

          }
      })}/>
      <HandbookModal form={"product"}  data={selectedMenu} setSelected={setSelectedMenu} onSave={e=>{
          Api.HandBook.updateMenu(e).then(response=>{
              if(response.status){
                  fetchMenu();
                  setSelectedMenu(null)
              }
          })
      }}/>
      <div style={{"padding":"20px 20px 20px 20px"}}>
            <Box
                direction="row"
                width="100%"
                height={"100%"}>
                <Item
                    ratio={1}
                   >
                    <div className={'dx-card '}>
                    <div style={{"padding":"10px 10px"}}>
                        <strong>{i18n.t("მენიუს ჯგუფები")}</strong>
                    </div>
                    <DataGrid
                        height={`${window.innerHeight - 150}px`}
                        scrolling={{
                            mode:'standard'
                        }}
                        loadPanel={
                            {
                                enabled:true
                            }
                        }
                        selection={{
                            mode:'single'
                        }}
                        onSelectionChanged={({selectedRowsData})=> setSelectedGroupId(selectedRowsData[0]["group_id"])}
                        className={'dx-card wide-card'}
                        hoverStateEnabled={true}
                        dataSource={groups}
                        showBorders={false}
                        focusedRowEnabled={true}
                        //defaultFocusedRowIndex={0}
                        columnAutoWidth={true}
                        columnHidingEnabled={false}
                        keyExpr="id"
                        allowColumnReordering={true}
                        allowColumnResizing={false}

                    >
                        <Paging defaultPageSize={30} />

                        <FilterRow visible={true} />
                        <Pager showPageSizeSelector={true} showInfo={true} />


                        <Column dataField={'group_id'} caption={"#"} alignment={"left"} width={90} hidingPriority={6} allowFiltering={false} />
                        <Column
                            caption={i18n.t("დასახელება პროგრამიდან")}
                            hidingPriority={6}
                            cellRender={(e)=> {
                                return <Translator
                                            id={e.rowIndex}
                                            originalName={e.data.group_name}
                                            translated={e.data.translated}
                                            translatedName={e.data.group_name}
                                            onSuccess={()=>dispatch(Api.Menu.list())
                                            } />
                            }} />}
                        />
                        <Column
                            dataField={'title'}
                            caption={i18n.t("თარგმანი")}
                            hidingPriority={6}
                            cellRender={({data})=>{
                                return <span style={{color: data.translated?'green':'red'}}>{data.translated_name}</span>
                            }}
                        />
                        <Column
                            dataField={'group_status'}
                            caption={i18n.t("სტატუსი GeoRes")}
                            hidingPriority={6}
                        />
                        <Column
                            dataField={'status'}
                            caption={i18n.t("სტატუსი")}
                            hidingPriority={6}
                            cellRender={({data})=>{
                                return <span style={{
                                    color:data?.status===1?'green':'red'
                                }}>{data?.status===1? i18n.t('აქტიური'):i18n.t("პასიური")}</span>
                            }}
                        />
                        <Column
                            dataField={'sort'}
                            caption={i18n.t("რიგითი ნომერი")}
                            hidingPriority={6}
                        />

                        <Column dataField=""
                                cssClass={"custom-button-icon"}
                                width={50}
                                allowSorting={false}
                                cellRender={(e)=>{
                                    return  <Button
                                        disabled={btnLoader === e.key}
                                        icon={btnLoader === e.key? Loader:Edit}
                                        type="normal"
                                        style={{margin: 0, padding: 0,width:"35px",height:"35px"}}
                                        stylingMode="contained"
                                        onClick={() => setSelectedGroup({...e.data,name:e.data.group_name})}
                                    />
                                } }
                        />
                    </DataGrid>
                    </div>
                </Item>
                <Item
                    ratio={1}
                    >
                    <div className={'dx-card '}>
                        <div style={{"padding":"10px 10px",display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                            <strong>{i18n.t("კერძები")}</strong>

                        </div>
                        <DataGrid
                            height={`${window.innerHeight - 150}px`}
                            scrolling={{
                                mode:'standard'
                            }}
                            loadPanel={
                                {
                                    enabled:true
                                }
                            }

                            className={'dx-card wide-card'}
                            dataSource={menus}
                            showBorders={false}
                            focusedRowEnabled={true}
                            //defaultFocusedRowIndex={0}
                            columnAutoWidth={true}
                            columnHidingEnabled={true}
                            keyExpr="id"
                            allowColumnReordering={true}
                            allowColumnResizing={true}

                        >
                            <Paging defaultPageSize={30} />

                            <FilterRow visible={true} />
                            <Pager showPageSizeSelector={true} showInfo={true} />


                            <Column dataField={'product_id'} caption={"#"} alignment={"left"} width={90} hidingPriority={6} allowFiltering={false} />
                            <Column
                                dataField={'product_name'}
                                caption={i18n.t("დასახელება პროგრამიდან")}
                                hidingPriority={6}
                                cellRender={(e)=> {
                                    return <Translator
                                        id={e.rowIndex}
                                        originalName={e.data.product_name}
                                        translated={e.data.translated}
                                        translatedName={e.data.product_name}
                                        onSuccess={()=>fetchMenu()
                                        } />
                                }} />}
                            />
                            <Column
                                dataField={'title'}
                                caption={i18n.t("თარგმანი")}
                                hidingPriority={6}
                                cellRender={({data})=>{
                                     return <span style={{color: data.translated?'green':'red'}}>{data.translated_name}</span>
                                }}
                            />
                            <Column
                                dataField={'product_price'}
                                caption={i18n.t("ფასი")}
                                hidingPriority={6}
                            />
                            <Column
                                dataField={'status'}
                                caption={i18n.t("სტატუსი")}
                                hidingPriority={6}
                                cellRender={({data})=>{
                                    return <span style={{
                                        color:data?.status===1?'green':'red'
                                    }}>{data?.status===1? i18n.t('აქტიური'):i18n.t("პასიური")}</span>
                                }}
                            />
                            <Column
                                dataField={'sort'}
                                caption={i18n.t("რიგითი ნომერი")}
                                hidingPriority={6}
                            />

                            <Column dataField=""
                                    cssClass={"custom-button-icon"}
                                    width={50}
                                    allowSorting={false}
                                    cellRender={(e)=>{
                                        return  <Button
                                            disabled={btnLoader === e.key}
                                            icon={btnLoader === e.key? Loader:Edit}
                                            type="normal"
                                            style={{margin: 0, padding: 0,width:"35px",height:"35px"}}
                                            stylingMode="contained"
                                            onClick={() => setSelectedMenu({...e.data,name:e.data.product_name})}
                                        />
                                    } }
                            />

                        </DataGrid>
                    </div>
                </Item>


            </Box>
      </div>
    </React.Fragment>
)}
