import React, {useCallback, useEffect, useState} from 'react';
import 'devextreme/data/odata/store';
import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
} from 'devextreme-react/data-grid';
import {Api, i18n} from "../../core";
import {useDispatch, useSelector} from "react-redux";
import {Box, Button} from "devextreme-react";
import {Edit, Loader} from "../../assets/icons/assets";
import {UserDetails} from "../../components";
import {Item} from "devextreme-react/box";

export default function Roles() {
  const dispatch = useDispatch();
  const roles = useSelector(state=>state.Roles)
  const [btnLoader,setBtnLoader]=useState(-1)
  const [selectedRole,setSelectedRole]=useState(null)
  const [actions,setActions]= useState([])
  const [pages,setPages]= useState([])
  useEffect(()=>{
        dispatch(Api.Role.list())
        i18n.on("languageChanged",e=> {
            Api.Settings.ChangeLanguage(e).then(e=> {
                dispatch(Api.Role.list())
            })
        })
    return () =>{
        i18n.off("languageChanged",e=>{

        })
    }

  },[])
  useEffect(()=>{
        if(selectedRole){
            Api.Role.getActionsByRole({role_id:selectedRole?.id})
                .then(response=>{
                    setActions(response.status?response.data:[])
                })
            Api.Role.getPagesByRole({role_id:selectedRole?.id})
                .then(response=>{
                    setPages(response.status?response.data:[])
                })
        }
    },[selectedRole])

  const onSelectionChanged=(role_id)=>{

  }

  return (
    <React.Fragment>
      <div style={{"padding":"0 20px 20px 20px"}}>
            <Box
                direction="row"
                width="100%"
                height={"100%"}>
                <Item
                    ratio={0}
                    baseSize={300}>
                    <div className={'dx-card '}>
                    <div style={{"padding":"10px 10px"}}>
                        <strong>{i18n.t("როლები")}</strong>
                    </div>
                    <DataGrid
                        height={`${window.innerHeight - 150}px`}
                        scrolling={{
                            mode:'standard'
                        }}
                        loadPanel={
                            {
                                enabled:true
                            }
                        }
                        selection={{
                            mode:'single'
                        }}
                        onSelectionChanged={({selectedRowsData})=> setSelectedRole(selectedRowsData[0])}
                        className={'dx-card wide-card'}
                        hoverStateEnabled={true}
                        dataSource={roles}
                        showBorders={false}
                        focusedRowEnabled={true}
                        //defaultFocusedRowIndex={0}
                        columnAutoWidth={true}
                        columnHidingEnabled={true}
                        keyExpr="id"
                        allowColumnReordering={true}
                        allowColumnResizing={true}

                    >
                        <Paging defaultPageSize={20} />
{/*
                        <FilterRow visible={true} />
*/}

                        <Column dataField={'id'} caption={"#"} alignment={"left"} width={90} hidingPriority={2} allowFiltering={false} />
                        <Column
                            dataField={'title'}
                            caption={i18n.t("დასახელება")}
                            hidingPriority={6}
                        />


                        <Column dataField=""
                                cssClass={"custom-button-icon"}
                                width={50}
                                allowSorting={false}
                                cellRender={(e)=>{
                                    return  <Button
                                        disabled={btnLoader === e.key}
                                        icon={btnLoader === e.key? Loader:Edit}
                                        type="normal"
                                        style={{margin: 0, padding: 0,width:"35px",height:"35px"}}
                                        stylingMode="contained"
                                        onClick={() => onSelectionChanged(e)}
                                    />
                                } }
                        />
                    </DataGrid>
                    </div>
                </Item>
                <Item
                    ratio={1}
                    >
                    <div className={'dx-card '}>
                        <div style={{"padding":"10px 10px"}}>
                            <strong>{i18n.t("უფლებები")}</strong>
                        </div>
                        <DataGrid
                            height={`${window.innerHeight - 150}px`}
                            scrolling={{
                                mode:'standard'
                            }}
                            loadPanel={
                                {
                                    enabled:true
                                }
                            }

                            className={'dx-card wide-card'}
                            dataSource={actions}
                            showBorders={false}
                            focusedRowEnabled={true}
                            //defaultFocusedRowIndex={0}
                            columnAutoWidth={true}
                            columnHidingEnabled={true}
                            keyExpr="action_id"
                            allowColumnReordering={true}
                            allowColumnResizing={true}

                        >
                            <Paging defaultPageSize={20} />
{/*
                            <FilterRow visible={true} />
*/}

                            <Column dataField={'action_id'} caption={"#"} alignment={"left"} width={90} hidingPriority={2} allowFiltering={false} />
                            <Column
                                dataField={'action_title'}
                                caption={i18n.t("დასახელება")}
                                hidingPriority={6}
                            />


                            <Column dataField=""
                                    cssClass={"custom-button-icon"}
                                    width={50}
                                    allowSorting={false}
                                    cellRender={(e)=>{
                                        return  <Button
                                            disabled={btnLoader === e.key}
                                            icon={btnLoader === e.key? Loader:Edit}
                                            type="normal"
                                            style={{margin: 0, padding: 0,width:"35px",height:"35px"}}
                                            stylingMode="contained"
                                            onClick={() => onSelectionChanged(e)}
                                        />
                                    } }
                            />
                        </DataGrid>
                    </div>
                </Item>
                <Item
                    ratio={1}
                   >
                    <div className={'dx-card '}>
                        <div style={{"padding":"10px 10px"}}>
                            <strong>{i18n.t("გვერდები")}</strong>
                        </div>
                        <DataGrid
                            height={`${window.innerHeight - 150}px`}
                            scrolling={{
                                mode:'standard'
                            }}
                            className={'dx-card wide-card'}
                            dataSource={pages}
                            showBorders={false}
                            focusedRowEnabled={true}
                            //defaultFocusedRowIndex={0}
                            columnAutoWidth={true}
                            columnHidingEnabled={true}
                            keyExpr="id"
                            allowColumnReordering={true}
                            allowColumnResizing={true}

                        >
                            <Paging defaultPageSize={20} />
                            <Pager showPageSizeSelector={true} showInfo={true} />
{/*
                            <FilterRow visible={true} />
*/}

                            <Column dataField={'id'} caption={"#"} alignment={"left"} width={90} hidingPriority={2} allowFiltering={false} />
                            <Column
                                dataField={'text'}
                                caption={i18n.t("დასახელება")}
                                hidingPriority={6}
                            />
                            <Column
                                dataField={'path'}
                                caption={i18n.t("ბმული")}
                                hidingPriority={6}
                            />
                            <Column
                                dataField={'icon'}
                                caption={i18n.t("აიქონი")}
                                hidingPriority={6}
                            />
                            <Column
                                dataField={'sort'}
                                caption={i18n.t("რიგითობა")}
                                hidingPriority={6}
                            />
                            <Column dataField=""
                                    cssClass={"custom-button-icon"}
                                    width={50}
                                    allowSorting={false}
                                    cellRender={(e)=>{
                                        return  <Button
                                            disabled={btnLoader === e.key}
                                            icon={btnLoader === e.key? Loader:Edit}
                                            type="normal"
                                            style={{margin: 0, padding: 0,width:"35px",height:"35px"}}
                                            stylingMode="contained"
                                            onClick={() => onSelectionChanged(e)}
                                        />
                                    } }
                            />
                        </DataGrid>
                    </div>
                </Item>

            </Box>
      </div>
    </React.Fragment>
)}
