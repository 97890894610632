import { createSlice} from '@reduxjs/toolkit'
const initialState = []

const usersSlice = createSlice({
    name: 'Users',
    initialState,
    reducers: {
        getUserList:(state,action)=>{
           return action.payload;
        }
    }

})
export const { getUserList } = usersSlice.actions

export default usersSlice.reducer
