import React, {useCallback, useEffect, useState} from 'react';
import 'devextreme/data/odata/store';
import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
} from 'devextreme-react/data-grid';
import {Api, i18n} from "../../../core";
import {useDispatch, useSelector} from "react-redux";
import {Box, Button} from "devextreme-react";
import {Edit, Loader} from "../../../assets/icons/assets";
import {Item} from "devextreme-react/box";
import HandbookModal from "../../../components/handbook/handBookModal/handbookModal";
import {Translator} from "../../../components";

export default function Sections() {
  const dispatch = useDispatch();
  const sections = useSelector(state=>state.HandBook.sectionsList)
  const [tables,setTables]= useState([])
  const [btnLoader,setBtnLoader]=useState(-1)
  const [selectedSectionId,setSelectedSectionId]=useState(null)
  const [section,setSection] = useState(null)
  const [table,setTable]=useState(null)
  useEffect(()=>{
    dispatch(Api.HandBook.list())
  },[])
  useEffect(()=>{
      fetchTables()
      i18n.on('languageChanged',e=>{
          Api.Settings.ChangeLanguage(e).then(e=> {
              fetchTables()
          })
      })
      return () =>{
          i18n.off("languageChanged",e=>{

          })
      }
    },[selectedSectionId])

 const fetchTables = ()=>{

     setTables([])
     if(selectedSectionId){
         Api.HandBook.tablesListById({section_id:selectedSectionId})
             .then(response=>{
                 setTables(response.status?response.data:[])
             })
     }
 }

  return (
    <React.Fragment>
        <HandbookModal   data={section} setSelected={setSection} onSave={e=>Api.HandBook.updateSection(e).then(response=>{
            if(response.status){
                setSection(null)
                dispatch(Api.HandBook.list())

            }
        })}/>
        <HandbookModal   data={table} setSelected={setTable} onSave={e=>Api.HandBook.updateTable(e).then(response=>{
            if(response.status){
                setTable(null)
              fetchTables()

            }
        })}/>
      <div style={{"padding":"20px 20px 20px 20px"}}>
            <Box
                direction="row"
                width="100%"
                height={"100%"}>
                <Item
                    ratio={1}
                   >
                    <div className={'dx-card '}>
                    <div style={{"padding":"10px 10px"}}>
                        <strong>{i18n.t("სექციები")}</strong>
                    </div>
                    <DataGrid
                        height={`${window.innerHeight - 150}px`}
                        scrolling={{
                            mode:'standard'
                        }}
                        loadPanel={
                            {
                                enabled:true
                            }
                        }
                        selection={{
                            mode:'single'
                        }}
                        onSelectionChanged={({selectedRowsData})=> setSelectedSectionId(selectedRowsData[0]["section_id"])}
                        className={'dx-card wide-card'}
                        hoverStateEnabled={true}
                        dataSource={sections}
                        showBorders={false}
                        focusedRowEnabled={true}
                        //defaultFocusedRowIndex={0}
                        columnAutoWidth={true}
                        columnHidingEnabled={true}
                        keyExpr="id"
                        allowColumnReordering={true}
                        allowColumnResizing={true}

                    >
                        <Paging defaultPageSize={30} />

                        <FilterRow visible={true} />
                        <Pager showPageSizeSelector={true} showInfo={true} />


                        <Column dataField={'section_id'} caption={"#"} alignment={"left"} width={90} hidingPriority={2} allowFiltering={false} />
                        <Column
                            dataField={'section_name'}
                            caption={i18n.t("დასახელება პროგრამიდან")}
                            hidingPriority={6}
                            cellRender={(e)=> {
                                return <Translator
                                    id={e.rowIndex}
                                    originalName={e.data.section_name}
                                    translated={e.data.translated}
                                    translatedName={e.data.section_name}
                                    onSuccess={()=>dispatch(Api.HandBook.list()) } />
                            }} />}
                        />

                        <Column
                            dataField={'title'}
                            caption={i18n.t("თარგმანი")}
                            hidingPriority={6}
                            cellRender={({data})=>{
                                return <span style={{color: data.translated?'green':'red'}}>{data.translated_name}</span>
                            }}
                        />
                        <Column
                            dataField={'section_order'}
                            caption={i18n.t("რიგითი ნომერი")}
                            hidingPriority={6}
                        />
                        <Column
                            dataField={'status'}
                            caption={i18n.t("სტატუსი")}
                            hidingPriority={6}
                        />

                        <Column dataField=""
                                cssClass={"custom-button-icon"}
                                width={50}
                                allowSorting={false}
                                cellRender={(e)=>{
                                    return  <Button
                                        disabled={btnLoader === e.key}
                                        icon={btnLoader === e.key? Loader:Edit}
                                        type="normal"
                                        style={{margin: 0, padding: 0,width:"35px",height:"35px"}}
                                        stylingMode="contained"
                                        onClick={() => setSection({...e.data,name:e.data.section_name})}
                                    />
                                } }
                        />
                    </DataGrid>
                    </div>
                </Item>
                <Item
                    ratio={1}
                    >
                    <div className={'dx-card '}>
                        <div style={{"padding":"10px 10px"}}>
                            <strong>{i18n.t("მაგიდები")}</strong>
                        </div>
                        <DataGrid
                            height={`${window.innerHeight - 150}px`}
                            scrolling={{
                                mode:'standard'
                            }}
                            loadPanel={
                                {
                                    enabled:true
                                }
                            }

                            className={'dx-card wide-card'}
                            dataSource={tables}
                            showBorders={false}
                            focusedRowEnabled={true}
                            //defaultFocusedRowIndex={0}
                            columnAutoWidth={true}
                            columnHidingEnabled={true}
                            keyExpr="table_id"
                            allowColumnReordering={true}
                            allowColumnResizing={true}

                        >
                            <Paging defaultPageSize={30} />

                            <FilterRow visible={true} />
                            <Pager showPageSizeSelector={true} showInfo={true} />


                            <Column dataField={'table_id'} caption={"#"} alignment={"left"} width={90} hidingPriority={2} allowFiltering={false} />
                            <Column
                                dataField={'table_name'}
                                caption={i18n.t("დასახელება პროგრამიდან")}
                                hidingPriority={6}
                                cellRender={(e)=> {
                                    return <Translator
                                        id={e.rowIndex}
                                        originalName={e.data.table_name}
                                        translated={e.data.translated}
                                        translatedName={e.data.table_name}
                                        onSuccess={()=> fetchTables()} />
                                }} />}
                            />
                            <Column
                                dataField={'title'}
                                caption={i18n.t("თარგმანი")}
                                hidingPriority={6}
                                cellRender={({data})=>{
                                    return <span style={{color: data.translated?'green':'red'}}>{data.translated_name}</span>
                                }}
                            />
                            <Column
                                dataField={'status'}
                                caption={i18n.t("სტატუსი")}
                                hidingPriority={6}
                            />
                            <Column
                                dataField={'sort'}
                                caption={i18n.t("რიგითი ნომერი")}
                                hidingPriority={6}
                            />

                            <Column dataField=""
                                    cssClass={"custom-button-icon"}
                                    width={50}
                                    allowSorting={false}
                                    cellRender={(e)=>{
                                        return  <Button
                                            disabled={btnLoader === e.key}
                                            icon={btnLoader === e.key? Loader:Edit}
                                            type="normal"
                                            style={{margin: 0, padding: 0,width:"35px",height:"35px"}}
                                            stylingMode="contained"
                                            onClick={() => setTable({...e.data,name:e.data.table_name})}
                                        />
                                    } }
                            />
                        </DataGrid>
                    </div>
                </Item>


            </Box>
      </div>
    </React.Fragment>
)}
