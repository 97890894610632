import React, {useEffect, useState} from 'react';
import 'devextreme/data/odata/store';
import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  Lookup, Editing
} from 'devextreme-react/data-grid';
import {Api, i18n} from "../../core";
import {useDispatch, useSelector} from "react-redux";

import {UseConfirmation} from "../../core/hooks/useConfirmation";
import {Button} from "devextreme-react"
import {CLoseIcon, Edit, Loader, PlusIcon, UserGroup} from "../../assets/icons/assets"
import notify from "devextreme/ui/notify"
import UserNotificationModal from "../../components/notifications/userNotifications"

export default function PushNotifications() {
  const dispatch = useDispatch();
  const confirmation = UseConfirmation();
  const users = useSelector(state=>state.Users)
  const [btnLoader,setBtnLoader]=useState(-1)
  const [selected,setSelected]=useState(null)
  const [groupNotifications,setGroupNotification]=useState({
    title:"",
    subTitle:"",
    users:[],
    modal:false
  })
  const [data,setData]=useState([])
  useEffect(()=>{
    getTransactions()
    i18n.on("languageChanged",e=> {
      Api.Settings.ChangeLanguage(e).then(e=> {
        getTransactions()
      })
    })
    return () =>{
      i18n.off("languageChanged",e=>{

      })
    }
    },[])
  const getTransactions = ()=>{
    Api.Notification.pushNotificationlist().then(response=>{
      if(response?.status){
        setData(response.data)
      }
    })
  }


  return (
    <React.Fragment>

      <UserNotificationModal notifications={groupNotifications} setNotifications={setGroupNotification} onSuccess={e=>{


        Api.Notification.sendGroupPushNotification({title:e.title,msg:e.subTitle,users:e.users}).then(response=>{
          notify(response.data,response.status?'success':'error')
        /*  if(response.status){
            confirmation.close()
          }
*/
          getTransactions();
          setGroupNotification({
            title:"",
            subTitle:"",
            users:[],
            modal:false
          })
        })
      }
      }/>
      <div style={{"padding":"20px",display:'flex',flexDirection:"row",justifyContent:"space-between",}}>
         <strong>{i18n.t("ნოტიფიკაციები")}</strong>
        <div>
          <Button
              icon={PlusIcon}
              type="normal"
              style={{marginRight: 30, padding: 0,width:"35px",height:"35px"}}
              stylingMode="contained"
              onClick={() => {
                confirmation.open({
                  type:"success",
                  title:"ნოტიფიკაცია",
                  subtitle:true,
                  notes:true,
                  noteTitle:true,
                  noteTitleLabel:"სათაური",
                  onYes:(note,details,title)=>{
                    Api.Notification.sendPushNotification({title:title,subtitle:note}).then(response=>{
                      notify(response.data,response.status?'success':'error')
                      if(response.status){
                        getTransactions()
                        confirmation.close()
                      }

                    })
                  },
                  onNo:()=>confirmation.close()
                })
              }}
          />

          <Button
              icon={UserGroup}
              type="normal"
              style={{marginRight: 30, padding: 0,width:"35px",height:"35px"}}
              stylingMode="contained"
              onClick={() => {
                setGroupNotification({
                  title:"",
                  subTitle:"",
                  users:[],
                  modal:true
                })
              }}
          />
        </div>
      </div>
      <div style={{"padding":"0 20px 20px 20px"}}>
        <div className={'dx-card '}>
          <DataGrid
              height={`${window.innerHeight - 150}px`}
              scrolling={{
                mode:'standard'
              }}
              className={'dx-card wide-card'}
              dataSource={data}
              showBorders={false}
              focusedRowEnabled={true}
              defaultFocusedRowIndex={0}
              columnAutoWidth={true}
              columnHidingEnabled={true}
              keyExpr="id"
              allowColumnReordering={true}
              allowColumnResizing={true}
          >
            <Paging defaultPageSize={20} />
            <Pager showPageSizeSelector={true} showInfo={true} />
            <FilterRow visible={true} />

            <Column dataField={'id'}  alignment={"center"} width={90} hidingPriority={6} allowFiltering={false} />
            <Column
                alignment={"center"}
                dataField={'title'}
                caption={i18n.t("სათაური")}
                hidingPriority={7}
            />
            <Column
                alignment={"center"}
                dataField={'subtitle'}
                caption={i18n.t("ქვესათაური")}
                hidingPriority={7}
            />
            <Column
                alignment={"center"}
                dataField={'body'}
                caption={i18n.t("ტექსტი")}
                hidingPriority={3}
            />


            <Column
                alignment={"center"}
                dataField={'timestamp'}
                caption={i18n.t("გაგზავნის დრო")}
                hidingPriority={3}
            />
            <Column
                alignment={"center"}
                dataField={'timestamp'}
                caption={i18n.t("დასრულების დრო")}
                hidingPriority={3}
            />
            <Column
                alignment={"center"}
                dataField={'status'}
                caption={i18n.t("სტატუსი")}
                hidingPriority={3}
                cellComponent={e=>{

                  switch (e.data.data?.status){
                    case 3: return "გაუქმებული";
                    case 2: return "გაგზავნილი";
                    case 1: return "იგზავნება";
                    default: return 'გასაგზავნი'
                  }
                }}
            />
            <Column
                alignment={"center"}
                dataField={'type'}
                caption={i18n.t("ტიპი")}
                hidingPriority={3}

            />
            <Column
                alignment={"center"}
                dataField={'user_id'}
                caption={i18n.t("მომხმარებელი")}
                hidingPriority={3}

            />
            <Column dataField=""  cssClass={"custom-button-icon"}
                    width={50}
                    allowSorting={false}
                    cellRender={(e)=>{

                      return (e.data.status===0) && <Button
                          disabled={btnLoader === e.key}
                          icon={btnLoader === e.key? Loader:CLoseIcon}
                          type="normal"
                          style={{margin: 0, padding: 0,width:"35px",height:"35px"}}
                          stylingMode="contained"
                          onClick={() =>{

                            confirmation.open({
                              title:i18n.t("ნოტიფიკაციის წაშლა"),
                              type:'danger',
                              subtitle:i18n.t(`დარწმუნებული ხართ, რომ გსურთ ნოტიფიკაციის  გაუქმება?`),
                              notes:false,
                              icon:"times-circle",
                              //iconColor:'red',
                              //subtitleColor:'red',
                              onYes:(note)=>{

                                Api.Notification.cancel({
                                 id:e.data.id,
                                  loader:e=>{
                                    setBtnLoader(e?e.key:null)
                                  }
                                }).then(response=>{
                                  notify(response.data,response.status?'success':'error',3000);
                                  if(response.status){
                                    confirmation.close();
                                    getTransactions()

                                  }
                                })

                              },
                              onNo:()=>{
                                confirmation.close();
                              }
                            })
                          }}
                      />
                    } }
            />
          </DataGrid>
        </div>
      </div>
    </React.Fragment>
)}
