import axios from 'axios';
import {UseEvent} from "../hooks/useEvent";

import Token from "../models/Access";
import notify from "devextreme/ui/notify";
import {i18n} from "../i18n/i18n";
const http =  axios.create({
    baseURL: '/',
    timeout:10000
})
const loaders = UseEvent();
const token  = new Token()
class Http {

    static get({url,loader,headers}){
        return new Promise((resolve) => {
            if (loader) this.setLoader(loader, true);
            url = url.replace("{lang}",localStorage.getItem("lang")||'ka')
            http.get(url,{headers: {
                    'Authorization': `bearer ${token.access}`
                }}).then(response=>{
                resolve(response.status===200?{status:true,data:response.data}:{status:false,data:response.data})
            }).catch(reason => {
                resolve({ status:false , data:reason?.response?.data})
                if(reason?.response?.data){
                    notify(i18n.t(reason.response.data),"error",3000)
                }
            }).finally(()=>{
                if (loader) this.setLoader(loader, false);
            })
        })

    }
    static post({url,data,loader,headers}){
        return  new Promise(resolve => {
            if (loader) this.setLoader(loader, true);
            url = url.replace("{lang}",localStorage.getItem("lang")||'ka')

            http.post(url,data,{headers: {
                    'Authorization': `bearer ${token.access}`
                }}).then(response=>{
                resolve(response.status===200?{status:true,data:response.data}:{status:false,data:response.data})
            }).catch(reason =>{
                    resolve({ status:false , data:JSON.stringify(reason.response)})
                if(reason?.response?.data){
                    notify(i18n.t(reason.response.data),"error",3000)
                }
            }).finally(()=>{
                if (loader) this.setLoader(loader, false);
            })
        })

    }
    static put({url,data,loader,headers}){
        return  new Promise(resolve => {
            if (loader) this.setLoader(loader, true);
            url = url.replace("{lang}",localStorage.getItem("lang")||'ka')

            http.put(url,data,{headers: {
                    'Authorization': `bearer ${token.access}`
                }}).then(response=>{
                resolve(response.status===200?{status:true,data:response.data}:{status:false,data:response.data})
            }).catch(reason =>{
                resolve({ status:false , data:JSON.stringify(reason.response)})
                if(reason?.response?.data){
                    notify(i18n.t(reason.response.data),"error",3000)

                }
            }).finally(()=>{
                if (loader) this.setLoader(loader, false);
            })
        })

    }
    static setLoader(loader, status) {
        if (typeof loader === "string") {
            loaders.emit(loader, status);
        } else {
            try {
                loader(status);
            } catch (e) {
                console.info(e.message);
            }
        }
    }
}
export default Http;
