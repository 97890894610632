import React, {useEffect, useState} from 'react';
import 'devextreme/data/odata/store';
import "./orders.scss"
import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
} from 'devextreme-react/data-grid';
import {Api, i18n, useTranslation} from "../../core";
import {Button, Popup} from "devextreme-react";
import {Edit, Loader, CLoseIcon, UserCheck, PlusIcon, UpdateIcon} from "../../assets/icons/assets";
import {UserDetails} from "../../components";
import {UseConfirmation} from "../../core/hooks/useConfirmation";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import notify from "devextreme/ui/notify";
import {faInfo} from "@fortawesome/fontawesome-free-solid";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale } from "react-datepicker";
import ka from "date-fns/locale/ka";

import OrderModal from "../../components/users/orderModal"

registerLocale("ka", ka);

export default function Reservations() {
  const {t} = useTranslation();
  const confirmation = UseConfirmation();
  const [btnLoader,setBtnLoader]=useState(-1)
  const [selected,setSelected]=useState(null)
  const [data,setData]=useState([])
  const [details,setDetails]=useState(null)
  const [spaces,setSpaces]=useState([])
    const [allProducts,setAllProducts] = useState([])


  useEffect(()=>{
    getTransactions();
      i18n.on("languageChanged",e=> {
          Api.Settings.ChangeLanguage(e).then(e=> {
              getTransactions()
          })
      })

      getAllProducts();

      getSpaces();
      return () =>{
          i18n.off("languageChanged",e=>{

          })
      }},[])

    const getAllProducts=()=>{
        Api.Menu.getAllProducts()
            .then((response)=>{
                if(response.status){
                    setAllProducts(response.data)
                }
            })
    }
    const getSpaces = () => {
        Api.HandBook.getSpaces().then(response=>{
            setSpaces(response.status?response.data:[])
        })
    }
  const getTransactions = ()=>{
    Api.Reservation.list().then(response=>{
      if(response?.status){
        setData(response.data)
      }
    })
  }
  const onSelectionChanged=(e)=>{
    let data =e.data;
    Api.Staff.getDetails({id:data.id,loader:(e)=>{
        setBtnLoader(e?e.key:null)
    }}).then(response=>{
        setSelected(response.status?response.data:null)
    })

  }


  const updateReservation=()=>{
      Api.Reservation.updateReservation({data:{
              type:details.type,
              order_date:details.order.order_date,
              space_id:details.order.space_id,
              order_time:details.order.order_time,
              guestQty:details.order.guestQty,
              cart:details?.details||[],
              orderId:details.order.id,
              user_id:details.order.user_id,
              comment:details.order.comment
          }})
          .then((response)=>{
                if(response.status){
                    getTransactions();
                    setDetails(null)
                }
              notify(response.data,response.status?'success':'error',3000);

          })
  }



  const detailsModal = () =>{
      return <OrderModal details={details} setDetails={(e)=>{
          if(e===null){
              getTransactions()
          }
          setDetails(e)
      }}/>
  }
  return (
    <React.Fragment>
        {detailsModal()}

      <UserDetails data={selected} onSelectionChanged={onSelectionChanged} setSelected={setSelected}/>
      <div style={{"padding":"20px"}}>
         <strong>{i18n.t("ჯავშნები")}</strong>
      </div>
      <div style={{"padding":"0 20px 20px 20px"}}>
        <div className={'dx-card '}>
          <DataGrid
              height={`${window.innerHeight - 150}px`}
              scrolling={{
                  mode:'standard'
              }}
              className={'dx-card wide-card'}
              dataSource={data}
              showBorders={false}
              focusedRowEnabled={true}
              defaultFocusedRowIndex={0}
              columnAutoWidth={true}
              columnHidingEnabled={true}
              keyExpr="id"
              allowColumnReordering={true}
              allowColumnResizing={true}

          >
            <Paging defaultPageSize={20} />
            <Pager showPageSizeSelector={true} showInfo={true} />
            <FilterRow visible={true} />

            <Column dataField={'id'}  alignment={"center"} width={90} hidingPriority={6} allowFiltering={false} />
            <Column
                alignment={"center"}
                dataField={'status'}
                caption={i18n.t("სტატუსი")}
                hidingPriority={7}
                cellRender={e=>{
                      if(e.data.status ===-1 ){
                          return <span style={{color:'#ff5722'}} className={"blink_me"}>{i18n.t("დასადასტურებელი")}</span>
                      }else if(e.data.status ===1){
                        return <span style={{color:'green'}}>{i18n.t("დადასტურებული")}</span>

                      }else if(e.data.status ===2){
                        return <span style={{color:'red'}}>{i18n.t("გაუქმებული კლიენტის მიერ")}</span>

                      }else if(e.data.status ===3){
                        return <span style={{color:'red'}}>{i18n.t("გაუქმებული მენეჯერის მიერ")}</span>
                      }else if(e.data.status ===4){
                          return <span style={{color:'#850000'}}>{i18n.t("გაუქმებული სისტემის  მიერ")}</span>
                      }
                }}
            />
              <Column
                  alignment={"center"}
                  dataField={'timestamp'}
                  caption={i18n.t("დრო")}
                  allowSorting={false}
                  hidingPriority={7}
              />
            <Column
                alignment={"center"}
                dataField={'type'}
                caption={i18n.t("ჯავშნის დრო")}
                hidingPriority={7}
                cellRender={({data})=>{
                   return  <span>{data.order_date.concat(" ",data.order_time)}</span>
                }}
            />
            <Column
                alignment={"center"}
                dataField={'place'}
                caption={i18n.t("ადგილი")}
                allowSorting={false}
                hidingPriority={7}
            />
              <Column
                  alignment={"center"}
                  dataField={'guestQty'}
                  caption={i18n.t("სტ. რაოდ")}
                  hidingPriority={3}
              />
            <Column
                alignment={"center"}
                dataField={'fullName'}
                caption={i18n.t("მომხმარებელი")}
                hidingPriority={3}
            />
              <Column
                  alignment={"center"}
                  dataField={'idNumber'}
                  caption={i18n.t("პირადი ნომერი")}
                  hidingPriority={3}
              />
            <Column
                alignment={"center"}
                dataField={'phone'}
                caption={i18n.t("ტელეფონი")}
                hidingPriority={3}
            />
            <Column
                alignment={"center"}
                dataField={'adminFullName'}
                caption={i18n.t("მენეჯერი")}
                hidingPriority={3}
            />
            <Column
                alignment={"center"}
                dataField={'accept_date'}
                caption={i18n.t("დასტურის დრო")}
                hidingPriority={3}
            />



            <Column dataField=""
                    cssClass={"custom-button-icon"}
                    width={50}
                    allowSorting={false}
                    cellRender={(e)=>{
                      return [-1].indexOf(e.data.status)>-1 &&  e.data.canChange===1 &&<Button
                                disabled={btnLoader === e.key}
                                type="normal"
                                style={{margin: 0, padding: 0,width:"35px",height:"35px",borderRadius:10}}
                                stylingMode="contained"
                                onClick={() => {
                                    confirmation.open({
                                        title:i18n.t("ჯავშნის დადასტურება"),
                                        type:'danger',
                                        subtitle:i18n.t(`დარწმუნებული ხართ, რომ გსურთ ჯავშნის დადასტურება?`),
                                        notes:false,
                                        icon:"times-circle",
                                        checkbox:true,
                                        checkboxText:t('გაიგზავნოს სმს შეტყობინება'),
                                        checkboxValue:true,
                                        //iconColor:'red',
                                        //subtitleColor:'red',
                                        onYes:(note,details)=>{
                                            Api.Reservation.changeStatus({
                                                data:{
                                                    id:e.data.id,
                                                    status:1,
                                                    phone:e.data.phone,
                                                    order_date:e.data.order_date,
                                                    order_time:e.data.order_time,
                                                    user_id:e.data.user_id,
                                                    sms:details?.checkboxValue?'yes':'no'

                                                },
                                                loader:e=>{
                                                    setBtnLoader(e?e.key:null)
                                                }
                                            }).then(response=>{
                                                notify(response.data,response.status?'success':'error',3000);
                                                if(response.status){
                                                    confirmation.close();
                                                    getTransactions()

                                                }
                                            })

                                        },
                                        onNo:()=>{
                                            confirmation.close();
                                        }
                                    })

                                }}
                            >
                           <FontAwesomeIcon icon={faCheck}  />

                      </Button>
                    } }
            />
              <Column dataField=""
                    cssClass={"custom-button-icon"}
                    width={50}
                    allowSorting={false}
                    cellRender={(e)=>{


                      return [-1,1].indexOf(e.data.status)>-1 && e.data.canChange===1 && <Button
                                disabled={btnLoader === e.key}
                                icon={btnLoader === e.key? Loader:CLoseIcon}
                                type="normal"
                                style={{margin: 0, padding: 0,width:"35px",height:"35px"}}
                                stylingMode="contained"
                                onClick={() =>{

                                    confirmation.open({
                                        title:i18n.t("ჯავშნის გაუქმება"),
                                        type:'danger',
                                        subtitle:i18n.t(`დარწმუნებული ხართ, რომ გსურთ ჯავშნის გაუქმება?`),
                                        notes:false,
                                        icon:"times-circle",
                                        checkbox:true,
                                        checkboxText:t('გაიგზავნოს სმს შეტყობინება'),
                                        checkboxValue:true,
                                        //iconColor:'red',
                                        //subtitleColor:'red',
                                        onYes:(note,details)=>{
                                            Api.Reservation.changeStatus({
                                                data:{
                                                    id:e.data.id,
                                                    status:3,
                                                    phone:e.data.phone,
                                                    order_date:e.data.order_date,
                                                    order_time:e.data.order_time,
                                                    user_id:e.data.user_id,
                                                    sms:details?.checkboxValue?'yes':'no'
                                                },
                                                loader:e=>{
                                                    setBtnLoader(e?e.key:null)
                                                }
                                            }).then(response=>{
                                                notify(response.data,response.status?'success':'error',3000);
                                                if(response.status){
                                                    confirmation.close();
                                                    getTransactions()

                                                }
                                            })

                                        },
                                        onNo:()=>{
                                            confirmation.close();
                                        }
                                    })
                                }}
                            />
                    } }
            />

              <Column dataField=""
                      cssClass={"custom-button-icon"}
                      width={50}
                      allowSorting={false}

                      cellRender={(e)=>{
                          return <Button
                              disabled={btnLoader === e.key}
                              type="normal"
                              style={{margin: 0, padding: 0,width:"35px",height:"35px",borderRadius:10}}
                              stylingMode="contained"
                              onClick={() => {
                                  Api.Reservation.Details(e.data.id).then(response=>{
                                        if(response.status){
                                            setDetails({
                                                type:'info',
                                                comment:e.data.comment,
                                                details:response.data,
                                                order:e.data
                                            })
                                        }else{
                                            setDetails({
                                                type:'info',
                                                comment:e.data.comment,
                                                details:[],
                                                order:e.data
                                            })
                                        }
                                  })

                              }}
                          >
                              <FontAwesomeIcon icon={faInfo}  />

                          </Button>
                      } }
              />


          </DataGrid>
        </div>
      </div>
    </React.Fragment>
)}
