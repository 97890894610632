import http from "../http/http";
import Config from "../config/config.json"



const list = ()=>{
    return http.get({
        url: Config.TRANSACTION.LIST
    })

}
const changeStatus=({data,loader})=>{
    return http.post({
        url:Config.TRANSACTION.CHANGE_STATUS,
        data:data,
        loader:loader
    })
}
const details=({id,loader})=>{
    return http.get({url:Config.TRANSACTION.DETAILS.replace("{trans_id}",id),loader:loader})
}

const cashback=({data,loader})=>{
    return http.post({url:Config.TRANSACTION.CASHBACK,loader:loader,data})

}

const accept_transaction = ({pay_id,cashback,loader}) => {
    return http.get({
        url:Config.TRANSACTION.ACCEPT_TRANSACTION.replace("{payId}",pay_id).replace("{cashback}",cashback),
        loader:loader
    })
}
const getOrderDetails = ({loader,orderNumber,mode}) =>{
    return http.get({url:Config.ORDER.CHECK_ORDER.replace("{orderNumber}",orderNumber).replace("{mode}",mode),loader:loader})
}

export  {
    list,
    changeStatus,
    details,
    cashback,
    accept_transaction,
    getOrderDetails
}
