import React, {useEffect, useState} from 'react';
import 'devextreme/data/odata/store';
import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  Lookup, Editing
} from 'devextreme-react/data-grid';
import {Api, i18n} from "../../core";
import {useDispatch, useSelector} from "react-redux";
import {Button} from "devextreme-react";
import {CLoseIcon, CheckIcon, Loader} from "../../assets/icons/assets";
import {UserDetails} from "../../components";
import notify from "devextreme/ui/notify";
import {UseConfirmation} from "../../core/hooks/useConfirmation";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfo} from "@fortawesome/fontawesome-free-solid";
import {faUser} from "@fortawesome/free-solid-svg-icons";
import TransactionDetails from "../../components/user-form/transactionDetails"
import {Form} from 'react-bootstrap'
import moment from "moment"
import DatePicker from "react-datepicker";

export default function Transactions() {
  const confirmation = UseConfirmation();
  const [dateFrom,setDateFrom]=useState(new Date())
  const [dateTo,setDateTo]=useState(new Date())
  const [btnLoader,setBtnLoader]=useState(-1)
  const [selectedUser,setSelectedUser]=useState(null)
  const [data,setData]=useState([])
  const [details,setDetails]=useState(null)
  useEffect(()=>{
    getTransactions()
    i18n.on("languageChanged",e=> {
      Api.Settings.ChangeLanguage(e).then(e=> {
        getTransactions()
      })
    })
    return () =>{
      i18n.off("languageChanged",e=>{

      })
    }
    },[])

  const getTransactions = ()=>{
    Api.Transaction.list().then(response=>{
      if(response?.status){
        setData(response.data)
      }
    })
  }
  const onSelectionChanged=(e)=>{
    let data =e.data;
    Api.Users.getDetails({id:data.id,loader:(e)=>{
        setBtnLoader(e?e.key:null)
    }}).then(response=>{
        setSelectedUser(response.status?response.data:null)
    })

  }

    const onTransactionDetails=(e)=>{
        let data =e.data;
        Api.Transaction.details({id:data.id,loader:(e)=>{
                setBtnLoader(e?e.key:null)
            }}).then(response=>{
            setDetails(response.status?response.data:null)
        })

    }

    function renderStatus(e) {

      switch (e.data.status){
          case 1: return <span style={{color:'green'}}>{i18n.t("დადასტურებული")}</span>
          case 2: return <span style={{color:'red'}}>{i18n.t("გაუქმებული")}</span>
          case 3: return <span style={{color:'orange'}} className={"blink_me"}>{i18n.t("დასადასტურებელი")}</span>
      }
       /* if(e.data.status ===1){

        }else{
            return <span style={{color:'red'}}>{i18n.t("გაუქმებული")}</span>
        }*/
    }

    return (
    <React.Fragment>

      <UserDetails data={selectedUser} onSelectionChanged={onSelectionChanged} setSelected={setSelectedUser}/>
      <TransactionDetails transactions={details} close={()=>setDetails(null)}/>
      <div style={{"padding":"20px",flexDirection:'row',display:'flex'}}>
         <strong>{i18n.t("ტრანზაქციები")}</strong>
          <div style={{flex:1,justifyContent:'flex-end',display:'flex'}}>

              <label style={{display:'flex',flexDirection:'column'}}>
                  {i18n.t("-დან")}
                  <DatePicker selected={dateFrom}   placeholder={i18n.t("თარიღიდან")}  dateFormat="dd/MM/yyyy" onChange={(date) => setDateFrom(date)} />

{/*
                  //<input type="date" id="birthday" name="dateFrom" placeholder={i18n.t("თარიღიდან")} value={dateFrom} onChange={e=>setDateFrom(e.target.value)}/>
*/}
              </label>
              &nbsp;&nbsp;&nbsp;
              <label style={{display:'flex',flexDirection:'column'}}>
                  {i18n.t("-მდე")}
                  <DatePicker selected={dateTo}   placeholder={i18n.t("თარიღიდან")}  dateFormat="dd/MM/yyyy" onChange={(date) => setDateTo(date)} />

{/*
                  <input type="date" id="birthday" name="dateFrom" placeholder={i18n.t("თარიღიდან")} value={dateTo} onChange={e=>setDateTo(e.target.value)}/>
*/}
              </label>
              <div style={{display:'flex',alignItems:'flex-end',marginLeft:20}}>
                  <a href={`/api/ka/transaction/export?dateFrom=${moment(dateFrom).format('YYYY-MM-DD 00:00')}&dateTo=${moment(dateTo).format('YYYY-MM-DD 23:59')}`}><Button variant="light">export</Button></a>{' '}
              </div>
          </div>


      </div>
      <div style={{"padding":"0 20px 20px 20px"}}>
        <div className={'dx-card '}>
          <DataGrid
              height={`${window.innerHeight - 150}px`}
              scrolling={{
                mode:'standard'
              }}

              className={'dx-card wide-card'}
              dataSource={data}
              showBorders={false}
              focusedRowEnabled={true}
              defaultFocusedRowIndex={0}
              columnAutoWidth={true}
              columnHidingEnabled={true}
              keyExpr="id"
              allowColumnReordering={true}
              allowColumnResizing={true}

          >
            <Paging defaultPageSize={20} />
            <Pager showPageSizeSelector={true} showInfo={true} />
            <FilterRow visible={true} />

            <Column dataField={'id'}  alignment={"center"} width={90} hidingPriority={9} allowFiltering={false} />
            <Column
                alignment={"center"}
                dataField={'charge_type'}
                caption={i18n.t("გატარების ტიპი")}
                hidingPriority={7}
            />
            <Column
                alignment={"center"}
                dataField={'type'}
                caption={i18n.t("ტიპი")}
                hidingPriority={5}
            />
            <Column
                alignment={"center"}
                dataField={'status'}
                caption={i18n.t("სტატუსი")}
                hidingPriority={7}
                cellRender={e=>renderStatus(e)}
            />
            <Column
                alignment={"center"}
                dataField={'amount'}
                caption={i18n.t("თანხა")}
                allowSorting={false}
                hidingPriority={7}
            />
              <Column
                  alignment={"center"}
                  dataField={'cashback'}
                  caption={i18n.t("დარიცხული ქეშბექი")}
                  hidingPriority={3}
              />
            <Column
                alignment={"center"}
                dataField={'cashback_percent'}
                caption={i18n.t("დარიცხული პროცენტი")}
                hidingPriority={3}
            />
              <Column
                  alignment={"center"}
                  dataField={'timestamp'}
                  caption={i18n.t("დრო")}
                  hidingPriority={3}
              />
            <Column
                alignment={"center"}
                dataField={'fullname'}
                caption={i18n.t("მოხმარებელი")}
                hidingPriority={3}
            />
              <Column
                  alignment={"center"}
                  dataField={'card'}
                  caption={i18n.t("ბარათის ნომერი")}
                  hidingPriority={3}
              />
            <Column
                alignment={"center"}
                dataField={'pay_id'}
                caption={i18n.t("ჩეკის ნომერი")}
                hidingPriority={3}
            />
            <Column
                alignment={"center"}
                dataField={'adminFullName'}
                caption={i18n.t("მენეჯერი")}
                hidingPriority={3}
            />
            <Column
                alignment={"center"}
                dataField={'accept_date'}
                caption={i18n.t("დასტურის დრო")}
                hidingPriority={3}
            />
              <Column
                  alignment={"center"}
                  dataField={'trans_date'}
                  caption={i18n.t("ჩეკის დახურვის თარიღი")}
                  hidingPriority={3}
              />
              <Column
                alignment={"center"}
                dataField={'details'}
                caption={i18n.t("კომენტარი")}
                hidingPriority={10}
            />
              <Column dataField=""
                      cssClass={"custom-button-icon"}
                      width={50}
                      allowSorting={false}
                      cellRender={(e)=>{
                          return <Button
                              disabled={btnLoader === e.key}
                              type="normal"
                              style={{margin: 0, padding: 0,width:"35px",height:"35px",borderRadius:10}}
                              stylingMode="contained"
                              onClick={() => {
                                  onTransactionDetails({data:{id:e.row.data["trans_id"]}})
                              }}
                          >
                              <FontAwesomeIcon icon={faInfo}  />

                          </Button>
                      } }
              />
            <Column dataField=""
                    cssClass={"custom-button-icon"}
                    width={50}
                    allowSorting={false}
                    cellRender={(e)=>{
                      return <Button
                          disabled={btnLoader === e.key}
                          type="normal"
                          style={{margin: 0, padding: 0,width:"35px",height:"35px",borderRadius:10}}
                          stylingMode="contained"
                          onClick={() => {
                            onSelectionChanged({data:{id:e.row.data["user_id"]}})
                          }}
                      >
                        <FontAwesomeIcon icon={faUser}  />

                      </Button>
                    } }
            />

            <Column dataField=""  cssClass={"custom-button-icon"}
                    width={50}
                    allowSorting={false}
                    cellRender={(e)=>{

                      return [1].indexOf(e.data.status)>-1 && <Button
                          disabled={btnLoader === e.key}
                          icon={btnLoader === e.key? Loader:CLoseIcon}
                          type="normal"
                          style={{margin: 0, padding: 0,width:"35px",height:"35px"}}
                          stylingMode="contained"
                          onClick={() =>{

                            confirmation.open({
                              title:i18n.t("ტრანზაქციის გაუქმება"),
                              type:'danger',
                              subtitle:i18n.t(`დარწმუნებული ხართ, რომ გსურთ ტრანზაქციის გაუქმება?`),
                              notes:false,
                              icon:"times-circle",
                              //iconColor:'red',
                              //subtitleColor:'red',
                              onYes:(note)=>{
                                Api.Transaction.changeStatus({
                                  data:{
                                    id:e.data.id,
                                    status:2
                                  },
                                  loader:e=>{
                                    setBtnLoader(e?e.key:null)
                                  }
                                }).then(response=>{
                                  notify(response.data,response.status?'success':'error',3000);
                                  if(response.status){
                                    confirmation.close();
                                    getTransactions()

                                  }
                                })

                              },
                              onNo:()=>{
                                confirmation.close();
                              }
                            })
                          }}
                      />
                    } }
            />
              <Column dataField=""  cssClass={"custom-button-icon"}
                    width={50}
                    allowSorting={false}
                    cellRender={(e)=>{

                      return [3].indexOf(e.data.status)>-1 && <Button
                          disabled={btnLoader === e.key}
                          icon={btnLoader === e.key? Loader:CheckIcon}
                          type="normal"
                          style={{margin: 0, padding: 0,width:"35px",height:"35px"}}
                          stylingMode="contained"
                          onClick={() =>{

                            Api.Transaction.getOrderDetails({orderNumber:e.data.pay_id,mode:'update'}).then(response=>{
                                if(response?.status){

                                    const cashback = response?.data?.CardPayment?parseFloat(response?.data?.CardPayment)?.toFixed(2)||0:0;
                                    confirmation.open({
                                        title:i18n.t("ტრანზაქციის დადასტურება"),
                                        type:'danger',
                                        subtitle:i18n.t(`დარწმუნებული ხართ, რომ გსურთ ტრანზაქციის დადასტურება?`),
                                        notes:false,
                                        icon:"times-circle",
                                        //iconColor:'red',
                                        //subtitleColor:'red',
                                        onYes:(note)=>{

                                            Api.Transaction.accept_transaction({
                                                pay_id: e.data.pay_id,
                                                cashback:cashback,
                                                loader:e=>{
                                                    setBtnLoader(e?e.key:null)
                                                }

                                            }).then(response=>{
                                                notify(response.data,response.status?'success':'error',3000);
                                                if(response.status){
                                                    confirmation.close();
                                                    getTransactions()

                                                }
                                            })
                                        },
                                        onNo:()=>{
                                            confirmation.close();
                                        }
                                    })
                                }
                                else{
                                    notify(response.data,response.status?'success':'error',3000);
                                }
                            })

                          }}
                      />
                    } }
            />
          </DataGrid>
        </div>
      </div>
    </React.Fragment>
)}
